define("ember-components/components/copyable-fields/to", ["exports", "ember-components/templates/components/copyable-fields/to"], function (_exports, _to) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _to.default,
    classNames: ["to"],
    "data-test-id": "data-to"
  });

  _exports.default = _default;
});