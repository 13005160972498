define("ember-feature-flags/helpers/feature-flag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    features: Ember.inject.service(),

    compute([flag]) {
      if (this._observedFlag) {
        this.get('features').removeObserver(this._observedFlag, this, 'recompute');
      }

      this.set('_observedFlag', Ember.String.camelize(flag));
      this.get('features').addObserver(this._observedFlag, this, 'recompute');
      return this.get('features').isEnabled(flag);
    },

    _observedFlag: null,

    willDestroy() {
      this._super(...arguments);

      if (this._observedFlag) {
        this.get('features').removeObserver(this._observedFlag, this, 'recompute');
      }
    }

  });

  _exports.default = _default;
});