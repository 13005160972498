define("ember-components/components/side-bar/right-popup/footer", ["exports", "ember-components/templates/components/side-bar/right-popup/footer"], function (_exports, _footer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _footer.default,
    classNames: ["footer"],

    didInsertElement() {
      this._super(...arguments);

      this.element.parentElement.classList.add("has-footer");
    }

  });

  _exports.default = _default;
});