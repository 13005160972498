define("ember-components/templates/components/tabular-menu/left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n53oOUQm",
    "block": "{\"symbols\":[\"Tab\",\"@name\",\"@path\",\"@icon\",\"@disabled\",\"@tabs\"],\"statements\":[[4,\"let\",[[28,\"component\",[\"tabular-menu/tab\"],null]],null,{\"statements\":[[4,\"if\",[[23,6,[]]],null,{\"statements\":[[0,\"    \"],[6,[23,1,[]],[],[[\"@tabs\"],[[23,6,[]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,[23,1,[]],[],[[\"@name\",\"@path\",\"@icon\",\"@disabled\"],[[23,2,[]],[23,3,[]],[23,4,[]],[23,5,[]]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/tabular-menu/left.hbs"
    }
  });

  _exports.default = _default;
});