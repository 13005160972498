define("ember-components/components/dropdown-menu", ["exports", "ember-components/templates/components/dropdown-menu"], function (_exports, _dropdownMenu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _dropdownMenu.default,
    tagName: "",
    showMenuItems: false,
    actions: {
      onMenuToggle() {
        Ember.set(this, "showMenuItems", !this.showMenuItems);
        this.onMenuToggle && this.onMenuToggle();
      },

      updateTitle(title) {
        Ember.run.next(() => Ember.set(this, "activeItemTitle", title));
      }

    }
  });

  _exports.default = _default;
});