define("ember-components/helpers/is-item-equal", ["exports", "ember-get-config", "ember-components/mixins/helper-observer"], function (_exports, _emberGetConfig, _helperObserver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isItemEqual = isItemEqual;
  _exports.default = void 0;
  const defaultKey = _emberGetConfig.default.APP.OBJECT_COMPARATOR_KEY;

  const getValueByKey = (object, key) => object.get ? object.get(key) : object[key];

  const doesObjectContainsKey = (obj, key) => obj instanceof Object && key in obj;

  function getValue(object, key) {
    let value = object;
    if (doesObjectContainsKey(object, key)) value = getValueByKey(object, key);
    return Number.isInteger(value) ? value.toString() : value;
  }

  function isItemEqual(item, selected, key) {
    key = key || defaultKey;
    const selectedValue = getValue(selected, key);
    return selectedValue != null && selectedValue === getValue(item, key);
  }

  var _default = Ember.Helper.extend(_helperObserver.default, {
    compute([item, selected, key]) {
      if (selected && selected.addObserver) this.setupRecompute(selected, "code");
      return isItemEqual(item, selected, key);
    }

  });

  _exports.default = _default;
});