define('ember-click-outside/component', ['exports', 'ember-click-outside/mixin', 'ember-click-outside/utils'], function (exports, _mixin, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_mixin.default, {

    clickOutside(e) {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }

      const exceptSelector = Ember.get(this, 'except-selector');
      if (exceptSelector && (0, _utils.closest)(e.target, exceptSelector)) {
        return;
      }

      let action = Ember.get(this, 'action');
      if (typeof action === 'function') {
        action(e);
      }
    },

    didInsertElement() {
      this._super(...arguments);
      this._cancelOutsideListenerSetup = Ember.run.next(this, this.addClickOutsideListener);
    },

    willDestroyElement() {
      Ember.run.cancel(this._cancelOutsideListenerSetup);
      this.removeClickOutsideListener();
    }
  });
});