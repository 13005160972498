define("ember-components/initializers/responsive", ["exports", "ember-responsive/initializers/responsive"], function (_exports, _responsive) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "responsive",
    initialize: _responsive.initialize
  };
  _exports.default = _default;
});