define("ember-components/components/side-bar/textarea-popup/textarea", ["exports", "ember-components/templates/components/side-bar/textarea-popup/textarea", "ember-components/mixins/validation-class-mixin", "ember-components/helpers/insert-text-to-textarea"], function (_exports, _textarea, _validationClassMixin, _insertTextToTextarea) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_validationClassMixin.default, {
    layout: _textarea.default,
    classNames: ["text-area-input"],
    helpTitle: "Paruoštukai",

    scrollToTopOnKeyboardPopup() {
      const self = this;
      const lastWindowHeight = window.innerHeight;
      const element = document.getElementById(self.elementId) || self.popupTextAreaSelector();

      if (element) {
        const sidebar = element.closest(".sidebar");
        const runAfterMilliseconds = 50;
        window.addEventListener("resize", function textAreaListener() {
          document.textAreaListenerReference = textAreaListener;
          if (lastWindowHeight > window.innerHeight) Ember.run.later(() => {
            if (self.helpOptions) self.scrollToNotes();else if (sidebar) sidebar.scrollTop = 0;
          }, runAfterMilliseconds);
        });
      }
    },

    focusAndScrollToTextarea() {
      this.element.getElementsByTagName("textarea")[0].focus();
      this.scrollToTopOnKeyboardPopup();
    },

    didInsertElement() {
      this._super(...arguments);

      this.autoFocus && this.focusAndScrollToTextarea();
    },

    willDestroyElement() {
      this._super(...arguments);

      window.removeEventListener("resize", document.textAreaListenerReference);
    },

    changeValue(value) {
      Ember.set(this, "value", value);
    },

    scrollToNotes() {
      Ember.run.next(() => {
        const [textareaTitle] = document.getElementsByClassName("textarea title");
        const [textarea] = document.getElementsByTagName("textarea");
        textareaTitle ? textareaTitle.scrollIntoView && textareaTitle.scrollIntoView() : textarea && textarea.scrollIntoView && textarea.scrollIntoView();
      });
    },

    popupTextAreaSelector: () => document.getElementById("popup-textarea"),
    actions: {
      updateValue(value) {
        this.changeValue(value);
      },

      focusIn() {
        this.focusAndScrollToTextarea();
      },

      focusTextAreaAndScroll(isBlockVisible) {
        this.popupTextAreaSelector().focus();
        isBlockVisible && this.scrollToNotes();
      },

      onBlockClick(blockValue, value) {
        this.changeValue((0, _insertTextToTextarea.insertTextToTextarea)(this.popupTextAreaSelector(), value, blockValue));
      }

    }
  });

  _exports.default = _default;
});