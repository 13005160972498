define("ember-components/components/side-bar/single-input/comment", ["exports", "ember-components/templates/components/side-bar/single-input/comment"], function (_exports, _comment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _comment.default,
    "data-test-id": "comment",
    tagName: "span",
    classNames: ["comment"],
    displayIcon: true
  });

  _exports.default = _default;
});