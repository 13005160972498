define("ember-components/templates/components/side-bar/right-popup/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GQqSvM2r",
    "block": "{\"symbols\":[\"ValidationBlock\",\"@message\",\"@task\",\"@data\",\"@isDisabled\",\"@className\",\"@title\",\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"row popup-top \",[23,6,[]]]]],[10,\"data-test-id\",\"popup-header\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[0,\"\\n      \"],[7,\"h4\",true],[10,\"data-test-id\",\"right-popup-title\"],[8],[1,[23,7,[]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"header-content\"],[10,\"data-test-id\",\"header-content\"],[8],[0,\"\\n      \"],[14,8],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"buttons-container\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"task\"]]],null,{\"statements\":[[0,\"        \"],[5,\"ui-loader\",[],[[\"@isLoading\"],[[23,3,[\"isRunning\"]]]]],[0,\"\\n        \"],[7,\"button\",true],[10,\"data-test-id\",\"sidebar-submit-button\"],[10,\"class\",\"ui green button submit-button\"],[11,\"onclick\",[28,\"perform\",[[23,3,[]],[23,4,[]]],null]],[11,\"disabled\",[23,5,[]]],[8],[0,\"\\n          OK\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"let\",[[28,\"component\",[\"side-bar/right-popup/validation-block\"],null]],null,{\"statements\":[[0,\"    \"],[6,[23,1,[]],[],[[\"@message\"],[[23,2,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/side-bar/right-popup/header.hbs"
    }
  });

  _exports.default = _default;
});