define("ember-components/templates/components/side-bar/numeric-input/input-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kJIy+1W/",
    "block": "{\"symbols\":[\"@unit\",\"@error\",\"@prefix\",\"&default\"],\"statements\":[[4,\"if\",[[23,3,[]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"data-test-id\",\"prefix\"],[10,\"class\",\"prefix\"],[8],[1,[23,3,[]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"ui input\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[23,2,[]],[28,\"not-eq\",[[23,2,[]],\"reachedMaxLength\"],null]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"ui pointing below red basic label\"],[10,\"data-test-id\",\"error-message\"],[8],[0,\"\\n      \"],[1,[23,2,[]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[14,4],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"data-test-id\",\"unit\"],[10,\"class\",\"unit\"],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/side-bar/numeric-input/input-section.hbs"
    }
  });

  _exports.default = _default;
});