define("ember-components/mixins/sidebar-switcher", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    indexSelector: "sidebar-index",
    currentSidebarIndex: null,
    sidebarIndexOrder: null,
    scopeSelector: Ember.computed(function () {
      return this.element.closest("[sidebar-switcher-scope]");
    }),
    closeHiddenSidebar: (0, _emberConcurrency.task)(function* () {
      yield Ember.set(this, "isRightPopupOpen", false);
    }),

    findAndSetSidebarIndexOrder() {
      const {
        indexSelector
      } = this;
      const {
        scopeSelector
      } = this;

      if (scopeSelector) {
        let elementsWithIndex = scopeSelector.querySelectorAll(`[${indexSelector}]`);
        elementsWithIndex = Array.from(elementsWithIndex);
        const sidebarIndexOrder = elementsWithIndex.map(el => el.getAttribute(indexSelector)).sort((a, b) => a - b);
        Ember.set(this, "sidebarIndexOrder", sidebarIndexOrder);
      }
    },

    findAndSetCurrentSidebarIndex() {
      const {
        element,
        indexSelector,
        currentSidebarIndex
      } = this;

      if (!currentSidebarIndex) {
        const elementWithIndexAttribute = element.closest(`[${indexSelector}]`);

        if (elementWithIndexAttribute) {
          const indexAttributeValue = elementWithIndexAttribute.getAttribute(indexSelector);
          Ember.set(this, "currentSidebarIndex", indexAttributeValue);
        }
      }
    },

    openSidebar(element) {
      const [hiddenSidebarOpener] = element.getElementsByClassName("hidden-sidebar-opener");
      return hiddenSidebarOpener ? hiddenSidebarOpener.click() : element.getElementsByClassName("input")[0].click();
    },

    getElementWithIndex(index) {
      return this.scopeSelector && this.scopeSelector.querySelector(`[${this.indexSelector}="${index}"]`);
    },

    getNextIndexFromOrderList(direction) {
      const {
        sidebarIndexOrder,
        currentSidebarIndex
      } = this;

      if (sidebarIndexOrder) {
        const positionOfCurrentIndex = sidebarIndexOrder.indexOf(currentSidebarIndex);
        return sidebarIndexOrder[positionOfCurrentIndex + direction];
      }

      return null;
    },

    getNextPopupTitle() {
      const nextElementIndex = this.getNextIndexFromOrderList(1);
      const nextElement = this.getElementWithIndex(nextElementIndex);

      if (nextElement) {
        const navigatorTitle = nextElement.getAttribute("sidebar-navigator-title");
        if (navigatorTitle) return navigatorTitle;
        const elementTitle = nextElement.getElementsByClassName("title");
        return elementTitle[0].innerText;
      }

      return null;
    },

    scrollElementToCenter(element) {
      element.scrollIntoView({
        block: "center"
      });
    },

    switchTask: (0, _emberConcurrency.task)(function* (direction) {
      this.findAndSetSidebarIndexOrder();
      this.set("isRightPopupOpen", false);
      const nextIndex = this.getNextIndexFromOrderList(direction);

      if (nextIndex) {
        const nextElement = this.getElementWithIndex(nextIndex);
        yield this.openSidebar(nextElement);
        return yield this.scrollElementToCenter(nextElement);
      }

      return null;
    }),
    sidebarSwitcher: Ember.computed("selectedItem", "order", "isRightPopupOpen", {
      get() {
        this.findAndSetSidebarIndexOrder();
        this.findAndSetCurrentSidebarIndex();

        if (this.currentSidebarIndex) {
          const {
            switchTask
          } = this;
          const nextSidebarTitle = this.getNextPopupTitle();
          return {
            switchTask,
            nextSidebarTitle
          };
        }

        return null;
      },

      set: (key, value) => value
    })
  });

  _exports.default = _default;
});