define("ember-components/components/side-bar/single-radiobox/input", ["exports", "ember-components/templates/components/side-bar/single-radiobox/input"], function (_exports, _input) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _input.default,
    classNames: ["ui", "button", "input-view"],
    classNameBindings: ["checked:active"],
    attributeBindings: ["tabindex"],
    tabindex: 0,
    "data-test-id": "button",

    getSelectedItem(checked) {
      return this.required || !checked ? this.item : null;
    },

    click() {
      this.onSelectedValue(this.getSelectedItem(this.checked));
    }

  });

  _exports.default = _default;
});