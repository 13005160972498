define("ember-components/helpers/is-item-in-array", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isItemInArray = isItemInArray;
  _exports.default = _exports.getObjectIfMatches = void 0;

  const getObjectIfMatches = (array, item, key) => array.find(element => element[key] === item[key]);

  _exports.getObjectIfMatches = getObjectIfMatches;

  function isItemInArray([array, item]) {
    if (!array || !item) return false;
    if (typeof item === "object") return !!getObjectIfMatches(array, item, _emberGetConfig.default.APP.OBJECT_COMPARATOR_KEY);
    return array.includes(item);
  }

  var _default = Ember.Helper.helper(isItemInArray);

  _exports.default = _default;
});