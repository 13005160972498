define("ember-components/templates/components/contacts/error-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wfr2sJAL",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isNotNetworkError\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"contacts-error-message\"],[8],[0,\"\\n    \"],[1,[28,\"concat\",[[28,\"t\",[\"support.questions.needHelp\"],null],\" \",[28,\"t\",[\"support.message.callUs\"],null],\" +37067210477\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/contacts/error-message.hbs"
    }
  });

  _exports.default = _default;
});