define("ember-components/components/side-bar/single-select/input", ["exports", "ember-components/templates/components/side-bar/single-select/input"], function (_exports, _input) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _input.default,
    showItemDeleteBlock: Ember.computed("hideSelectedItemBlock", "required", "selected", "comparatorKey", "isSearchable", function () {
      const {
        selected,
        hideSelectedItemBlock,
        comparatorKey,
        isSearchable,
        required
      } = this;
      if (hideSelectedItemBlock || required) return false;
      if (isSearchable) return Boolean(selected);
      return selected && comparatorKey ? Boolean(Ember.get(selected, comparatorKey)) : true;
    }),
    otherOptionsOrNull: otherOptions => otherOptions || null,
    options: Ember.computed("items.@each.code", "selected", "isSearchable", "query", "otherOptions", function () {
      const {
        items,
        selected,
        isSearchable,
        query,
        otherOptions,
        otherOptionsSource
      } = this;
      if (isSearchable && !query) return selected ? [selected] : this.otherOptionsOrNull(otherOptions);
      if (!isSearchable && otherOptionsSource) return this.otherOptionsOrNull(otherOptions);
      return items;
    })
  });

  _exports.default = _default;
});