define("ember-components/components/side-bar/single-input/icon", ["exports", "ember-components/templates/components/side-bar/single-input/icon"], function (_exports, _icon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _icon.default,
    "data-test-id": "single-input-icon",
    tagName: "i",
    classNameBindings: ["iconClass"],
    iconClass: Ember.computed("icon", "selectedItem.{isPromiseLoading,isPromiseRejected}", "readOnly", function () {
      const {
        icon
      } = this;
      if (this.get("selectedItem.isPromiseRejected")) return "warning sign red icon";else if (this.get("selectedItem.isPromiseLoading")) return "ui small active inline loader";else if (this.readOnly) return "icon lock grey";else if (icon) return icon;else return "icon align justify blue";
    })
  });

  _exports.default = _default;
});