define("ember-components/templates/components/locked-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W7VA4QL7",
    "block": "{\"symbols\":[\"@displayIcon\",\"@comment\",\"@validation\",\"@title\",\"@value\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"locked input-inline input-view \",[28,\"if\",[[23,3,[]],[23,3,[\"type\"]],\"\"],null]]]],[11,\"data-test-validation\",[24,[\"validation\",\"type\"]]],[8],[0,\"\\n  \"],[7,\"label\",true],[10,\"data-test-id\",\"input-label\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"data-test-id\",\"single-input-header\"],[10,\"class\",\"ui input-header\"],[8],[0,\"\\n      \"],[1,[23,4,[]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"input\"],[10,\"data-test-id\",\"input-field-value\"],[8],[0,\"\\n      \"],[1,[23,5,[]],false],[0,\"\\n\"],[4,\"if\",[[23,2,[]]],null,{\"statements\":[[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"          \"],[7,\"i\",true],[10,\"class\",\"info circle icon grey\"],[10,\"data-test-id\",\"comment-icon\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[7,\"span\",true],[10,\"class\",\"comment\"],[10,\"data-test-id\",\"comment\"],[8],[0,\"\\n          \"],[1,[23,2,[]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"icon lock grey\"],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/locked-field.hbs"
    }
  });

  _exports.default = _default;
});