define("ember-components/templates/components/side-bar/single-radiobox/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hy9yZU0m",
    "block": "{\"symbols\":[\"@title\"],\"statements\":[[7,\"p\",true],[10,\"class\",\"title\"],[10,\"data-test-id\",\"title\"],[8],[1,[23,1,[]],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/side-bar/single-radiobox/header.hbs"
    }
  });

  _exports.default = _default;
});