define("ember-components/components/side-bar/multi-input/popup", ["exports", "ember-components/templates/components/side-bar/multi-input/popup", "ember-concurrency"], function (_exports, _popup, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _popup.default,
    device: Ember.inject.service(),
    inputsMap: null,
    focusedInputId: null,
    onRightPopupClose: (0, _emberConcurrency.task)(function* () {
      yield this.set("isRightPopupOpen", false);
    }),

    getFocusedInputValue() {
      return this.inputsMap[this.focusedInputId].context.parsedInputValue;
    },

    getInputElementContextById(id) {
      return this.inputsMap[id].context;
    },

    setElementInputValue: (element, value) => Ember.set(element, "inputValue", value),

    getFocusedInputMaxLength() {
      return this.getInputElementContextById(this.focusedInputId).maxLength;
    },

    isInputLengthValid(value) {
      const maxLength = this.getFocusedInputMaxLength();

      if (maxLength) {
        const inputLength = Ember.isPresent(value) ? `${value}`.length : 0;
        return inputLength <= maxLength;
      }

      return true;
    },

    setErrorMessage(message) {
      const element = this.getInputElementContextById(this.focusedInputId);
      Ember.set(element, "errorMessage", message);
    },

    isValid(value) {
      if (this.isInputLengthValid(value)) {
        this.setErrorMessage(null);
        return true;
      } else {
        this.setErrorMessage("Skaičius yra per didelis");
        return false;
      }
    },

    setFocusedInputValue(value) {
      const element = this.getInputElementContextById(this.focusedInputId);
      value = Ember.isPresent(value) ? parseInt(value) : null;
      this.isValid(value) && this.setElementInputValue(element, value);
    },

    setFocusedInput(id) {
      Ember.set(this, "focusedInputId", id);
    },

    focusFirstInput() {
      const {
        element
      } = this;
      const inputs = element && element.getElementsByTagName("input");

      if (inputs) {
        this.device.isDesktop() && inputs[0].focus();
        this.setFocusedInput(inputs[0].parentElement.id);
      }
    },

    init() {
      this._super(...arguments);

      Ember.set(this, "inputsMap", {});
    },

    didInsertElement() {
      this._super(...arguments);

      this.focusFirstInput();
    },

    actions: {
      onSelectionRemove() {
        Object.keys(this.inputsMap).forEach(elementId => {
          const element = this.getInputElementContextById(elementId);
          this.setElementInputValue(element, null);
        });
      },

      onNumberSelect(value) {
        let numericValue = `${this.getFocusedInputValue()}`;
        numericValue += value;
        this.setFocusedInputValue(numericValue);
      },

      onNumberDelete() {
        let numericValue = `${this.getFocusedInputValue()}`;
        numericValue = numericValue.slice(0, numericValue.length - 1);
        this.setFocusedInputValue(numericValue);
      },

      onKeyPressed(event) {
        if (event.code === "Tab") {
          this.setFocusedInput(event.currentTarget.parentElement.id);
        } else {
          const inputValue = event.currentTarget.value;
          this.setFocusedInputValue(inputValue);
        }

        event.stopPropagation();
      }

    }
  });

  _exports.default = _default;
});