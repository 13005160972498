define("ember-components/helpers/sort-array-by-order", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sortArrayByOrder = sortArrayByOrder;

  function sortArrayByOrder(data, orderSort, key) {
    // TODO refactor with filter or sort
    if (!orderSort || !data || data.length < 2) return data;
    let sortedData = [];
    orderSort = key ? orderSort.map(o => o[key]) : orderSort;
    orderSort.forEach(order => {
      const found = data.find(element => {
        element = key ? element[key] : element;
        return element === order;
      });
      found && sortedData.push(found);
    });
    if (sortedData.length !== data.length) sortedData = [...new Set([...sortedData, ...data])];
    return sortedData;
  }
});