define("ember-components/templates/components/filter-composer/select-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZH0pkjIV",
    "block": "{\"symbols\":[\"Notification\",\"@onButtonClicked\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui container align-column filter-select-button\"],[10,\"data-test-id\",\"filter-select-notification\"],[8],[0,\"\\n  \"],[7,\"button\",true],[10,\"data-test-id\",\"filter-popup\"],[10,\"class\",\"huge ui primary basic button\"],[11,\"onclick\",[23,2,[]]],[8],[0,\"\\n    Filtrai\\n  \"],[9],[0,\"\\n\\n\"],[4,\"let\",[[28,\"component\",[\"side-bar/notification\"],null]],null,{\"statements\":[[0,\"    \"],[6,[23,1,[]],[],[[],[]],{\"statements\":[[0,\"\\n      Pasirinkite paieškos kriterijus\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/filter-composer/select-button.hbs"
    }
  });

  _exports.default = _default;
});