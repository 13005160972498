define("ember-components/templates/components/side-bar/numeric-input/favourites", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bFMMngyV",
    "block": "{\"symbols\":[\"SelectedItemBlock\",\"favourite\",\"@favourites\"],\"statements\":[[4,\"let\",[[28,\"component\",[\"side-bar/selected-item-block\"],null]],null,{\"statements\":[[4,\"if\",[[24,[\"favourites\"]]],null,{\"statements\":[[0,\"    \"],[6,[23,1,[]],[[12,\"data-test-id\",\"favourite-field\"],[12,\"class\",\"favourite-field\"]],[[],[]],{\"statements\":[[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"field\"],[8],[0,\"\\n\"],[4,\"each\",[[23,3,[]]],null,{\"statements\":[[0,\"\\n          \"],[7,\"button\",true],[10,\"class\",\"ui basic blue button\"],[10,\"data-test-id\",\"option\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"onFavouriteSelect\",[23,2,[\"value\"]]],null]],[8],[0,\"\\n            \"],[1,[23,2,[\"name\"]],false],[0,\"\\n          \"],[9],[0,\"\\n\\n\"]],\"parameters\":[2]},null],[0,\"      \"],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/side-bar/numeric-input/favourites.hbs"
    }
  });

  _exports.default = _default;
});