define("ember-components/components/checkbox-true-null", ["exports", "ember-components/templates/components/checkbox-true-null", "ember-components/mixins/validation-class-mixin", "ember-components/mixins/sidebar-switcher"], function (_exports, _checkboxTrueNull, _validationClassMixin, _sidebarSwitcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_validationClassMixin.default, _sidebarSwitcher.default, {
    layout: _checkboxTrueNull.default,
    "data-test-id": "checkbox",
    classNames: ["checkbox-true-null"],
    classNameBindings: ["locked:locked"],
    locked: false,
    readOnly: false,
    fadeBackground: true,
    showValidationBlock: true,
    checkboxItem: Ember.computed("title", function () {
      const {
        title
      } = this;
      return {
        code: title,
        name: title
      };
    }),
    items: Ember.computed("checkboxItem", function () {
      return [this.checkboxItem];
    }),

    onChange() {
      if (!this.locked && !this.readOnly) {
        if (this.checked) Ember.set(this, "checked", null);else Ember.set(this, "checked", true);
        if (this.onValueChange) return this.onValueChange();
      }

      return null;
    },

    keyUp() {
      if (event.keyCode === 13) this.onChange();
    },

    closeHiddenPopup() {
      this.closeOnSelect && this.closeHiddenSidebar.perform();
    },

    actions: {
      onClick() {
        return this.onChange();
      },

      onSelectedItem(item) {
        Ember.set(this, "checked", true);
        this.onSelectedItem && this.onSelectedItem(item);
        this.closeHiddenPopup();
      },

      onClearValue() {
        Ember.set(this, "checked", null);
        this.onClearValue && this.onClearValue();
        this.closeHiddenPopup();
      }

    }
  });

  _exports.default = _default;
});