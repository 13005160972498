define("ember-components/components/side-bar/single-input/input", ["exports", "ember-components/templates/components/side-bar/single-input/input"], function (_exports, _input) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _input.default,
    tagName: "label",
    formattedData: Ember.computed("data", {
      get() {
        return this.data;
      },

      set(fieldName, value) {
        return Ember.set(this, "data", value === "" ? null : value);
      }

    }),
    actions: {
      onclick() {
        if (this.onclick) this.onclick();
      },

      onFocusIn() {
        Ember.set(this, "isInputFocused", true);
        this.onFocusIn && this.onFocusIn();
      },

      onFocusOut() {
        Ember.set(this, "isInputFocused", false);
        this.onFocusOut && this.onFocusOut();
      },

      onInputChange(value) {
        Ember.set(this, "data", value === "" ? null : value);
        const {
          onInputChange,
          data
        } = this;
        onInputChange && onInputChange(data);
      }

    }
  });

  _exports.default = _default;
});