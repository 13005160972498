define("ember-components/components/side-bar/time-picker/generic", ["exports", "ember-components/templates/components/side-bar/time-picker/generic"], function (_exports, _generic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _generic.default,
    tagName: ""
  });

  _exports.default = _default;
});