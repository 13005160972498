define("ember-components/services/error-translation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),
    isString: exception => typeof exception === "string",

    translateErrorMessageIfHasTranslation(intl, code) {
      const translation = intl.t(code);
      return !translation.includes("Missing translation") && translation;
    },

    getErrorTextFromException(exception) {
      console.error(exception); // eslint-disable-line

      const errorCode = this.getErrorCodeFromException(exception);
      return errorCode ? this.translateErrorMessageIfHasTranslation(this.intl, `errors.${errorCode}`) || this.intl.t("errors.general") : this.intl.t("errors.general");
    },

    getErrorCodeFromException(exception) {
      if (this.isString(exception) && !exception.includes("adapter")) return exception;
      const networkConnection = this.isNetworkConnection(exception);
      if (networkConnection) return "NO_INTERNET_CONNECTION";
      return exception && exception.errors ? exception.errors[0].code : null;
    },

    isAdapterError(exception) {
      return !exception.status && (exception.title === "Adapter Error" || exception.includes && exception.includes("The adapter operation was aborted"));
    },

    isNetworkConnection(exception) {
      if (!exception) return false;
      const error = this.isString(exception) ? exception : exception.errors || exception.message;

      if (error) {
        const [firstError] = error;
        return this.isAdapterError(this.isString(exception) ? exception : firstError) || error === "TypeError: Network request failed";
      }

      return false;
    }

  });

  _exports.default = _default;
});