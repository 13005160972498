define("ember-components/components/dropdown-menu/active-item-block", ["exports", "ember-components/templates/components/dropdown-menu/active-item-block"], function (_exports, _activeItemBlock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _activeItemBlock.default,
    "data-test-id": "active-item-block"
  });

  _exports.default = _default;
});