define("ember-components/templates/components/lazy-yield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x00co3H0",
    "block": "{\"symbols\":[\"@visible\",\"&default\"],\"statements\":[[4,\"if\",[[24,[\"createDom\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"data-test-id\",\"yielded-dom\"],[11,\"class\",[28,\"if\",[[23,1,[]],\"\",\"lazy-yield hidden\"],null]],[8],[0,\"\\n    \"],[14,2],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/lazy-yield.hbs"
    }
  });

  _exports.default = _default;
});