define("ember-components/templates/components/side-bar/multi-input/popup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ByrQJYoI",
    "block": "{\"symbols\":[\"RightPopup\",\"SelectedItemBlock\",\"NumericPad\",\"Ui\",\"@fadeBackground\",\"@title\",\"@sidebarSwitcher\",\"@isRightPopupOpen\",\"@validation\",\"@onInputChange\",\"&default\"],\"statements\":[[4,\"let\",[[28,\"component\",[\"side-bar/right-popup\"],null],[28,\"component\",[\"side-bar/selected-item-block\"],null],[28,\"component\",[\"side-bar/numeric-input/pad\"],null]],null,{\"statements\":[[0,\"\\n  \"],[6,[23,1,[]],[],[[\"@fadeBackground\",\"@title\",\"@task\",\"@sidebarSwitcher\",\"@isRightPopupOpen\",\"@message\",\"@className\"],[[23,5,[]],[23,6,[]],[22,\"onRightPopupClose\"],[23,7,[]],[23,8,[]],[23,9,[]],\"mini\"]],{\"statements\":[[0,\"\\n    \"],[6,[23,4,[\"header\"]],[],[[],[]]],[0,\"\\n\\n    \"],[6,[23,4,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"input-remove-container\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"input-section\"],[8],[0,\"\\n          \"],[14,11,[[28,\"hash\",null,[[\"input\"],[[28,\"component\",[\"side-bar/multi-input/input\"],[[\"onKeyPressed\",\"inputsMap\",\"focusedInputId\",\"onInputChange\",\"setFocusedInput\"],[[28,\"action\",[[23,0,[]],\"onKeyPressed\"],null],[24,[\"inputsMap\"]],[24,[\"focusedInputId\"]],[23,10,[]],[28,\"action\",[[23,0,[]],[24,[\"setFocusedInput\"]]],null]]]]]]]]],[0,\"\\n        \"],[9],[0,\"\\n\\n        \"],[6,[23,2,[]],[],[[\"@selectedItem\",\"@onClearValue\"],[true,[28,\"action\",[[23,0,[]],\"onSelectionRemove\"],null]]]],[0,\"\\n\\n      \"],[9],[0,\"\\n\\n      \"],[6,[23,3,[]],[],[[\"@onNumberSelect\",\"@onNumberDelete\"],[[28,\"action\",[[23,0,[]],\"onNumberSelect\"],null],[28,\"action\",[[23,0,[]],\"onNumberDelete\"],null]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n    \"],[14,11,[[28,\"hash\",null,[[\"help-block\"],[[28,\"component\",[\"side-bar/common/help-block\"],[[\"title\"],[[23,6,[]]]]]]]]]],[0,\"\\n\\n  \"]],\"parameters\":[4]}],[0,\"\\n\"]],\"parameters\":[1,2,3]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/side-bar/multi-input/popup.hbs"
    }
  });

  _exports.default = _default;
});