define("ember-components/components/center-modal", ["exports", "ember-components/templates/components/center-modal", "ember-components/mixins/modal-counter", "ember-components/mixins/keyboard-actions"], function (_exports, _centerModal, _modalCounter, _keyboardActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalCounter.default, _keyboardActions.default, {
    "data-test-id": "center-modal",
    layout: _centerModal.default,

    didRender() {
      if (!this.style) {
        const wH = window.innerHeight;
        let element = this.element.querySelector(".center-modal .ui.modal");
        const mH = element.clientHeight;
        element.setAttribute("style", Ember.String.htmlSafe(`top: ${(wH - mH) / 2}px;`));
      }
    },

    actions: {
      closeModal() {
        this.dimmerCloseTask && this.dimmerCloseTask.perform();
      }

    }
  });

  _exports.default = _default;
});