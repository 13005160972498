define("ember-components/components/side-bar/time-picker/favourite-select", ["exports", "ember-components/templates/components/side-bar/time-picker/favourite-select"], function (_exports, _favouriteSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _favouriteSelect.default,
    classNames: ["time-picker-favourite", "favourite-block"],
    "data-test-id": "favourite-block",
    classNameBindings: ["validation.type"],
    actions: {
      onSettingTime(time, difference, unit) {
        this.onSettingTime(time, difference, unit);
      }

    }
  });

  _exports.default = _default;
});