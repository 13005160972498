define("ember-components/templates/components/medicaments-list/body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xbmZWuBo",
    "block": "{\"symbols\":[\"medicament\",\"&default\"],\"statements\":[[4,\"each\",[[24,[\"sortedMedicaments\"]]],null,{\"statements\":[[0,\"  \"],[14,2,[[28,\"hash\",null,[[\"name\",\"types\",\"tr\",\"medicament\",\"drillDown\"],[[28,\"component\",[\"medicaments-list/body/name\"],[[\"name\",\"colspanCount\"],[[23,1,[\"name\"]],[24,[\"colspanCount\"]]]]],[28,\"component\",[\"medicaments-list/body/types\"],[[\"types\",\"isSelected\",\"selectedMedicamentCode\",\"colspanCount\",\"onMedicamentClick\",\"isDrillDownOptionEnabled\",\"selectedItems\"],[[23,1,[\"types\"]],[24,[\"isSelected\"]],[24,[\"selectedMedicamentCode\"]],[24,[\"colspanCount\"]],[24,[\"onMedicamentClick\"]],[24,[\"isDrillDownOptionEnabled\"]],[24,[\"selectedItems\"]]]]],[28,\"component\",[\"medicaments-list/body/tr\"],null],[23,1,[]],[28,\"component\",[\"medicaments-list/drill-down\"],[[\"isSelected\",\"colspanCount\"],[[24,[\"isSelected\"]],[24,[\"colspanCount\"]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/medicaments-list/body.hbs"
    }
  });

  _exports.default = _default;
});