define("ember-components/helpers/error-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    errorTranslation: Ember.inject.service(),
    icons: {
      TOO_MANY_RESULTS: "sort amount up",
      NO_RESULTS: "file excel"
    },

    compute([error]) {
      const errorCode = this.errorTranslation.getErrorCodeFromException(error);
      return this.icons[errorCode] || "";
    }

  });

  _exports.default = _default;
});