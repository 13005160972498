define("ember-components/components/side-bar/numeric-input/favourites", ["exports", "ember-components/templates/components/side-bar/numeric-input/favourites"], function (_exports, _favourites) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _favourites.default,
    tagName: "",
    actions: {
      onFavouriteSelect(value) {
        Ember.set(this, "inputValue", value);
        this.onFavouriteSelect && this.onFavouriteSelect(value);
      }

    }
  });

  _exports.default = _default;
});