define("ember-components/components/center-modal/header", ["exports", "ember-components/templates/components/center-modal/header"], function (_exports, _header) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _header.default,
    classNames: ["header"]
  });

  _exports.default = _default;
});