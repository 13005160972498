define("ember-components/templates/components/side-bar/right-popup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zdR4ItWY",
    "block": "{\"symbols\":[\"Footer\",\"@sidebarSwitcher\",\"@data\",\"@task\",\"@message\",\"@title\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"data-test-id\",\"fade-background\"],[11,\"class\",[29,[\"sidebar-background \",[28,\"if\",[[24,[\"fadeBackground\"]],\"faded\",\"transparent\"],null]]]],[11,\"onclick\",[28,\"perform\",[[23,4,[]],[23,3,[]]],null]],[8],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"sidebar \",[22,\"className\"]]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui grid\"],[10,\"data-test-id\",\"sidebar-body\"],[8],[0,\"\\n\\n    \"],[14,7,[[28,\"hash\",null,[[\"header\",\"body\"],[[28,\"component\",[\"side-bar/right-popup/header\"],[[\"title\",\"task\",\"message\",\"data\",\"isDisabled\"],[[23,6,[]],[24,[\"task\"]],[23,5,[]],[23,3,[]],[24,[\"isDisabled\"]]]]],[28,\"component\",[\"side-bar/right-popup/body\"],null]]]]]],[0,\"\\n\\n\"],[4,\"if\",[[23,2,[]]],null,{\"statements\":[[4,\"let\",[[28,\"component\",[\"side-bar/right-popup/footer\"],null]],null,{\"statements\":[[0,\"        \"],[6,[23,1,[]],[],[[\"@switchSideBarTask\",\"@title\"],[[23,2,[\"switchTask\"]],[23,2,[\"nextSidebarTitle\"]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"pointer\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/side-bar/right-popup.hbs"
    }
  });

  _exports.default = _default;
});