define('ember-click-outside/components/click-outside', ['exports', 'ember-click-outside/component', 'ember-click-outside/utils'], function (exports, _component, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  (0, _utils.printConsoleMessage)(`Importing 'ember-click-outside/components/click-outside' is deprecated. Please import from 'ember-click-outside/component' instead.`);

  exports.default = _component.default;
});