define("ember-components/components/side-bar/numeric-input", ["exports", "ember-components/templates/components/side-bar/numeric-input"], function (_exports, _numericInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _numericInput.default,
    "data-test-id": "numeric-input",
    maxLength: null,
    fadeBackground: true,
    type: "integer",
    defaultIcon: "icon calculator"
  });

  _exports.default = _default;
});