define("ember-components/templates/components/side-bar/numeric-input/ui-design", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eitYEVAR",
    "block": "{\"symbols\":[\"&default\",\"@icon\"],\"statements\":[[4,\"if\",[[24,[\"isParentBlock\"]]],null,{\"statements\":[[0,\"  \"],[14,1,[[28,\"hash\",null,[[\"template\",\"popup\",\"model\",\"age\"],[[24,[\"ui\",\"template\"]],[24,[\"ui\",\"popup\"]],[24,[\"selectedItem\"]],[28,\"component\",[\"side-bar/numeric-input/date/age\"],[[\"age\"],[[24,[\"age\"]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[24,[\"ui\",\"template\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[24,[\"ui\",\"icon\"]],\"expected `ui.icon` to be a contextual component but found a string. Did you mean `(component ui.icon)`? ('ember-components/templates/components/side-bar/numeric-input/ui-design.hbs' @ L12:C2) \"],null]],[[\"readOnly\",\"icon\"],[[24,[\"readOnly\"]],[23,2,[]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"isRightPopupOpen\"]]],null,{\"statements\":[[0,\"  \"],[14,1,[[28,\"hash\",null,[[\"validator\"],[[28,\"component\",[\"side-bar/numeric-input/validator\"],[[\"customValidationRules\"],[[24,[\"customValidationRules\"]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/side-bar/numeric-input/ui-design.hbs"
    }
  });

  _exports.default = _default;
});