define("ember-components/helpers/ms-to-mins", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.msToMins = msToMins;
  _exports.default = void 0;
  const MINUTE = 60000;

  function msToMins(milliseconds) {
    if (!milliseconds) return "0";
    const result = Math.floor(milliseconds / MINUTE);
    return result > 0 ? `+${result}` : `${result}`;
  }

  var _default = Ember.Helper.helper(msToMins);

  _exports.default = _default;
});