define("ember-components/components/medicaments-list/medicament-events/head", ["exports", "ember-components/templates/components/medicaments-list/medicament-events/head"], function (_exports, _head) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _head.default,
    tagName: "thead"
  });

  _exports.default = _default;
});