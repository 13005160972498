define("ember-components/mixins/validation-class-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    classNameBindings: ["validationError:error", "validationWarning:warning"],
    "data-test-validation": Ember.computed("validation", "validation.type", "validation.message", function () {
      return this.get("validation.type");
    }),
    validationError: Ember.computed("validation", "validation.type", "validation.message", function () {
      return this.get("validation.type") === "error";
    }),
    validationWarning: Ember.computed("validation", "validation.type", "validation.message", function () {
      return this.get("validation.type") === "warning";
    })
  });

  _exports.default = _default;
});