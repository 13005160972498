define("ember-components/templates/components/center-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NG8lhK3z",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui dimmer modals page visible active z-index-max\"],[10,\"data-test-id\",\"dimmer-close\"],[8],[0,\"\\n  \"],[5,\"click-outside\",[[12,\"class\",\"center-modal\"]],[[\"@action\",\"@except-selector\"],[[28,\"action\",[[23,0,[]],\"closeModal\"],null],\".daterangepicker\"]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"ui modal transition visible active\"],[8],[0,\"\\n      \"],[14,1,[[28,\"hash\",null,[[\"header\"],[[28,\"component\",[\"center-modal/header\"],null]]]]]],[0,\"\\n      \"],[14,1,[[28,\"hash\",null,[[\"body\"],[[28,\"component\",[\"center-modal/body\"],null]]]]]],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/center-modal.hbs"
    }
  });

  _exports.default = _default;
});