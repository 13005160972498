define("ember-components/components/side-bar/single-select/favourite-select", ["exports", "ember-components/templates/components/side-bar/single-select/favourite-select", "ember-components/mixins/validation-class-mixin"], function (_exports, _favouriteSelect, _validationClassMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_validationClassMixin.default, {
    layout: _favouriteSelect.default,
    classNames: ["favourite-block input-view"],
    classNameBindings: ["validation.type", "isRightPopupOpen:focused"],
    "data-test-id": "favourite-block",
    isItemsLengthNotEqualWithFavourites: Ember.computed("items", "favourites", function () {
      const {
        favourites,
        items
      } = this;
      return items && favourites ? items.length !== favourites.length : true;
    }),
    actions: {
      onSelectedItem(selected) {
        Ember.set(this, "selectedItem", selected);
        return typeof this.onSelectedItem === "function" ? this.onSelectedItem(selected) : null;
      },

      onRightPopupOpen() {
        Ember.set(this, "isRightPopupOpen", true);
        this.onRightPopupOpen && this.onRightPopupOpen();
      }

    }
  });

  _exports.default = _default;
});