define("ember-components/helpers/extract-props-by-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.extractPropsByKey = extractPropsByKey;

  function extractPropsByKey(array, key) {
    if (!array || !key) return array;
    return array.map(element => Ember.isEmpty(element[key]) ? element : element[key]);
  }
});