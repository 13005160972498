define("ember-components/components/navigation-menu/item", ["exports", "ember-components/templates/components/navigation-menu/item"], function (_exports, _item) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _item.default,
    tagName: "span",
    "data-test-id": "menu-item"
  });

  _exports.default = _default;
});