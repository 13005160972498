define("ember-components/components/filter-composer/filters-template", ["exports", "ember-components/templates/components/filter-composer/filters-template"], function (_exports, _filtersTemplate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _filtersTemplate.default
  });

  _exports.default = _default;
});