define("ember-components/components/section-header", ["exports", "ember-components/templates/components/section-header"], function (_exports, _sectionHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _sectionHeader.default,
    classNames: ["section-header ui container fluid"],
    "data-test-id": "section-header"
  });

  _exports.default = _default;
});