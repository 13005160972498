define("ember-components/templates/components/task-wrapper/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "peQMD/sF",
    "block": "{\"symbols\":[\"&default\",\"@task\"],\"statements\":[[4,\"if\",[[23,2,[\"last\",\"isError\"]]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/task-wrapper/error.hbs"
    }
  });

  _exports.default = _default;
});