define("ember-components/components/side-bar/time-picker", ["exports", "ember-components/templates/components/side-bar/time-picker", "moment", "ember-components/mixins/sidebar-switcher"], function (_exports, _timePicker, _moment, _sidebarSwitcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_sidebarSwitcher.default, {
    layout: _timePicker.default,
    "data-test-id": "time-picker",
    timeService: Ember.inject.service("time"),
    intl: Ember.inject.service(),
    hideFavouriteOptions: false,
    customValidationRules: [],
    error: null,
    items: Ember.computed(function () {
      return [{
        name: this.intl.t("components.general.now"),
        difference: 0,
        unit: "d",
        code: "now"
      }, {
        name: "-1 min",
        difference: -1,
        unit: "m",
        code: "-1 min"
      }, {
        name: "-5 min",
        difference: -5,
        unit: "m",
        code: "-5 min"
      }];
    }),
    isFavouriteOptionsFeatureEnabled: Ember.computed("time", "hideFavouriteOptions", {
      get() {
        return !this.hideFavouriteOptions && !this.time;
      },

      set: (key, value) => value
    }),
    now: Ember.computed({
      get() {
        return this.timeService.getTime();
      },

      set: (key, value) => value
    }),

    validate(time) {
      for (let i = 0; i < this.customValidationRules.length; i++) {
        const error = this.customValidationRules[i](time);
        if (error) return error;
      }

      return null;
    },

    validateTime(time) {
      const error = this.validate(time);
      Ember.set(this, "error", error);
    },

    actions: {
      setTime(time, difference = 0, unit = "") {
        if (!time) time = this.now;
        let alteredTime = unit ? (0, _moment.default)(time).add(difference, unit) : (0, _moment.default)(time);
        alteredTime = alteredTime.set({
          second: 0,
          millisecond: 0
        });
        this.validateTime(alteredTime);
        if (!Ember.get(this, "error")) Ember.set(this, "time", alteredTime);
        this.onTimeChanged && this.onTimeChanged(this.time);
      }

    }
  });

  _exports.default = _default;
});