define("ember-components/templates/components/medicaments-list/medicament-events/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Elozeho+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"tr\",true],[8],[0,\"\\n  \"],[14,1,[[28,\"hash\",null,[[\"th\"],[[28,\"component\",[\"medicaments-list/medicament-events/head/th\"],null]]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/medicaments-list/medicament-events/head.hbs"
    }
  });

  _exports.default = _default;
});