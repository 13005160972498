define("ember-components/templates/components/medicaments-list/drill-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ARgzi5NS",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"isSelected\"]]],null,{\"statements\":[[0,\"  \"],[7,\"tr\",true],[10,\"class\",\"drill-down-row\"],[10,\"data-test-id\",\"drill-down\"],[8],[0,\"\\n    \"],[7,\"td\",true],[11,\"colspan\",[22,\"colspanCount\"]],[8],[0,\"\\n      \"],[14,1,[[28,\"hash\",null,[[\"medicamentEvents\"],[[28,\"component\",[\"medicaments-list/medicament-events\"],null]]]]]],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"addEmptyLineAfterDrillDown\"]]],null,{\"statements\":[[0,\"    \"],[7,\"tr\",true],[10,\"data-test-id\",\"empty-line\"],[8],[0,\"\\n      \"],[7,\"td\",true],[10,\"class\",\"empty-line\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/medicaments-list/drill-down.hbs"
    }
  });

  _exports.default = _default;
});