define("ember-components/components/side-bar/numeric-input/validator", ["exports", "ember-components/templates/components/side-bar/numeric-input/validator"], function (_exports, _validator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _validator.default,
    tagName: "",

    init() {
      this._super(...arguments);

      this.customValidationRules.pushObject(this.validate);
    }

  });

  _exports.default = _default;
});