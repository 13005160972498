define("ember-in-viewport/mixins/in-viewport", ["exports", "ember-in-viewport/-private/raf-admin", "ember-in-viewport/utils/can-use-dom", "ember-in-viewport/utils/can-use-raf", "ember-in-viewport/utils/find-elem", "ember-in-viewport/utils/can-use-intersection-observer", "ember-in-viewport/utils/check-scroll-direction"], function (_exports, _rafAdmin, _canUseDom, _canUseRaf, _findElem, _canUseIntersectionObserver, _checkScrollDirection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const lastDirection = {};
  const lastPosition = {};

  var _default = Ember.Mixin.create({
    /**
     * @property _debouncedEventHandler
     * @default null
     */
    _debouncedEventHandler: null,

    /**
     * unbinding listeners will short circuit rAF
     *
     * @property _stopListening
     * @default false
     */
    _stopListening: false,
    inViewport: Ember.inject.service(),

    /**
     * @property viewportExited
     * @type Boolean
     */
    viewportExited: Ember.computed.not('viewportEntered').readOnly(),

    init() {
      // ensure this mixin runs first, then your component can override the options
      this._super(...arguments);

      let options = Ember.assign({
        viewportUseRAF: (0, _canUseRaf.default)(),
        viewportEntered: false,
        viewportListeners: []
      }, this._buildOptions()); // set viewportUseIntersectionObserver after merging users config to avoid errors in browsers that lack support (https://github.com/DockYard/ember-in-viewport/issues/146)

      options = Ember.assign(options, {
        viewportUseIntersectionObserver: (0, _canUseIntersectionObserver.default)()
      });
      Ember.setProperties(this, options);
      Ember.set(this, '_evtListenerClosures', []);
    },

    didInsertElement() {
      this._super(...arguments);

      if (!_canUseDom.default) {
        return;
      }

      const viewportEnabled = Ember.get(this, 'viewportEnabled');

      if (viewportEnabled) {
        this.watchElement(Ember.get(this, 'element'));
      }
    },

    willDestroyElement() {
      this._super(...arguments);

      this._unbindListeners(Ember.get(this, 'element'));
    },

    _buildOptions(defaultOptions = {}) {
      const owner = Ember.getOwner(this);

      if (owner) {
        return Ember.assign(defaultOptions, owner.lookup('config:in-viewport'));
      }
    },

    watchElement(element) {
      this._setInitialViewport(element);

      this._addObserverIfNotSpying(element); // TODO: future make this by default false in major release
      // This adds a performance hit and likely many aren't using didScroll hook
      // Willing to leave as true if enough people do use it


      const viewportDidScroll = Ember.get(this, 'viewportDidScroll');

      if (viewportDidScroll) {
        this._bindScrollDirectionListener(Ember.get(this, 'viewportScrollSensitivity'));
      }

      if (!Ember.get(this, 'viewportUseIntersectionObserver') && !Ember.get(this, 'viewportUseRAF')) {
        Ember.get(this, 'viewportListeners').forEach(listener => {
          let {
            context,
            event
          } = listener;
          context = Ember.get(this, 'scrollableArea') || context;

          this._bindListeners(context, event, element);
        });
      }
    },

    _addObserverIfNotSpying(element) {
      if (!Ember.get(this, 'viewportSpy')) {
        this.addObserver('viewportEntered', this, Ember.run.bind(this, '_unbindIfEntered', element));
      }
    },

    _setInitialViewport(element) {
      const isTearingDown = this.isDestroyed || this.isDestroying;

      if (!element || isTearingDown) {
        return;
      }

      const inViewport = Ember.get(this, 'inViewport');

      if (Ember.get(this, 'viewportUseIntersectionObserver')) {
        inViewport.startIntersectionObserver();
        return Ember.run.scheduleOnce('afterRender', this, () => {
          const scrollableArea = Ember.get(this, 'scrollableArea');
          const viewportTolerance = Ember.get(this, 'viewportTolerance');
          const intersectionThreshold = Ember.get(this, 'intersectionThreshold');
          inViewport.watchElement(element, {
            intersectionThreshold,
            viewportTolerance,
            scrollableArea
          }, Ember.run.bind(this, this._onEnterIntersection), Ember.run.bind(this, this._onExitIntersection));
        });
      } else if (Ember.get(this, 'viewportUseRAF')) {
        inViewport.startRAF();
        const scrollableArea = Ember.get(this, 'scrollableArea');
        const viewportTolerance = Ember.get(this, 'viewportTolerance');
        const viewportSpy = Ember.get(this, 'viewportSpy');

        const enterCallback = () => {
          const isTearingDown = this.isDestroyed || this.isDestroying;
          const viewportEntered = element.getAttribute('data-in-viewport-entered') === "true";

          if (!isTearingDown && (viewportSpy || viewportEntered)) {
            Ember.set(this, 'viewportEntered', true);
            this.trigger('didEnterViewport');
          }
        };

        const exitCallback = () => {
          const isTearingDown = this.isDestroyed || this.isDestroying;

          if (!isTearingDown && viewportSpy) {
            Ember.set(this, 'viewportEntered', false);
            this.trigger('didExitViewport');
          }
        };

        (0, _rafAdmin.startRAF)(element, {
          scrollableArea,
          viewportTolerance,
          viewportSpy
        }, enterCallback, exitCallback, inViewport.addRAF.bind(inViewport, element.id), inViewport.removeRAF.bind(inViewport, element.id));
      } else {
        return Ember.run.scheduleOnce('afterRender', this, () => {
          this._setViewportEntered(element);
        });
      }
    },

    /**
     * used by rAF and scroll event listeners to determine if mixin is in viewport
     * Remember to set `viewportSpy` to true if you want to continuously observe your element
     *
     * @method _setViewportEntered
     */
    _setViewportEntered(element) {
      const scrollableArea = Ember.get(this, 'scrollableArea') ? document.querySelector(Ember.get(this, 'scrollableArea')) : undefined;
      const height = scrollableArea ? scrollableArea.offsetHeight + scrollableArea.getBoundingClientRect().top : window.innerHeight;
      const width = scrollableArea ? scrollableArea.offsetWidth + scrollableArea.getBoundingClientRect().left : window.innerWidth;
      const boundingClientRect = element.getBoundingClientRect();

      if (boundingClientRect) {
        this._triggerDidAccessViewport(Ember.get(this, 'inViewport').isInViewport(boundingClientRect, height, width, Ember.get(this, 'viewportTolerance')), Ember.get(this, 'viewportEntered'));

        if (Ember.get(this, 'viewportUseRAF') && !Ember.get(this, '_stopListening')) {
          Ember.get(this, 'inViewport').addRAF(Ember.get(this, 'elementId'), Ember.run.bind(this, this._setViewportEntered, element));
        }
      }
    },

    /**
     * Callback provided to IntersectionObserver
     * trigger didEnterViewport callback
     *
     * @method _onEnterIntersection
     */
    _onEnterIntersection() {
      const isTearingDown = this.isDestroyed || this.isDestroying;

      if (!isTearingDown) {
        Ember.set(this, 'viewportEntered', true);
      }

      this.trigger('didEnterViewport');
    },

    /**
     * trigger didExitViewport callback
     *
     * @method _onExitIntersection
     */
    _onExitIntersection() {
      const isTearingDown = this.isDestroyed || this.isDestroying;

      if (!isTearingDown) {
        Ember.set(this, 'viewportEntered', false);
      }

      this.trigger('didExitViewport');
    },

    /**
     * @method _triggerDidScrollDirection
     * @param contextEl
     * @param sensitivity
     */
    _triggerDidScrollDirection(contextEl = null, sensitivity = 1) {
      (false && !(contextEl) && Ember.assert('You must pass a valid context element to _triggerDidScrollDirection', contextEl));
      (false && !(sensitivity) && Ember.assert('sensitivity cannot be 0', sensitivity));
      const elementId = Ember.get(this, 'elementId');
      const lastDirectionForEl = lastDirection[elementId];
      const lastPositionForEl = lastPosition[elementId];
      const newPosition = {
        top: contextEl.scrollTop,
        left: contextEl.scrollLeft
      };
      const scrollDirection = (0, _checkScrollDirection.default)(lastPositionForEl, newPosition, sensitivity);
      const directionChanged = scrollDirection !== lastDirectionForEl;

      if (scrollDirection && directionChanged && Ember.get(this, 'viewportDidScroll')) {
        this.trigger('didScroll', scrollDirection);
        lastDirection[elementId] = scrollDirection;
      }

      lastPosition[elementId] = newPosition;
    },

    /**
     * @method _triggerDidAccessViewport
     * @param hasEnteredViewport
     * @param viewportEntered
     */
    _triggerDidAccessViewport(hasEnteredViewport = false, viewportEntered) {
      const isTearingDown = this.isDestroyed || this.isDestroying;

      if (isTearingDown) {
        return;
      }

      const didEnter = !viewportEntered && hasEnteredViewport;
      const didLeave = viewportEntered && !hasEnteredViewport;
      let triggeredEventName = '';

      if (didEnter) {
        triggeredEventName = 'didEnterViewport';
      }

      if (didLeave) {
        triggeredEventName = 'didExitViewport';
      }

      if (Ember.get(this, 'viewportSpy') || !viewportEntered) {
        Ember.set(this, 'viewportEntered', hasEnteredViewport);
      }

      if (triggeredEventName) {
        this.trigger(triggeredEventName);
      }
    },

    /**
     * Unbind when enter viewport only if viewportSpy is false
     *
     * @method _unbindIfEntered
     */
    _unbindIfEntered(element) {
      if (Ember.get(this, 'viewportEntered')) {
        this._unbindListeners(element);

        this.removeObserver('viewportEntered', this, '_unbindIfEntered');
        Ember.set(this, 'viewportEntered', false);
      }
    },

    /**
     * General utility function
     *
     * @method _debouncedEvent
     */
    _debouncedEvent(methodName, ...args) {
      (false && !(methodName) && Ember.assert('You must pass a methodName to _debouncedEvent', methodName));
      (false && !(Ember.typeOf(methodName) === 'string') && Ember.assert('methodName must be a string', Ember.typeOf(methodName) === 'string'));
      Ember.run.debounce(this, () => this[methodName](...args), Ember.get(this, 'viewportRefreshRate'));
    },

    _bindScrollDirectionListener(sensitivity = 1) {
      (false && !(sensitivity) && Ember.assert('sensitivity cannot be 0', sensitivity));
      const contextEl = Ember.get(this, 'scrollableArea') || window;
      const elem = (0, _findElem.default)(contextEl);
      this._debouncedEventHandler = this._debouncedEvent.bind(this, '_triggerDidScrollDirection', elem, sensitivity);
      elem.addEventListener('scroll', this._debouncedEventHandler, {
        passive: true
      });
    },

    _unbindScrollDirectionListener() {
      const elementId = Ember.get(this, 'elementId');
      const context = Ember.get(this, 'scrollableArea') || window;
      const elem = (0, _findElem.default)(context);

      if (elem) {
        elem.removeEventListener('scroll', this._debouncedEventHandler, {
          passive: true
        });
        delete lastPosition[elementId];
        delete lastDirection[elementId];
      }
    },

    /**
     * Only if not using IntersectionObserver and rAF
     *
     * @method _bindListeners
     */
    _bindListeners(context = null, event = null, element = null) {
      (false && !(context) && Ember.assert('You must pass a valid context to _bindListeners', context));
      (false && !(event) && Ember.assert('You must pass a valid event to _bindListeners', event));
      let elem = (0, _findElem.default)(context);

      let evtListener = () => this._debouncedEvent('_setViewportEntered', element);

      this._evtListenerClosures.push({
        event: event,
        evtListener
      });

      elem.addEventListener(event, evtListener, false);
    },

    /**
     * Remove listeners for rAF or scroll event listeners
     * Either from component destroy or viewport entered and
     * need to turn off listening
     *
     * @method _unbindListeners
     */
    _unbindListeners(element) {
      Ember.set(this, '_stopListening', true); // if IntersectionObserver

      if (Ember.get(this, 'viewportUseIntersectionObserver') && Ember.get(this, 'viewportEnabled')) {
        Ember.get(this, 'inViewport').unobserveIntersectionObserver(element);
      } // if rAF


      if (!Ember.get(this, 'viewportUseIntersectionObserver') && Ember.get(this, 'viewportUseRAF')) {
        const elementId = Ember.get(this, 'elementId');
        Ember.get(this, 'inViewport').removeRAF(elementId);
      } // if scroll event listeners


      if (!Ember.get(this, 'viewportUseIntersectionObserver') && !Ember.get(this, 'viewportUseRAF')) {
        Ember.get(this, 'viewportListeners').forEach(listener => {
          let {
            context,
            event
          } = listener;
          context = Ember.get(this, 'scrollableArea') || context;
          let elem = (0, _findElem.default)(context);
          let {
            evtListener
          } = this._evtListenerClosures.find(closure => event === closure.event) || {};
          elem.removeEventListener(event, evtListener, false);
        });
      } // 4. last but not least


      const viewportDidScroll = Ember.get(this, 'viewportDidScroll');

      if (viewportDidScroll) {
        this._unbindScrollDirectionListener();
      }
    }

  });

  _exports.default = _default;
});