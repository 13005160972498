define('ember-click-outside/mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const bound = function bound(fnName) {
    return Ember.computed(fnName, function () {
      let fn = Ember.get(this, fnName);
      if (fn) {
        // https://github.com/zeppelin/ember-click-outside/issues/1
        return fn.bind(this);
      }
    });
  };

  const ios = () => {
    return (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    );
  };

  const documentOrBodyContains = element => {
    // https://github.com/zeppelin/ember-click-outside/issues/30
    if (typeof document.contains === 'function') {
      return document.contains(element);
    } else {
      return document.body.contains(element);
    }
  };

  exports.default = Ember.Mixin.create({
    clickOutside() {},
    clickHandler: bound('outsideClickHandler'),

    didInsertElement() {
      this._super(...arguments);

      if (!ios()) {
        return;
      }

      document.body.style.cursor = 'pointer';
    },

    willDestroyElement() {
      this._super(...arguments);

      if (!ios()) {
        return;
      }

      document.body.style.cursor = '';
    },

    outsideClickHandler(e) {
      const element = Ember.get(this, 'element');

      // Check if the click target still is in the DOM.
      // If not, there is no way to know if it was inside the element or not.
      const isRemoved = !e.target || !documentOrBodyContains(e.target);

      // Check the element is found as a parent of the click target.
      const isInside = element === e.target || element.contains(e.target);

      if (!isRemoved && !isInside) {
        this.clickOutside(e);
      }
    },

    addClickOutsideListener() {
      const clickHandler = Ember.get(this, 'clickHandler');
      document.addEventListener('click', clickHandler);
    },

    removeClickOutsideListener() {
      const clickHandler = Ember.get(this, 'clickHandler');
      document.removeEventListener('click', clickHandler);
    }
  });
});