define("ember-components/mixins/pad-actions", ["exports", "ember-keyboard"], function (_exports, _emberKeyboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const numpadKeyUp = key => (0, _emberKeyboard.keyUp)(`Numpad${key}`);

  const digitKeyUp = key => (0, _emberKeyboard.keyUp)(`Digit${key}`);

  var _default = Ember.Mixin.create(_emberKeyboard.EKMixin, {
    activateKeyboard: Ember.on("init", function () {
      this.set("keyboardActivated", this.isRightPopupOpen);
    }),
    onRightPopupChange: Ember.observer("isRightPopupOpen", function () {
      this.set("keyboardActivated", this.isRightPopupOpen);
    }),

    isFocused() {
      return this.element.querySelector("input") === document.activeElement;
    },

    catchTyping: Ember.on(numpadKeyUp("0"), numpadKeyUp("1"), numpadKeyUp("2"), numpadKeyUp("3"), numpadKeyUp("4"), numpadKeyUp("5"), numpadKeyUp("6"), numpadKeyUp("7"), numpadKeyUp("8"), numpadKeyUp("9"), digitKeyUp("0"), digitKeyUp("1"), digitKeyUp("2"), digitKeyUp("3"), digitKeyUp("4"), digitKeyUp("5"), digitKeyUp("6"), digitKeyUp("7"), digitKeyUp("8"), digitKeyUp("9"), (0, _emberKeyboard.keyUp)("Period"), numpadKeyUp("Comma"), (0, _emberKeyboard.keyUp)("shift+Equal"), numpadKeyUp("Add"), function (event) {
      if (!this.isFocused() && event.keyCode < 106 && event.keyCode > 95 && !isNaN(event.key)) this.onNumberSelect(event.key);
    }),
    onDeleteNumber: Ember.on((0, _emberKeyboard.keyUp)("Backspace"), function () {
      if (!this.isFocused()) this.onNumberDelete();
    })
  });

  _exports.default = _default;
});