define("ember-components/components/side-bar/single-select/options-not-found", ["exports", "ember-components/templates/components/side-bar/single-select/options-not-found"], function (_exports, _optionsNotFound) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _optionsNotFound.default,
    classNames: ["options-not-found"]
  });

  _exports.default = _default;
});