define("ember-components/templates/components/side-bar/numeric-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HT6ra22p",
    "block": "{\"symbols\":[\"ui\",\"@onMaskFilled\",\"@onInputChange\",\"&default\",\"@required\",\"@prefix\",\"@unit\",\"@value\",\"@icon\",\"@title\",\"@favourites\",\"@readOnly\",\"@validation\"],\"statements\":[[4,\"if\",[[25,4]],null,{\"statements\":[[4,\"component\",[[28,\"concat\",[\"side-bar/numeric-input/\",[24,[\"type\"]]],null]],[[\"validation\",\"value\",\"readOnly\",\"type\",\"favourites\",\"title\",\"maxLength\",\"unit\",\"prefix\",\"fadeBackground\",\"required\",\"isParentBlock\",\"icon\",\"onInputChange\",\"onMaskFilled\",\"isRightPopupOpen\"],[[23,13,[]],[23,8,[]],[23,12,[]],[24,[\"type\"]],[23,11,[]],[23,10,[]],[24,[\"maxLength\"]],[23,7,[]],[23,6,[]],[24,[\"fadeBackground\"]],[23,5,[]],[25,4],[28,\"if\",[[23,9,[]],[23,9,[]],[24,[\"defaultIcon\"]]],null],[23,3,[]],[23,2,[]],[24,[\"isRightPopupOpen\"]]]],{\"statements\":[[0,\"    \"],[14,4,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"component\",[[28,\"concat\",[\"side-bar/numeric-input/\",[24,[\"type\"]]],null]],[[\"validation\",\"value\",\"readOnly\",\"type\",\"favourites\",\"title\",\"maxLength\",\"unit\",\"prefix\",\"fadeBackground\",\"required\",\"isParentBlock\",\"icon\",\"onInputChange\",\"onMaskFilled\",\"isRightPopupOpen\"],[[24,[\"validation\"]],[23,8,[]],[24,[\"readOnly\"]],[24,[\"type\"]],[24,[\"favourites\"]],[24,[\"title\"]],[24,[\"maxLength\"]],[23,7,[]],[23,6,[]],[24,[\"fadeBackground\"]],[23,5,[]],[25,4],[28,\"if\",[[24,[\"icon\"]],[24,[\"icon\"]],[24,[\"defaultIcon\"]]],null],[23,3,[]],[23,2,[]],[24,[\"isRightPopupOpen\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/side-bar/numeric-input.hbs"
    }
  });

  _exports.default = _default;
});