define("ember-components/components/side-bar/multi-input/input", ["exports", "ember-components/templates/components/side-bar/multi-input/input"], function (_exports, _input) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _input.default,
    "data-test-id": "input",
    device: Ember.inject.service(),
    classNames: ["ui input input-container"],
    classNameBindings: ["isFocused:focused"],

    getInputSelector(element) {
      return element && element.getElementsByTagName("input")[0];
    },

    isInputActive() {
      return document.activeElement === this.getInputSelector(this.element);
    },

    blurCurrentInputAndFocusNext(nextInput, focus) {
      nextInput && Ember.run.next(() => {
        this.blurInput();
        nextInput.click();
        focus && nextInput.focus();
      });
    },

    async checkForInputChangeRule(inputValue) {
      if (this.changeToNextInput && this.changeToNextInput(inputValue)) {
        const nextElement = this.element.nextElementSibling;
        const nextInput = nextElement && this.getInputSelector(nextElement);
        this.blurCurrentInputAndFocusNext(nextInput, this.isInputActive());
      }
    },

    isFocused: Ember.computed("focusedInputId", {
      get() {
        return this.focusedInputId === this.element.id;
      },

      set: (key, value) => value
    }),
    onInputChanged: Ember.observer("inputValue", function () {
      this.onInputChange && this.onInputChange(this.inputValue);
    }),
    parsedInputValue: Ember.computed("inputValue", function () {
      const {
        inputValue
      } = this;
      const parsedValue = Ember.isPresent(inputValue) ? inputValue : "";
      this.checkForInputChangeRule(parsedValue);
      return parsedValue;
    }),

    didInsertElement() {
      this._super(...arguments);

      const {
        inputsMap,
        element
      } = this;
      inputsMap[element.id] = {
        context: this
      };
    },

    blurInput() {
      const input = this.getInputSelector(this.element);
      input && input.blur();
    },

    focusInput() {
      const input = this.getInputSelector(this.element);
      input && input.focus();
    },

    updateFocusedInput() {
      this.element && this.setFocusedInput(this.element.id);
    },

    onMobileInputClicked() {
      if (this.isFocused) {
        this.focusInput();
      } else {
        this.blurInput();
        this.updateFocusedInput();
      }
    },

    actions: {
      onKeyPressed(event) {
        this.onKeyPressed(event);
      },

      onInputClicked() {
        if (this.device.isDesktop()) {
          this.focusInput();
          this.updateFocusedInput();
        } else {
          this.onMobileInputClicked();
        }
      }

    }
  });

  _exports.default = _default;
});