define("ember-components/templates/components/click-outside-disabler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lutSw3Aa",
    "block": "{\"symbols\":[\"@disable\"],\"statements\":[[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"click-outside-disabler\"],[10,\"data-test-id\",\"disabler\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/click-outside-disabler.hbs"
    }
  });

  _exports.default = _default;
});