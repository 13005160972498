define("ember-components/templates/components/value-switcher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k+6FGj/x",
    "block": "{\"symbols\":[\"@first\",\"@selected\",\"@firstTitle\",\"@second\",\"@secondTitle\"],\"statements\":[[7,\"div\",true],[10,\"data-test-id\",\"first-label\"],[11,\"class\",[28,\"if\",[[28,\"eq\",[[23,2,[]],[23,1,[]]],null],\"active\"],null]],[8],[1,[23,3,[]],false],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"ui toggle checkbox switcher\"],[8],[0,\"\\n  \"],[7,\"input\",true],[10,\"data-test-id\",\"switcher\"],[10,\"class\",\"input-view\"],[11,\"checked\",[28,\"if\",[[28,\"eq\",[[23,2,[]],[23,4,[]]],null],\"checked\"],null]],[10,\"type\",\"checkbox\"],[8],[9],[0,\"\\n  \"],[7,\"label\",true],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"data-test-id\",\"second-label\"],[11,\"class\",[28,\"if\",[[28,\"eq\",[[23,2,[]],[23,4,[]]],null],\"active\"],null]],[8],[1,[23,5,[]],false],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/value-switcher.hbs"
    }
  });

  _exports.default = _default;
});