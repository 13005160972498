define("ember-intl/macro", ["exports", "ember-intl/-private/empty-object"], function (_exports, _emptyObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.raw = raw;
  _exports.default = createTranslatedComputedProperty;

  function partitionDynamicValuesAndStaticValues(options) {
    const dynamicValues = new _emptyObject.default();
    const staticValues = new _emptyObject.default();
    Object.keys(options).forEach(key => {
      const value = options[key];

      if (value instanceof Raw) {
        staticValues[key] = value.valueOf();
      } else {
        dynamicValues[key] = value;
      }
    });
    return [dynamicValues, staticValues];
  }

  function mapPropertiesByHash(object, hash) {
    const result = new _emptyObject.default();
    Object.keys(hash).forEach(key => {
      result[key] = Ember.get(object, hash[key]);
    });
    return result;
  }
  /**
   * This class is used to box primitive types and mark them as raw literals that
   * should be used as is by the translation macro.
   *
   * This class is internal. Instead of using this class directly, use the `raw`
   * utility function, that creates an instance of this class.
   */


  class Raw {
    constructor(value) {
      this._value = value;
    }

    valueOf() {
      return this._value;
    }

    toString() {
      return String(this._value);
    }

  }
  /**
   * Use this utility function to mark a value as a raw literal.
   *
   * @param {*} value The value to mark as a raw literal.
   * @return The same value, but boxed in the `Raw` class so that the consuming
   *  macro understands that this value should be used as is.
   */


  function raw(value) {
    return new Raw(value);
  }

  function createTranslatedComputedProperty(key, options) {
    const hash = options || new _emptyObject.default();
    const [dynamicValues, staticValues] = partitionDynamicValuesAndStaticValues(hash);
    const dependentKeys = ['intl.locale'].concat(Object.values(dynamicValues));
    return Ember.computed(...dependentKeys, function () {
      let intl = Ember.get(this, 'intl');

      if (!intl) {
        const owner = Ember.getOwner(this);
        (false && !(owner) && Ember.assert(`Cannot translate "${key}".\n${this} does not have an 'intl' property set or an owner to look up the service from.`, owner));
        intl = owner.lookup('service:intl');
        (false && !(intl) && Ember.assert(`Cannot translate "${key}".\n${this} does not have an 'intl' property set and there is no 'intl' service registered with the owner.`, intl));
      }

      return intl.t(key, Ember.assign({}, staticValues, mapPropertiesByHash(this, dynamicValues)));
    }).readOnly();
  }
});