define("ember-components/templates/components/letter-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xHxNyAyj",
    "block": "{\"symbols\":[\"range\",\"@items\"],\"statements\":[[4,\"each\",[[24,[\"ranges\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"ui button\"],[10,\"data-test-id\",\"range\"],[11,\"data-test-option\",[29,[[23,1,[\"code\"]]]]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"filterItems\",[23,1,[\"from\"]],[23,1,[\"to\"]]],null]],[8],[0,\"\\n    \"],[1,[23,1,[\"name\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[7,\"div\",false],[12,\"class\",\"ui button\"],[12,\"data-test-id\",\"all\"],[12,\"data-test-option\",\"all\"],[3,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"filteredItems\"]]],null],[23,2,[]]]],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"components.letterFilter.all\"],null],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/letter-filter.hbs"
    }
  });

  _exports.default = _default;
});