define("ember-components/mixins/sticky-element-position", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    classNames: ["sticky-element"],
    elementHasClass: (element, cssClass) => element.classList.contains(cssClass),
    getOffset: (element, type) => element[type],

    findTopElement(currentElement) {
      while (currentElement.previousElementSibling && !this.elementHasClass(currentElement.previousElementSibling, "sticky-element")) currentElement = currentElement.previousElementSibling;

      return currentElement;
    },

    setStickyElementPosition() {
      const {
        element
      } = this;
      const topElement = this.findTopElement(element);
      const top = this.elementHasClass(topElement, "popup-top") ? this.getOffset(topElement, "offsetHeight") : this.getOffset(topElement, "offsetTop");
      element.setAttribute("style", `top: ${top}px`);
    },

    didInsertElement() {
      this._super(...arguments);

      this.setStickyElementPosition();
    }

  });

  _exports.default = _default;
});