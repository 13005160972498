define("ember-components/templates/components/side-bar/single-radiobox/input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OKHryESN",
    "block": "{\"symbols\":[\"@item\",\"@key\",\"@checked\"],\"statements\":[[7,\"i\",true],[11,\"class\",[29,[[28,\"if\",[[23,3,[]],\"dot\",\"\"],null],\" circle outline icon\"]]],[8],[9],[0,\"\\n\"],[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"get\",[[23,1,[]],[23,2,[]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[23,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/side-bar/single-radiobox/input.hbs"
    }
  });

  _exports.default = _default;
});