define("ember-components/components/side-bar/single-select/icon", ["exports", "ember-components/templates/components/side-bar/single-select/icon"], function (_exports, _icon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _icon.default,
    icon: "play icon"
  });

  _exports.default = _default;
});