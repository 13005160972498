define("ember-components/components/side-bar/numeric-input/pad", ["exports", "ember-components/templates/components/side-bar/numeric-input/pad"], function (_exports, _pad) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _pad.default,
    "data-test-id": "numeric-pad",
    actions: {
      onNumberSelect(value) {
        this.onNumberSelect(value);
      }

    }
  });

  _exports.default = _default;
});