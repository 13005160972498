define("ember-components/helpers/starts-with", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.startsWith = startsWith;
  _exports.default = void 0;

  function startsWith([fullString, partString]) {
    return fullString && partString ? fullString.startsWith(partString) : false;
  }

  var _default = Ember.Helper.helper(startsWith);

  _exports.default = _default;
});