define("ember-components/components/side-bar/multi-select/input", ["exports", "ember-components/templates/components/side-bar/multi-select/input"], function (_exports, _input) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _input.default,
    selectedItems: Ember.A(),
    classNames: ["multi-select-input"],

    findItem(objects, objectToFind, key) {
      if (objects) {
        const totalObjects = objects.get("length");

        for (let i = 0; i < totalObjects; i++) {
          const object = objects.objectAt(i);
          const objectPropertyByKey = object.get ? object.get(key) : object[key];

          if (objectPropertyByKey === objectToFind[key]) {
            return object;
          }
        }
      }

      return null;
    },

    itemsWithPositionIndex: Ember.computed("items", function () {
      const map = new Map();
      const {
        items
      } = this;
      items && items.forEach((item, i) => map.set(Ember.get(item, "code"), i));
      return map;
    }),

    addSelectedItem(selectedItems, item) {
      selectedItems.pushObject(item);
      const selectedItemsLength = Ember.get(selectedItems, "length");

      if (selectedItemsLength > 1) {
        const positionMap = this.itemsWithPositionIndex;
        const sortedContent = this.sortItems(selectedItems, positionMap);
        if (selectedItems.replaceContent) selectedItems.replaceContent(0, selectedItemsLength, sortedContent);else selectedItems = sortedContent;
      }
    },

    sortItems(items, positionMap) {
      const content = Ember.get(items, "content") || items;
      return content.sort((a, b) => this.getPosition(a, positionMap) - this.getPosition(b, positionMap));
    },

    getPosition(item, positionMap) {
      return positionMap.get(Ember.get(item, "code"));
    },

    validate(selectedItems) {
      const {
        customValidationRules
      } = this;

      for (let i = 0; i < customValidationRules.length; i++) {
        const validationError = customValidationRules[i](selectedItems);

        if (validationError) {
          this.setValidationObject(validationError);
          return false;
        }
      }

      this.setValidationObject(null);
      return true;
    },

    hasCustomValidations() {
      return this.customValidationRules && this.customValidationRules.length;
    },

    addOrRemoveItem(selectedItems, item, checked) {
      checked ? selectedItems.removeObject(this.findItem(selectedItems, item, "name")) : this.addSelectedItem(selectedItems, item);
    },

    actions: {
      onItemSelect(selectedItems, item, checked) {
        this.addOrRemoveItem(selectedItems, item, checked);
        this.hasCustomValidations() && this.validate(selectedItems);
      }

    }
  });

  _exports.default = _default;
});