define("ember-components/components/side-bar/common/help-block", ["exports", "ember-components/templates/components/side-bar/common/help-block"], function (_exports, _helpBlock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _helpBlock.default,
    "data-test-id": "help",
    isBlockVisible: false,
    classNames: ["help-block"],
    icon: "icon grey info",
    expandIcon: "up",
    collapseIcon: "down",
    classNameBindings: ["isBlockVisible:visible"],

    click() {
      this.toggleProperty("isBlockVisible");
      this.onBlockClick && this.onBlockClick(this.isBlockVisible);
    }

  });

  _exports.default = _default;
});