define("ember-intl/-private/formatters/format-time", ["exports", "ember-intl/-private/formatters/format-date"], function (_exports, _formatDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Copyright 2015, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */
  class FormatTime extends _formatDate.default {}

  _exports.default = FormatTime;
});