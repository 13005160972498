define("ember-components/components/medicaments-list/drill-down", ["exports", "ember-components/templates/components/medicaments-list/drill-down"], function (_exports, _drillDown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _drillDown.default,
    tagName: "",
    addEmptyLineAfterDrillDown: true
  });

  _exports.default = _default;
});