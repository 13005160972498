define("ember-components/components/copyable-fields/from", ["exports", "ember-components/templates/components/copyable-fields/from"], function (_exports, _from) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _from.default,
    classNames: ["from"],
    "data-test-id": "data-from"
  });

  _exports.default = _default;
});