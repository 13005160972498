define("ember-components/helpers/get-selected-item-data", ["exports", "ember-components/mixins/helper-observer"], function (_exports, _helperObserver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend(_helperObserver.default, {
    compute([selectedItem, key]) {
      if (selectedItem && selectedItem.addObserver && key) this.setupRecompute(selectedItem, key);
      if (!key) return "";

      if (selectedItem) {
        const isObject = typeof selectedItem === "object";
        if (isObject && Object.keys(selectedItem).length === 0) return "";
        const value = Ember.get(selectedItem, key);
        if (value || value === "") return value;
        if (isObject) return "";
      }

      return selectedItem;
    }

  });

  _exports.default = _default;
});