define("ember-components/templates/components/medicaments-list/body/types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DAE0H52R",
    "block": "{\"symbols\":[\"type\",\"isSelected\",\"&default\"],\"statements\":[[4,\"each\",[[24,[\"types\"]]],null,{\"statements\":[[4,\"let\",[[28,\"eq\",[[24,[\"selectedMedicamentCode\"]],[23,1,[\"medicamentCode\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"tr\",true],[11,\"class\",[29,[\"medicament-type\",[28,\"if\",[[23,2,[]],\" selected\"],null]]]],[10,\"data-test-id\",\"medicament-type\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"onTypeClicked\",[23,1,[\"medicamentCode\"]],[24,[\"selectedItems\"]]],null]],[8],[0,\"\\n      \"],[14,3,[[28,\"hash\",null,[[\"type\",\"perEvent\",\"total\"],[[28,\"component\",[\"medicaments-list/body/type\"],[[\"typeName\"],[[23,1,[\"name\"]]]]],[28,\"component\",[\"medicaments-list/body/per-event\"],[[\"type\"],[[23,1,[]]]]],[28,\"component\",[\"medicaments-list/body/total\"],[[\"type\",\"isSelected\",\"isDrillDownOptionEnabled\"],[[23,1,[]],[23,2,[]],[24,[\"isDrillDownOptionEnabled\"]]]]]]]]]],[0,\"\\n    \"],[9],[0,\"\\n    \"],[14,3,[[28,\"hash\",null,[[\"drillDown\"],[[28,\"component\",[\"medicaments-list/drill-down\"],[[\"isSelected\",\"colspanCount\"],[[23,2,[]],[24,[\"colspanCount\"]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/medicaments-list/body/types.hbs"
    }
  });

  _exports.default = _default;
});