define("ember-components/components/side-bar/single-radiobox", ["exports", "ember-components/templates/components/side-bar/single-radiobox", "ember-components/mixins/validation-class-mixin", "ember-components/mixins/sidebar-switcher"], function (_exports, _singleRadiobox, _validationClassMixin, _sidebarSwitcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_validationClassMixin.default, _sidebarSwitcher.default, {
    layout: _singleRadiobox.default,
    "data-test-id": "single-radiobox",
    classNames: ["ui", "single-radiobox"],
    classNameBindings: ["isRightPopupOpen:focused"],
    device: Ember.inject.service(),
    fadeBackground: true,
    selectedItem: Ember.computed("selected", {
      get() {
        const {
          selected,
          items
        } = this;
        return selected === false ? items.find(item => item.code === false) : selected;
      },

      set: (key, value) => value
    }),
    actions: {
      onSelectedValue(selected) {
        if (!this.locked) {
          Ember.set(this, "selected", selected);
          if (this.onSelectedValue) return this.onSelectedValue(selected);
        }

        return null;
      },

      onClearValue() {
        Ember.set(this, "selected", null);
        this.onClearValue && this.onClearValue();
      }

    }
  });

  _exports.default = _default;
});