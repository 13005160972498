define("ember-components/components/side-bar/block-input", ["exports", "ember-components/templates/components/side-bar/block-input", "ember-concurrency"], function (_exports, _blockInput, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _blockInput.default,
    "data-test-id": "block-input",
    classNames: ["input-view", "block-container"],
    onRightPopupClose: (0, _emberConcurrency.task)(function* () {
      yield this.set("isRightPopupOpen", false);
    })
  });

  _exports.default = _default;
});