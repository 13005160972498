define("ember-components/components/value-switcher", ["exports", "ember-components/templates/components/value-switcher"], function (_exports, _valueSwitcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["value-switcher"],
    layout: _valueSwitcher.default,

    onSwitch() {
      const {
        first,
        second,
        selected
      } = this;
      Ember.set(this, "selected", selected === first ? second : first);
      if (this.onValueChange) this.onValueChange();
    },

    click() {
      return this.onSwitch();
    }

  });

  _exports.default = _default;
});