define("ember-components/components/side-bar/right-popup/header", ["exports", "ember-components/templates/components/side-bar/right-popup/header"], function (_exports, _header) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _header.default,
    tagName: ""
  });

  _exports.default = _default;
});