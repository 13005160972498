define("ember-components/templates/components/task-reloader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TrJv8rta",
    "block": "{\"symbols\":[\"@task\"],\"statements\":[[4,\"if\",[[23,1,[\"isRunning\"]]],null,{\"statements\":[[0,\"  \"],[5,\"ui-loader\",[],[[\"@isLoading\",\"@cssClass\"],[true,\"normal inline\"]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"i\",true],[10,\"class\",\"sync white icon refresh-icon\"],[10,\"data-test-id\",\"reload-task\"],[11,\"onclick\",[28,\"perform\",[[24,[\"task\"]],[24,[\"arguments\"]]],null]],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/task-reloader.hbs"
    }
  });

  _exports.default = _default;
});