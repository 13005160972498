define("ember-components/components/medicaments-list", ["exports", "ember-components/templates/components/medicaments-list"], function (_exports, _medicamentsList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _medicamentsList.default,
    intl: Ember.inject.service(),
    isDrillDownOptionEnabled: true,
    sortedMedicamentsTypes: Ember.computed("task.last.value", function () {
      const medicaments = this.task.last.value;
      medicaments.forEach(med => med.types && med.types.sort((a, b) => {
        const getTypeName = med => Ember.get(med, "name");

        return getTypeName(a) > getTypeName(b) ? 1 : -1;
      }));
      this.notifyPropertyChange("sortedMedicamentsTypes");
      return medicaments;
    }),
    sortedMedicaments: Ember.computed.sort("sortedMedicamentsTypes", function (a, b) {
      const unknownMedicament = this.intl.t("components.medicaments.unknown");
      const otherMedicament = this.intl.t("components.medicaments.other");

      const getMedCode = med => Ember.get(med, "types.firstObject.medicamentCode");

      const getMedName = med => Ember.get(med, "name");

      const firstMedName = getMedName(a);
      const secondMedName = getMedName(b);
      const isFirstUnknown = firstMedName.includes(unknownMedicament);
      const isFirstOther = firstMedName === otherMedicament;
      const isSecondUnknown = secondMedName.includes(unknownMedicament);
      const isSecondOther = secondMedName === otherMedicament;
      if (isFirstUnknown && isSecondUnknown) return getMedCode(a) > getMedCode(b) ? 1 : -1;else if (isFirstUnknown || isSecondUnknown) return isFirstUnknown ? 1 : -1;else if (isFirstOther || isSecondOther) return isFirstOther ? 1 : -1;else return firstMedName < secondMedName ? -1 : 1;
    })
  });

  _exports.default = _default;
});