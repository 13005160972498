define("ember-components/components/center-modal/body", ["exports", "ember-components/templates/components/center-modal/body"], function (_exports, _body) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _body.default,
    classNames: ["content"],
    "data-test-id": "modal-content"
  });

  _exports.default = _default;
});