define("ember-components/templates/components/side-bar/time-picker/validator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jOxYmgJD",
    "block": "{\"symbols\":[\"@validationError\"],\"statements\":[[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"ui pointing below red basic label custom-validation-popup\"],[10,\"data-test-id\",\"custom-validation-popup\"],[8],[0,\"\\n    \"],[1,[23,1,[]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/side-bar/time-picker/validator.hbs"
    }
  });

  _exports.default = _default;
});