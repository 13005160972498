define("ember-components/templates/components/checkbox-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qdEAQhi4",
    "block": "{\"symbols\":[\"item\",\"@selectedItems\",\"@title\",\"@items\"],\"statements\":[[7,\"p\",true],[10,\"class\",\"title\"],[10,\"data-test-id\",\"title\"],[8],[1,[23,3,[]],false],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"checkboxes\"],[8],[0,\"\\n\"],[4,\"each\",[[23,4,[]]],null,{\"statements\":[[0,\"    \"],[5,\"checkbox-true-null\",[[12,\"data-test-option\",[29,[[23,1,[\"code\"]]]]]],[[\"@title\",\"@showValidationBlock\",\"@checked\",\"@onValueChange\"],[[23,1,[\"name\"]],false,[28,\"is-item-in-array\",[[23,2,[]],[23,1,[]]],null],[28,\"action\",[[23,0,[]],\"onItemSelect\",[23,1,[]]],null]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/checkbox-group.hbs"
    }
  });

  _exports.default = _default;
});