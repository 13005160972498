define("ember-components/components/date-range-picker", ["exports", "ember-components/templates/components/date-range-picker", "moment"], function (_exports, _dateRangePicker, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _dateRangePicker.default,
    classNames: ["date-range-picker-input"],
    dateFormat: "YYYY-MM-DD",
    alwaysShowCalendars: true,
    showCustomRangeLabel: false,
    locale: {
      cancelLabel: "Atšaukti",
      applyLabel: "Pasirinkti",
      monthNames: ["Sausis", "Vasaris", "Kovas", "Balandis", "Gegužė", "Birželis", "Liepa", "Rugpjūtis", "Rugsėjis", "Spalis", "Lapkritis", "Gruodis"]
    },
    applyButtonClasses: "ui green button",
    buttonClasses: "ui button",
    cancelButtonClasses: "ui button",
    linkedCalendars: false,
    from: "",
    to: "",
    ranges: {
      "Šiandien": [(0, _moment.default)().startOf("day"), (0, _moment.default)().endOf("day")],
      "Vakar": [(0, _moment.default)().subtract(1, "days").startOf("day"), (0, _moment.default)().subtract(1, "days").endOf("day")],
      "Praėjusi savaitė": [(0, _moment.default)().subtract(1, "week").startOf("week"), (0, _moment.default)().subtract(1, "week").endOf("week")],
      "Praėjęs mėnuo": [(0, _moment.default)().subtract(1, "month").startOf("month"), (0, _moment.default)().subtract(1, "month").endOf("month")],
      "Praėjęs pusmetis": [(0, _moment.default)().subtract(6, "month").startOf("month"), (0, _moment.default)().subtract(1, "month").endOf("month")]
    },

    didInsertElement() {
      this._super(...arguments);

      const {
        from,
        to,
        alwaysShowCalendars,
        showCustomRangeLabel,
        locale,
        applyButtonClasses,
        buttonClasses,
        cancelButtonClasses,
        ranges,
        linkedCalendars,
        timePicker,
        maxDate
      } = this;
      this.setDateRange(this.from, this.to);
      const daterangepicker = $(document.getElementById("dates")).daterangepicker({
        startDate: from ? (0, _moment.default)(from) : (0, _moment.default)().set({
          hours: 8,
          minutes: 0
        }),
        endDate: to ? (0, _moment.default)(to) : (0, _moment.default)().set({
          hours: 8,
          minutes: 0
        }),
        alwaysShowCalendars,
        showCustomRangeLabel,
        locale,
        applyButtonClasses,
        buttonClasses,
        cancelButtonClasses,
        linkedCalendars,
        ranges,
        timePicker,
        maxDate,
        timePicker24Hour: true
      }, this.onValueUpdated.bind(this));
      $(".drp-buttons").insertBefore($(".ranges"));
      this.toggleBackgroundDimmer(daterangepicker);
    },

    toggleBackgroundDimmer(daterangepicker) {
      const self = this;
      daterangepicker.on("show.daterangepicker", () => !self.isDestroyed && self.set("isDateRangePickerOpened", true));
      daterangepicker.on("hide.daterangepicker", () => !self.isDestroyed && self.set("isDateRangePickerOpened", false));
    },

    onValueUpdated(from, to) {
      this.setDateRange(from, to);
      if (this.onRangeSelected) this.onRangeSelected(from, to);
    },

    setDateRange(from, to) {
      if (from && to) {
        Ember.set(this, "from", from.format ? from.format(this.dateFormat) : from);
        Ember.set(this, "to", to.format ? to.format(this.dateFormat) : to);
      }
    },

    willDestroyElement() {
      document.getElementsByClassName("daterangepicker")[0].remove();

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});