define("ember-components/components/message-box", ["exports", "ember-components/templates/components/message-box"], function (_exports, _messageBox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _messageBox.default,
    tagName: "",
    isVisible: true,
    isClosable: true
  });

  _exports.default = _default;
});