define("ember-components/helpers/repeat-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.repeatString = void 0;

  const repeatString = ([string, times]) => !string || times < 1 ? "" : string.repeat(times);

  _exports.repeatString = repeatString;

  var _default = Ember.Helper.helper(repeatString);

  _exports.default = _default;
});