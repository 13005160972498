define("ember-components/helpers/insert-text-to-textarea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.insertTextToTextarea = insertTextToTextarea;

  const addSpaceIfNeeded = (value, charAtIndex) => value.charAt(charAtIndex) === " " ? "" : " ";

  function ifCursorSelected(selectionStart, insertingText, value) {
    if (selectionStart === 0) return insertingText + addSpaceIfNeeded(value, selectionStart) + value;else if (selectionStart === value.length) return value + addSpaceIfNeeded(value, value.length - 1) + insertingText;else return value.substring(0, selectionStart) + addSpaceIfNeeded(value, selectionStart - 1) + insertingText + addSpaceIfNeeded(value, selectionStart) + value.substring(selectionStart, value.length);
  }

  function ifTextSelected(selectionStart, insertingText, value, selectionEnd) {
    if (selectionStart === 0) return insertingText + addSpaceIfNeeded(value, selectionEnd) + value.slice(selectionEnd);else if (selectionEnd === value.length) return value.slice(0, selectionStart) + addSpaceIfNeeded(value, selectionStart) + insertingText;else return value.slice(0, selectionStart) + addSpaceIfNeeded(value, selectionStart) + insertingText + addSpaceIfNeeded(value, selectionEnd) + value.slice(selectionEnd);
  }

  function insertTextToTextarea(textArea, value, insertingText) {
    if (!textArea || !insertingText) return value;
    if (!value) return insertingText;
    const {
      selectionStart,
      selectionEnd
    } = textArea;
    return selectionStart === selectionEnd ? ifCursorSelected(selectionStart, insertingText, value) : ifTextSelected(selectionStart, insertingText, value, selectionEnd);
  }
});