define("ember-components/templates/components/side-bar/textarea-popup/textarea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iKDbit1s",
    "block": "{\"symbols\":[\"ValidationBlock\",\"HelpBlock\",\"option\",\"@validation\",\"@helpOptions\",\"@title\"],\"statements\":[[4,\"if\",[[23,6,[]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"textarea title\"],[8],[1,[23,6,[]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"textarea\",true],[11,\"value\",[22,\"value\"]],[11,\"onfocus\",[28,\"action\",[[23,0,[]],\"focusIn\"],null]],[10,\"id\",\"popup-textarea\"],[11,\"oninput\",[28,\"action\",[[23,0,[]],\"updateValue\"],[[\"value\"],[\"target.value\"]]]],[10,\"data-test-id\",\"text-area\"],[8],[0,\"\"],[9],[0,\"\\n\\n\\n\"],[4,\"if\",[[24,[\"helpOptions\"]]],null,{\"statements\":[[4,\"let\",[[28,\"component\",[\"side-bar/common/help-block\"],null]],null,{\"statements\":[[0,\"    \"],[6,[23,2,[]],[],[[\"@title\",\"@icon\",\"@expandIcon\",\"@onBlockClick\",\"@collapseIcon\"],[\"Paruoštukai\",\"edit outline grey icon\",\"left\",[28,\"action\",[[23,0,[]],\"focusTextAreaAndScroll\"],null],\"up\"]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,5,[]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"block\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"onBlockClick\",[23,3,[]],[24,[\"value\"]]],null]],[10,\"data-test-id\",\"help-block\"],[8],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"one wide column\"],[8],[7,\"i\",true],[10,\"class\",\"plus small grey icon right aligned\"],[8],[9],[9],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"eleven wide column\"],[8],[1,[23,3,[]],false],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"let\",[[28,\"component\",[\"side-bar/right-popup/validation-block\"],null]],null,{\"statements\":[[0,\"  \"],[6,[23,1,[]],[],[[\"@message\"],[[23,4,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/side-bar/textarea-popup/textarea.hbs"
    }
  });

  _exports.default = _default;
});