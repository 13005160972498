define("ember-components/components/side-bar/multi-select", ["exports", "ember-components/templates/components/side-bar/multi-select", "ember-components/helpers/is-item-equal", "ember-concurrency", "ember-components/mixins/sidebar-switcher"], function (_exports, _multiSelect, _isItemEqual, _emberConcurrency, _sidebarSwitcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_sidebarSwitcher.default, {
    layout: _multiSelect.default,
    classNames: ["multi-select"],
    "data-test-id": "multi-select",
    fadeBackground: true,
    isRightPopupOpen: false,
    itemName: "name",
    customValidationRules: [],
    itemsWithSelectedOption: Ember.computed("items", "selectedItems", function () {
      return this.addIfNotInArray(this.items, this.selectedItems);
    }),

    getUnmatchedItems(arrayContainer, requiredItems) {
      let unmatchedItems = [];

      if (requiredItems) {
        for (let i = 0; i < requiredItems.length; i++) {
          if (!this.isInArray(arrayContainer, requiredItems.objectAt(i))) unmatchedItems.push(requiredItems.objectAt(i));
        }
      }

      return unmatchedItems;
    },

    addIfNotInArray(arr, requiredItems) {
      if (requiredItems) {
        const unmatchedItems = this.getUnmatchedItems(arr, requiredItems);

        if (unmatchedItems) {
          const clone = this.cloneArray(arr);
          unmatchedItems.forEach(i => clone.insertAt(0, i));
          return clone;
        }
      }

      return arr;
    },

    cloneArray: array => Ember.A(array),

    isInArray(items, selectedItem) {
      if (items) {
        for (let i = 0; i < items.length; i++) {
          if ((0, _isItemEqual.isItemEqual)(items[i], selectedItem)) return true;
        }
      }

      return false;
    },

    submit: (0, _emberConcurrency.task)(function* (selectedItems) {
      this.set("errorMessage", undefined);

      try {
        this.set("isRightPopupOpen", false);
        const {
          onSelectedItems
        } = this;
        if (onSelectedItems) yield onSelectedItems(selectedItems);
      } catch (e) {
        this.set("errorMessage", "error has occurred");
      }
    }),
    actions: {
      setValidationObject(object) {
        Ember.set(this, "validation", object);
      }

    }
  });

  _exports.default = _default;
});