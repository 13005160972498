define("ember-components/components/side-bar/numeric-input/integer", ["exports", "ember-components/templates/components/side-bar/numeric-input/integer", "ember-components/mixins/focus-input-field", "ember-components/components/side-bar/numeric-input/abstract"], function (_exports, _integer, _focusInputField, _abstract) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstract.default.extend(_focusInputField.default, {
    layout: _integer.default,
    customValidationRules: [],
    intl: Ember.inject.service(),
    convertTo: value => Number(value),

    validate(value) {
      if (this.countChars(value, ".") > 0 || this.countChars(value, ",") > 0 || isNaN(this.convertTo(value))) return this.intl.t("components.warnings.invalidNumber");
      return this._super(value);
    },

    giveCallbackIfFilled(value) {
      value.length === this.maxLength && this.onMaskFilled && this.onMaskFilled();
    },

    onNumberSelect(value) {
      let numericValue = this.asString(this.inputValue);
      if (numericValue === "0") numericValue = "";
      numericValue += this.asString(value);
      this.giveCallbackIfFilled(numericValue);

      this._super(numericValue);
    },

    actions: {
      onInputWithMaskUpdate(value) {
        this.setValues(value);
      }

    }
  });

  _exports.default = _default;
});