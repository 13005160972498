define('ember-g-map/components/g-map-address-route', ['exports', 'ember-g-map/templates/components/g-map-address-route'], function (exports, _gMapAddressRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const GMapAddressRouteComponent = Ember.Component.extend({
    layout: _gMapAddressRoute.default,
    classNames: ['g-map-address-route'],

    map: Ember.computed.alias('mapContext.map'),

    didInsertElement() {
      this._super(...arguments);
      this.initPlacesService();
    },

    mapWasSet: Ember.observer('map', function () {
      Ember.run.once(this, 'initPlacesService');
    }),

    initPlacesService() {
      const map = this.get('map');
      let service = this.get('placesService');

      if (Ember.isPresent(map) && Ember.isEmpty(service) && typeof FastBoot === 'undefined') {
        service = new google.maps.places.PlacesService(map);
        this.set('placesService', service);
        this.searchLocations();
      }
    },

    onAddressChanged: Ember.observer('originAddress', 'destinationAddress', function () {
      Ember.run.once(this, 'searchLocations');
    }),

    searchLocations() {
      const service = this.get('placesService');
      const originAddress = this.get('originAddress');
      const destinationAddress = this.get('destinationAddress');

      if (Ember.isPresent(service) && Ember.isPresent(originAddress) && typeof FastBoot === 'undefined') {
        const originRequest = { query: originAddress };

        service.textSearch(originRequest, (results, status) => {
          if (google && status === google.maps.places.PlacesServiceStatus.OK) {
            this.updateOriginLocation(results);
          }
        });
      }

      if (Ember.isPresent(service) && Ember.isPresent(destinationAddress) && typeof FastBoot === 'undefined') {
        const destinationRequest = { query: destinationAddress };

        service.textSearch(destinationRequest, (results, status) => {
          if (google && status === google.maps.places.PlacesServiceStatus.OK) {
            this.updateDestinationLocation(results);
          }
        });
      }
    },

    updateOriginLocation(results) {
      if (!this.isDestroyed) {
        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();

        this.set('originLat', lat);
        this.set('originLng', lng);
        this.sendOnLocationsChange(lat, lng, results);
      }
    },

    updateDestinationLocation(results) {
      if (!this.isDestroyed) {
        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();

        this.set('destinationLat', lat);
        this.set('destinationLng', lng);
        this.sendOnLocationsChange(lat, lng, results);
      }
    },

    sendOnLocationsChange() {
      const onLocationsChange = this.get('onLocationsChange');

      if (Ember.typeOf(onLocationsChange) === 'function') {
        onLocationsChange(...arguments);
      } else {
        this.sendAction('onLocationsChange', ...arguments);
      }
    }
  });

  GMapAddressRouteComponent.reopenClass({
    positionalParams: ['mapContext']
  });

  exports.default = GMapAddressRouteComponent;
});