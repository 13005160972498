define("ember-components/templates/components/side-bar/confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "42B3lk2I",
    "block": "{\"symbols\":[\"RightPopup\",\"Popup\",\"@parentClass\",\"@title\",\"@message\",\"@onYes\",\"@onNo\"],\"statements\":[[4,\"let\",[[28,\"component\",[\"side-bar/right-popup\"],null]],null,{\"statements\":[[0,\"  \"],[6,[23,1,[]],[],[[\"@parentClass\",\"@title\",\"@task\",\"@fadeBackground\",\"@isRightPopupOpen\"],[[23,3,[]],[23,4,[]],[22,\"onFadeBackgroundClickTask\"],[22,\"fadeBackground\"],[22,\"isRightPopupOpen\"]]],{\"statements\":[[0,\"\\n    \"],[6,[23,2,[\"header\"]],[],[[],[]]],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"delete-question\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"data-test-id\",\"message\"],[8],[1,[23,5,[]],false],[9],[0,\"\\n      \"],[7,\"div\",true],[8],[0,\"\\n        \"],[7,\"button\",true],[10,\"class\",\"large ui red button\"],[10,\"data-test-id\",\"confirm-delete\"],[11,\"onclick\",[23,6,[]]],[8],[0,\"Taip\"],[9],[0,\"\\n        \"],[7,\"button\",true],[10,\"class\",\"large ui green button\"],[10,\"data-test-id\",\"reject-delete\"],[11,\"onclick\",[23,7,[]]],[8],[0,\"Ne\"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/side-bar/confirm.hbs"
    }
  });

  _exports.default = _default;
});