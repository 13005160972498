define("ember-components/templates/components/side-bar/numeric-input/input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2p98ipLK",
    "block": "{\"symbols\":[\"@title\",\"@inputValue\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"title\"],[10,\"data-test-id\",\"input-title\"],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"],[7,\"input\",true],[10,\"data-test-id\",\"input-value\"],[11,\"value\",[23,2,[]]],[10,\"type\",\"number\"],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/side-bar/numeric-input/input.hbs"
    }
  });

  _exports.default = _default;
});