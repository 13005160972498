define("ember-components/components/side-bar/numeric-input/input", ["exports", "ember-components/templates/components/side-bar/numeric-input/input"], function (_exports, _input) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _input.default,
    "data-test-id": "multiple-input",
    classNames: ["ui input multiple-input"]
  });

  _exports.default = _default;
});