define("ember-components/components/side-bar/textarea-popup", ["exports", "ember-components/templates/components/side-bar/textarea-popup", "ember-concurrency", "ember-components/mixins/sidebar-switcher"], function (_exports, _textareaPopup, _emberConcurrency, _sidebarSwitcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_sidebarSwitcher.default, {
    layout: _textareaPopup.default,
    classNames: ["text-area-popup"],
    isRightPopupOpen: false,
    fadeBackground: true,
    submit: (0, _emberConcurrency.task)(function* (data) {
      yield Ember.set(this, "data", data);
      Ember.set(this, "isRightPopupOpen", false);
    })
  });

  _exports.default = _default;
});