define("ember-components/components/medicaments-list/body/total", ["exports", "ember-components/templates/components/medicaments-list/body/total"], function (_exports, _total) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _total.default,
    tagName: "td",
    ["data-test-id"]: "total-amount",
    classNames: ["right aligned"]
  });

  _exports.default = _default;
});