define("ember-components/components/side-bar/single-select/search-error-block", ["exports", "ember-components/templates/components/side-bar/single-select/search-error-block"], function (_exports, _searchErrorBlock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _searchErrorBlock.default
  });

  _exports.default = _default;
});