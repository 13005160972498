define('ember-g-map/components/g-map-marker', ['exports', 'ember-g-map/templates/components/g-map-marker', 'ember-g-map/components/g-map'], function (exports, _gMapMarker, _gMap) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const GMapMarkerComponent = Ember.Component.extend({
    layout: _gMapMarker.default,
    classNames: ['g-map-marker'],

    map: Ember.computed.alias('mapContext.map'),

    init() {
      this._super(...arguments);
      this.infowindow = null;
      if (Ember.isEmpty(this.get('group'))) {
        this.set('group', null);
      }

      const mapContext = this.get('mapContext');
      (false && !(mapContext instanceof _gMap.default) && Ember.assert('Must be inside {{#g-map}} component with context set', mapContext instanceof _gMap.default));


      mapContext.registerMarker(this);
    },

    didInsertElement() {
      this._super(...arguments);
      if (Ember.isEmpty(this.get('marker')) && typeof FastBoot === 'undefined' && typeof google !== 'undefined') {
        const marker = new google.maps.Marker();
        this.set('marker', marker);
      }
      this.setPosition();
      this.setZIndex();
      this.setIcon();
      this.setDraggable();
      this.setLabel();
      this.setTitle();
      this.setMap();
      this.setOnClick();
      this.setOnDrag();
    },

    willDestroyElement() {
      this.unsetMarkerFromMap();
      this.get('mapContext').unregisterMarker(this);
    },

    registerInfowindow(infowindow, openEvent, closeEvent) {
      this.set('infowindow', infowindow);
      this.attachOpenCloseEvents(infowindow, openEvent, closeEvent);
    },

    unregisterInfowindow() {
      this.set('infowindow', null);
    },

    attachOpenCloseEvents(infowindow, openEvent, closeEvent) {
      const marker = this.get('marker');
      if (openEvent === closeEvent) {
        this.attachTogglingInfowindowEvent(marker, infowindow, openEvent);
      } else {
        this.attachOpenInfowindowEvent(marker, infowindow, openEvent);
        this.attachCloseInfowindowEvent(marker, infowindow, closeEvent);
      }
    },

    attachOpenInfowindowEvent(marker, infowindow, event) {
      if (Ember.isPresent(event)) {
        marker.addListener(event, () => infowindow.open());
      }
    },

    attachCloseInfowindowEvent(marker, infowindow, event) {
      if (Ember.isPresent(event)) {
        marker.addListener(event, () => infowindow.close());
      }
    },

    attachTogglingInfowindowEvent(marker, infowindow, event) {
      if (Ember.isPresent(event)) {
        marker.addListener(event, () => {
          if (infowindow.get('isOpen')) {
            infowindow.close();
          } else {
            infowindow.open();
          }
        });
      }
    },

    unsetMarkerFromMap() {
      const marker = this.get('marker');
      if (Ember.isPresent(marker)) {
        marker.setMap(null);
      }
    },

    mapWasSet: Ember.observer('map', function () {
      Ember.run.once(this, 'setMap');
    }),

    setMap() {
      const map = this.get('map');
      const marker = this.get('marker');

      if (Ember.isPresent(marker) && Ember.isPresent(map)) {
        marker.setMap(map);
      }
    },

    coordsChanged: Ember.observer('lat', 'lng', function () {
      Ember.run.once(this, 'setPosition');
    }),

    setPosition() {
      const marker = this.get('marker');
      const lat = this.get('lat');
      const lng = this.get('lng');

      if (Ember.isPresent(marker) && Ember.isPresent(lat) && Ember.isPresent(lng) && typeof FastBoot === 'undefined') {
        const position = new google.maps.LatLng(lat, lng);
        if (Ember.isPresent(position)) {
          marker.setPosition(position);
        }
      }
    },

    iconChanged: Ember.observer('icon', function () {
      Ember.run.once(this, 'setIcon');
    }),

    setIcon() {
      const marker = this.get('marker');
      const icon = this.get('icon');

      if (Ember.isPresent(marker) && Ember.isPresent(icon)) {
        marker.setIcon(icon);
      }
    },

    zIndexChanged: Ember.observer('zIndex', function () {
      Ember.run.once(this, 'setZIndex');
    }),

    setZIndex() {
      const marker = this.get('marker');
      const zIndex = this.get('zIndex');
      if (Ember.isPresent(marker) && Ember.isPresent(zIndex)) {
        marker.setZIndex(zIndex);
      }
    },

    draggableChanged: Ember.observer('draggable', function () {
      Ember.run.once(this, 'setDraggable');
    }),

    setDraggable() {
      const marker = this.get('marker');
      const draggable = this.get('draggable');
      if (Ember.isPresent(marker) && Ember.isPresent(draggable)) {
        marker.setDraggable(draggable);
      }
    },

    setOnClick() {
      const marker = this.get('marker');
      if (Ember.isPresent(marker)) {
        marker.addListener('click', () => this.sendOnClick());
      }
    },

    setOnDrag() {
      const marker = this.get('marker');
      marker.addListener('dragend', event => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        if (Ember.isPresent(lat) && Ember.isPresent(lng) && Ember.isPresent(marker)) {
          const position = new google.maps.LatLng(lat, lng);
          marker.setPosition(position);
          this.sendOnDrag(lat, lng);
        }
      });
    },

    labelChanged: Ember.observer('label', function () {
      Ember.run.once(this, 'setLabel');
    }),

    setLabel() {
      const marker = this.get('marker');
      const label = this.get('label');

      if (Ember.isPresent(marker) && Ember.isPresent(label)) {
        marker.setLabel(label);
      }
    },

    titleChanged: Ember.observer('title', function () {
      Ember.run.once(this, 'setTitle');
    }),

    setTitle() {
      const marker = this.get('marker');
      const title = this.get('title');

      if (Ember.isPresent(marker) && Ember.isPresent(title)) {
        marker.setTitle(title);
      }
    },

    sendOnClick() {
      const onClick = this.get('onClick');
      const mapContext = this.get('mapContext');
      const group = this.get('group');

      if (Ember.typeOf(onClick) === 'function') {
        onClick();
      } else {
        this.sendAction('onClick');
      }

      if (Ember.isPresent(group)) {
        mapContext.groupMarkerClicked(this, group);
      }
    },

    sendOnDrag(lat, lng) {
      const onDrag = this.get('onDrag');

      if (Ember.typeOf(onDrag) === 'function') {
        onDrag(lat, lng);
      } else {
        this.sendAction('onDrag', lat, lng);
      }
    },

    closeInfowindow() {
      const infowindow = this.get('infowindow');
      if (Ember.isPresent(infowindow)) {
        infowindow.close();
      }
    }
  });

  GMapMarkerComponent.reopenClass({
    positionalParams: ['mapContext']
  });

  exports.default = GMapMarkerComponent;
});