define('ember-metrics/metrics-adapters/google-analytics', ['exports', 'ember-metrics/utils/can-use-dom', 'ember-metrics/utils/object-transforms', 'ember-metrics/utils/remove-from-dom', 'ember-metrics/metrics-adapters/base'], function (exports, _canUseDom, _objectTransforms, _removeFromDom, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  const compact = _objectTransforms.default.compact;
  exports.default = _base.default.extend({
    toStringExtension() {
      return 'GoogleAnalytics';
    },

    init() {
      const config = Ember.assign({}, Ember.get(this, 'config'));
      const id = config.id,
            sendHitTask = config.sendHitTask,
            trace = config.trace,
            require = config.require;
      let debug = config.debug;
      (false && !(id) && Ember.assert(`[ember-metrics] You must pass a valid \`id\` to the ${this.toString()} adapter`, id));


      delete config.id;
      delete config.require;

      if (debug) {
        delete config.debug;
      }
      if (sendHitTask) {
        delete config.sendHitTask;
      }
      if (trace) {
        delete config.trace;
      }

      const hasOptions = Ember.isPresent(Object.keys(config));

      if (_canUseDom.default) {

        /* eslint-disable */
        (function (i, s, o, g, r, a, m) {
          i['GoogleAnalyticsObject'] = r;i[r] = i[r] || function () {
            (i[r].q = i[r].q || []).push(arguments);
          }, i[r].l = 1 * new Date();a = s.createElement(o), m = s.getElementsByTagName(o)[0];a.async = 1;a.src = g;m.parentNode.insertBefore(a, m);
        })(window, document, 'script', `https://www.google-analytics.com/analytics${debug ? '_debug' : ''}.js`, 'ga');
        /* eslint-enable */

        if (trace === true) {
          window.ga_debug = { trace: true };
        }

        if (hasOptions) {
          window.ga('create', id, config);
        } else {
          window.ga('create', id, 'auto');
        }

        if (require) {
          require.forEach(plugin => {
            window.ga('require', plugin);
          });
        }

        if (sendHitTask === false) {
          window.ga('set', 'sendHitTask', null);
        }
      }
    },

    identify(options = {}) {
      const compactedOptions = compact(options);
      const distinctId = compactedOptions.distinctId;


      if (_canUseDom.default) {
        window.ga('set', 'userId', distinctId);
      }
    },

    trackEvent(options = {}) {
      const compactedOptions = compact(options);
      const sendEvent = { hitType: 'event' };
      const eventKeys = ['category', 'action', 'label', 'value'];
      let gaEvent = {};

      if (compactedOptions.nonInteraction) {
        gaEvent.nonInteraction = compactedOptions.nonInteraction;
        delete compactedOptions.nonInteraction;
      }

      for (let key in compactedOptions) {
        if (eventKeys.includes(key)) {
          const capitalizedKey = Ember.String.capitalize(key);
          gaEvent[`event${capitalizedKey}`] = compactedOptions[key];
        } else {
          gaEvent[key] = compactedOptions[key];
        }
      }

      const event = Ember.assign(sendEvent, gaEvent);
      if (_canUseDom.default) {
        window.ga('send', event);
      }

      return event;
    },

    trackPage(options = {}) {
      const compactedOptions = compact(options);
      const sendEvent = { hitType: 'pageview' };
      const event = Ember.assign(sendEvent, compactedOptions);

      if (_canUseDom.default) {
        for (let key in compactedOptions) {
          if (compactedOptions.hasOwnProperty(key)) {
            window.ga('set', key, compactedOptions[key]);
          }
        }
        window.ga('send', sendEvent);
      }

      return event;
    },

    willDestroy() {
      if (!_canUseDom.default) {
        return;
      }
      (0, _removeFromDom.default)('script[src*="google-analytics"]');

      delete window.ga;
    }
  });
});