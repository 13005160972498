define("ember-components/components/side-bar/single-select/not-selected-item", ["exports", "ember-components/templates/components/side-bar/single-select/not-selected-item"], function (_exports, _notSelectedItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _notSelectedItem.default,
    "data-test-id": "selected-block",
    classNames: ["selection-remove item"],
    actions: {
      clearSelectedValue() {
        this.set("selectedItem", null);
        if (this.onClearValue) this.onClearValue();
      }

    }
  });

  _exports.default = _default;
});