define("ember-components/components/side-bar/multi-select/favourite", ["exports", "ember-components/templates/components/side-bar/multi-select/favourite"], function (_exports, _favourite) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _favourite.default,
    classNames: ["favourite-block"],
    classNameBindings: ["validation.type", "isRightPopupOpen:focused"],
    "data-test-id": "favourite-block",
    actions: {
      onSelectedItem(selected) {
        Ember.set(this, "selectedItems", selected.value);
      }

    }
  });

  _exports.default = _default;
});