define("ember-components/components/side-bar/multi-input", ["exports", "ember-components/templates/components/side-bar/multi-input", "ember-components/mixins/sidebar-switcher", "ember-components/mixins/validation-class-mixin"], function (_exports, _multiInput, _sidebarSwitcher, _validationClassMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_sidebarSwitcher.default, _validationClassMixin.default, {
    layout: _multiInput.default,
    "data-test-id": "multi-input",
    classNames: ["numeric-input multi-input"]
  });

  _exports.default = _default;
});