define("ember-components/helpers/filter-to-string", ["exports", "ember-components/mixins/helper-observer", "ember-components/helpers/date-interval-to-string"], function (_exports, _helperObserver, _dateIntervalToString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend(_helperObserver.default, {
    compute([filter]) {
      const {
        selectedValue,
        key,
        type,
        selectedTitle,
        customRepresentation
      } = filter;
      if (!selectedValue || !type) return "";
      this.setupRecompute(filter, "selectedValue", "selectedValue.length", "selectedValue.fromDate", "selectedValue.toDate");

      if (customRepresentation) {
        const result = customRepresentation();
        if (result) return result;
      }

      if (type === "single-select" || type === "single-radiobox") return selectedValue[key];
      if (type === "multi-select" || type === "checkbox-group") return selectedValue.map(element => element[key]).join(", ");

      if (type === "date-range-picker") {
        const {
          fromDate,
          toDate
        } = selectedValue;
        return (0, _dateIntervalToString.getFormattedDateInterval)([fromDate, toDate]);
      }

      if (type === "boolean") return selectedTitle;
      return "";
    }

  });

  _exports.default = _default;
});