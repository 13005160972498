define("ember-components/helpers/medicament-per-event", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.medicamentPerEvent = medicamentPerEvent;
  _exports.default = void 0;

  function medicamentPerEvent([events, total]) {
    return events && total ? (total / events).toFixed(2) : "";
  }

  var _default = Ember.Helper.helper(medicamentPerEvent);

  _exports.default = _default;
});