define("ember-components/components/click-outside-disabler", ["exports", "ember-components/templates/components/click-outside-disabler"], function (_exports, _clickOutsideDisabler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _clickOutsideDisabler.default,
    tagName: ""
  });

  _exports.default = _default;
});