define("ember-components/templates/components/medicaments-list/medicament-events/body/td", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7QcxOuiw",
    "block": "{\"symbols\":[\"&default\",\"@value\"],\"statements\":[[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"  \"],[1,[23,2,[]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/medicaments-list/medicament-events/body/td.hbs"
    }
  });

  _exports.default = _default;
});