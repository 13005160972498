define("ember-components/mixins/keyboard-actions", ["exports", "ember-keyboard", "ember-components/helpers/switch-sidebar"], function (_exports, _emberKeyboard, _switchSidebar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_emberKeyboard.EKMixin, _emberKeyboard.EKOnInitMixin, {
    activateKeyboard: Ember.on("init", function () {
      Ember.set(this, "keyboardActivated", true);
    }),

    closeLastSidebar() {
      const submitButtons = document.getElementsByClassName("submit-button");
      submitButtons.length && submitButtons[submitButtons.length - 1].click();
    },

    closeOnEscape: Ember.on((0, _emberKeyboard.keyUp)("Escape"), (0, _emberKeyboard.keyUp)("Enter"), (0, _emberKeyboard.keyUp)("NumpadEnter"), function (event) {
      if (event.key === "Escape" || this.isActiveElementNotInputType()) {
        this.onClose ? this.onClose() : this.closeLastSidebar();
        event.stopPropagation();
      }
    }),
    moveThroughField: Ember.on((0, _emberKeyboard.keyUp)("PageUp"), (0, _emberKeyboard.keyUp)("PageDown"), function ({
      code
    }) {
      (0, _switchSidebar.switchSidebar)([code, this.element]);
    }),

    isActiveElementNotInputType() {
      if (document.activeElement) {
        const activeElementTagName = document.activeElement.tagName;
        return activeElementTagName !== "INPUT" && activeElementTagName !== "TEXTAREA";
      }

      return true;
    }

  });

  _exports.default = _default;
});