define("ember-components/templates/components/side-bar/selected-item-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "enJ6bmUk",
    "block": "{\"symbols\":[\"@selectedItem\",\"&default\"],\"statements\":[[4,\"if\",[[28,\"and\",[[23,1,[]],[24,[\"canClearValue\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"i\",true],[10,\"data-test-id\",\"remove-button\"],[10,\"class\",\"times circle outline icon remove-icon\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"clearSelectedValue\"],null]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[14,2]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/side-bar/selected-item-block.hbs"
    }
  });

  _exports.default = _default;
});