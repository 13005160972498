define("ember-components/components/side-bar/numeric-input/masked", ["exports", "ember-components/components/side-bar/numeric-input/float", "ember-components/helpers/repeat-string"], function (_exports, _float, _repeatString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NUMBER = "9";
  const DOT = ".";
  const ZERO = "0";
  const UNDERSCORE = "_";

  var _default = _float.default.extend({
    isMaskedInput: true,
    type: "float",
    maxLength: 5,
    fadeBackground: true,
    placeholder: Ember.computed(function () {
      return `${(0, _repeatString.repeatString)([UNDERSCORE, this.maxLength])}${DOT}${UNDERSCORE}`;
    }),
    mask: Ember.computed("maxLength", function () {
      return (0, _repeatString.repeatString)([NUMBER, this.maxLength]) + DOT + NUMBER;
    }),

    insertFloatToPlaceholder(float) {
      const splittedFloat = float.split(".");

      if (splittedFloat[0].length === this.maxLength) {
        return float;
      } else {
        const zerosAtFrontCount = this.maxLength - splittedFloat[0].length;
        return (0, _repeatString.repeatString)([ZERO, zerosAtFrontCount]) + splittedFloat[0] + DOT + splittedFloat[1];
      }
    },

    convertIntToInputValue(int) {
      if (int.length === this.maxLength) return int + DOT + ZERO;
      const zerosAtFrontCount = this.maxLength - int.length;
      return (0, _repeatString.repeatString)([ZERO, zerosAtFrontCount]) + int + DOT + ZERO;
    },

    insertIntToPlaceholder(int) {
      let result = this.placeholder;
      int.split("").forEach(number => {
        result = result.replace(UNDERSCORE, number);
      });
      return result;
    },

    inputValue: Ember.computed("value", "isRightPopupOpen", {
      set(key, value) {
        return this.setInputValue(value);
      },

      get() {
        const {
          value
        } = this;
        if (Ember.isNone(value)) return this.placeholder;
        const input = this.asString(value);
        return input.includes(DOT) ? this.insertFloatToPlaceholder(input) : this.convertIntToInputValue(input);
      }

    }),

    setValues(value) {
      Ember.set(this, "value", value);
    },

    setValueAndInputValue(value, inputValue) {
      Ember.set(this, "value", value);
      Ember.set(this, "inputValue", inputValue);
    },

    giveCallbackIfFilled() {
      if (this.inputValue.includes(DOT) && !this.inputValue.includes(UNDERSCORE)) this.onMaskFilled && this.onMaskFilled();
    },

    onNumberSelect(value) {
      const inputMask = this.inputValue.replace(UNDERSCORE, value);
      Ember.isNone(this.value) ? this.setValueAndInputValue(parseFloat(value), this.placeholder.replace(UNDERSCORE, value)) : this.setValueAndInputValue(parseFloat(inputMask), inputMask);
      this.giveCallbackIfFilled();
    },

    handleInputAfterDotPressed() {
      const [valueUntilDot] = this.inputValue.split(".");

      if (valueUntilDot.includes("_")) {
        const indexOfUnderscore = valueUntilDot.indexOf(UNDERSCORE);
        const valueUntilUnderscore = valueUntilDot.substring(0, indexOfUnderscore);
        const zerosAtFrontCount = this.maxLength - valueUntilUnderscore.length;
        const inputMask = (0, _repeatString.repeatString)([ZERO, zerosAtFrontCount]) + valueUntilUnderscore + DOT + UNDERSCORE;
        Ember.set(this, "inputValue", inputMask);
      }
    },

    onDotSelect() {
      Ember.isNone(this.value) ? this.setValueAndInputValue(0, (0, _repeatString.repeatString)([ZERO, this.maxLength]) + DOT + UNDERSCORE) : this.handleInputAfterDotPressed();
    },

    replaceStringAt(string, index, replacement) {
      return string.substr(0, index) + replacement + string.substr(index + replacement.length);
    },

    onNumberDelete() {
      let numericValue = this.asString(this.inputValue);

      if (numericValue) {
        let indexOfLastNumber = null;
        numericValue.split("").reverse().forEach((char, i) => {
          if (Ember.isNone(indexOfLastNumber) && !isNaN(char)) indexOfLastNumber = i;
        });
        indexOfLastNumber = numericValue.length - indexOfLastNumber - 1;
        numericValue = this.replaceStringAt(numericValue, indexOfLastNumber, UNDERSCORE);
        this.setValueAndInputValue(numericValue === this.placeholder ? null : parseFloat(numericValue), numericValue);
      }
    },

    actions: {
      onInputMaskKeyPressed(event) {
        event.key === DOT && this.handleInputAfterDotPressed();
      },

      onInputMaskUpdate(value, masked) {
        this.setValueAndInputValue(masked ? parseFloat(masked) : null, masked);
        this.giveCallbackIfFilled();
      },

      onNumberSelect(value) {
        value === DOT ? this.onDotSelect() : this.onNumberSelect(value);
      },

      onNumberDelete() {
        this.onNumberDelete();
      },

      onFavouriteSelect(favouriteValue) {
        const input = this.asString(favouriteValue);
        const maskedValue = input.includes(DOT) ? this.insertFloatToPlaceholder(input) : this.insertIntToPlaceholder(input);
        this.setValueAndInputValue(favouriteValue, maskedValue);
      }

    }
  });

  _exports.default = _default;
});