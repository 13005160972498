define("ember-components/templates/components/filter-composer/representation/template-wrapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0BqCDN00",
    "block": "{\"symbols\":[\"@title\",\"@value\"],\"statements\":[[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"ui basic label\"],[10,\"data-test-id\",\"filter-label\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"filter-wrapper\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"filter-title\"],[10,\"data-test-id\",\"title\"],[8],[1,[23,1,[]],false],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"filter-value\"],[10,\"data-test-id\",\"value\"],[8],[1,[23,2,[]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/filter-composer/representation/template-wrapper.hbs"
    }
  });

  _exports.default = _default;
});