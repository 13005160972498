define("ember-components/templates/components/signature-pad/input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5Gg1YKEF",
    "block": "{\"symbols\":[\"@data\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"sign-canvas\"],[8],[0,\"\\n\"],[4,\"unless\",[[23,1,[]]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[10,\"class\",\"huge pencil alternate icon\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"canvas\",true],[11,\"width\",[22,\"width\"]],[11,\"height\",[22,\"height\"]],[10,\"data-test-id\",\"signature-canvas\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/signature-pad/input.hbs"
    }
  });

  _exports.default = _default;
});