define("ember-components/templates/components/side-bar/single-select/input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jAcp76dj",
    "block": "{\"symbols\":[\"item\",\"NotSelectedItem\",\"@comparatorKey\",\"@selected\",\"&default\",\"@required\",\"@onClearValue\"],\"statements\":[[4,\"if\",[[24,[\"showItemDeleteBlock\"]]],null,{\"statements\":[[4,\"let\",[[28,\"component\",[\"side-bar/single-select/not-selected-item\"],null]],null,{\"statements\":[[0,\"    \"],[6,[23,2,[]],[],[[\"@selectedItem\",\"@required\",\"@onClearValue\"],[[23,4,[]],[23,6,[]],[23,7,[]]]]],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"each\",[[24,[\"options\"]]],null,{\"statements\":[[0,\"  \"],[14,5,[[28,\"hash\",null,[[\"icon\",\"model\",\"checked\"],[[28,\"component\",[\"side-bar/single-select/icon\"],null],[23,1,[]],[28,\"is-item-equal\",[[23,1,[]],[23,4,[]],[23,3,[]]],null]]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/side-bar/single-select/input.hbs"
    }
  });

  _exports.default = _default;
});