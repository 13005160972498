define("ember-components/helpers/is-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.isNumber = void 0;

  const isNumber = number => Ember.isPresent(number) && !isNaN(number);

  _exports.isNumber = isNumber;

  var _default = Ember.Helper.helper(isNumber);

  _exports.default = _default;
});