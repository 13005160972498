define("ember-components/helpers/switch-sidebar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.switchSidebar = switchSidebar;

  function switchSidebar([keyCode, element]) {
    if ((keyCode === "PageUp" || keyCode === "PageDown") && element) {
      const direction = keyCode === "PageUp" ? "left" : "right";
      const nextElement = element.querySelector(`.footer .${direction}`);
      nextElement && nextElement.click();
    }
  }
});