define("ember-components/templates/components/side-bar/single-input/input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aucjBuQX",
    "block": "{\"symbols\":[\"@unit\",\"@data\",\"&default\",\"@readOnly\"],\"statements\":[[7,\"div\",true],[10,\"data-test-id\",\"single-input-header\"],[10,\"class\",\"ui input-header title\"],[11,\"onclick\",[28,\"if\",[[23,4,[]],[28,\"action\",[[23,0,[]],\"onclick\"],null]],null]],[8],[0,\"\\n  \"],[1,[22,\"title\"],false],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[23,4,[]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"input\"],[10,\"data-test-id\",\"input-field-value\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"onclick\"],null]],[8],[0,\"\\n    \"],[1,[23,2,[]],false],[0,\"\\n\"],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[10,\"class\",\"unit\"],[8],[0,\" \"],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[14,3,[[28,\"hash\",null,[[\"comment\"],[[28,\"component\",[\"side-bar/single-input/comment\"],null]]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"input\",true],[10,\"class\",\"input editable input-view\"],[10,\"data-test-id\",\"input-field-value\"],[11,\"onfocus\",[28,\"action\",[[23,0,[]],\"onFocusIn\"],null]],[11,\"onblur\",[28,\"action\",[[23,0,[]],\"onFocusOut\"],null]],[10,\"autocomplete\",\"off\"],[11,\"oninput\",[28,\"action\",[[23,0,[]],\"onInputChange\"],[[\"value\"],[\"target.value\"]]]],[11,\"value\",[22,\"formattedData\"]],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/side-bar/single-input/input.hbs"
    }
  });

  _exports.default = _default;
});