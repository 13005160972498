define("ember-components/templates/components/side-bar/single-input/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XQSsUzYt",
    "block": "{\"symbols\":[\"item\",\"@key\"],\"statements\":[[5,\"ui-loader\",[],[[\"@isLoading\"],[[24,[\"onSearch\",\"last\",\"isRunning\"]]]]],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"onSearch\",\"last\",\"error\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"list error\"],[10,\"data-test-id\",\"search-error\"],[8],[0,\"\\n    \"],[1,[28,\"translate-error\",[[24,[\"onSearch\",\"last\",\"error\"]]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"onSearch\",\"lastSuccessful\",\"value\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"list\"],[8],[0,\"\\n    \"],[7,\"ui\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"onSearch\",\"lastSuccessful\",\"value\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"item\"],[10,\"data-test-id\",\"list-option\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"onSelectValue\",[23,1,[]]],null]],[8],[0,\"\\n\"],[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"            \"],[1,[28,\"get\",[[23,1,[]],[23,2,[]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[23,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/side-bar/single-input/search.hbs"
    }
  });

  _exports.default = _default;
});