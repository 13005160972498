define('ember-g-map/components/g-map-address-marker', ['exports', 'ember-g-map/templates/components/g-map-address-marker'], function (exports, _gMapAddressMarker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const GMapAddressMarkerComponent = Ember.Component.extend({
    layout: _gMapAddressMarker.default,
    classNames: ['g-map-address-marker'],

    map: Ember.computed.alias('mapContext.map'),

    didInsertElement() {
      this._super(...arguments);
      this.initPlacesService();
    },

    mapWasSet: Ember.observer('map', function () {
      Ember.run.once(this, 'initPlacesService');
    }),

    initPlacesService() {
      const map = this.get('map');
      let service = this.get('placesService');

      if (Ember.isPresent(map) && Ember.isEmpty(service) && typeof FastBoot === 'undefined') {
        service = new google.maps.places.PlacesService(map);
        this.set('placesService', service);
        this.searchLocation();
      }
    },

    onAddressChanged: Ember.observer('address', function () {
      Ember.run.once(this, 'searchLocation');
    }),

    searchLocation() {
      const service = this.get('placesService');
      const address = this.get('address');

      if (Ember.isPresent(service) && Ember.isPresent(address) && typeof FastBoot === 'undefined') {
        const request = { query: address };

        service.textSearch(request, (results, status) => {
          if (google && status === google.maps.places.PlacesServiceStatus.OK) {
            this.updateLocation(results);
          }
        });
      }
    },

    updateLocation(results) {
      if (!this.destroyed) {
        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();
        const viewport = results[0].geometry.viewport;


        this.set('lat', lat);
        this.set('lng', lng);
        this.set('viewport', viewport);
        this.sendOnLocationChange(lat, lng, results);
      }
    },

    sendOnLocationChange() {
      const onLocationChange = this.get('onLocationChange');

      if (Ember.typeOf(onLocationChange) === 'function') {
        onLocationChange(...arguments);
      } else {
        this.sendAction('onLocationChange', ...arguments);
      }
    }
  });

  GMapAddressMarkerComponent.reopenClass({
    positionalParams: ['mapContext']
  });

  exports.default = GMapAddressMarkerComponent;
});