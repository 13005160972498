define("ember-components/components/side-bar/single-select/popup", ["exports", "ember-components/templates/components/side-bar/single-select/popup", "ember-concurrency", "ember-get-config", "ember-components/mixins/sidebar-switcher"], function (_exports, _popup, _emberConcurrency, _emberGetConfig, _sidebarSwitcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_sidebarSwitcher.default, {
    layout: _popup.default,
    errorTranslation: Ember.inject.service(),
    isRightPopupOpen: false,
    searchLimit: 200,
    defaultMinQueryLength: 3,
    debounceTimeMS: 800,
    items: null,
    defaultKey: _emberGetConfig.default.APP.OBJECT_COMPARATOR_KEY,
    isSearchable: Ember.computed("source", {
      get() {
        return !!this.source;
      },

      set: (key, value) => value
    }),
    queryLength: Ember.computed("query", "minQueryLength", function () {
      const {
        minQueryLength,
        defaultMinQueryLength,
        query
      } = this;
      const result = typeof minQueryLength === "function" ? minQueryLength(query) : minQueryLength;
      return result || defaultMinQueryLength;
    }),
    otherOptionsErrorMessage: Ember.computed("otherOptions.{isError,isFinished}", function () {
      const message = Ember.get(this, "otherOptions.error");

      if (Ember.get(this, "otherOptions.value")) {
        try {
          this.validateQueryResults(this.otherOptions.value);
        } catch (e) {
          return e;
        }
      }

      return message;
    }),
    errorMessage: Ember.computed("selectedItem", "isSearchable", "otherOptions.isError", {
      get() {
        return this.isSearchable && Ember.get(this, "otherOptions.error") || this.selectedItem ? null : "EMPTY_QUERY";
      },

      set: (key, value) => value
    }),
    showItemsList: Ember.computed("errorMessage", "onSearch.isRunning", function () {
      const {
        errorMessage,
        onSearch: {
          isRunning
        }
      } = this;
      return !(isRunning || errorMessage) || !isRunning && errorMessage === "EMPTY_QUERY";
    }),

    setError(error) {
      this.onQueryError && this.onQueryError(this, error);
      Ember.set(this, "errorMessage", error);
    },

    resetErrorsAndNotSelectedItems() {
      const {
        selectedItem
      } = this;
      this.setError("");
      Ember.set(this, "items", selectedItem ? [selectedItem] : null);
    },

    validateQueryResults(items) {
      if (!items) throw "NO_RESULTS";
      const itemsLength = items.content ? items.content.length : items.length;
      if (itemsLength === 0) throw "NO_RESULTS";else if (itemsLength > this.searchLimit) throw "TOO_MANY_RESULTS";
    },

    validateQuery(query) {
      if (Ember.isBlank(query) && !this.selectedItem) throw "EMPTY_QUERY";
      if (query && !this.isQueryMinimumLength(query)) throw "QUERY_IS_TOO_SHORT";
    },

    isQueryMinimumLength(query) {
      return query.trim().length >= this.queryLength;
    },

    onSearch: (0, _emberConcurrency.task)(function* (query) {
      try {
        Ember.set(this, "query", query);
        this.resetErrorsAndNotSelectedItems();
        this.validateQuery(query);

        if (query && this.isQueryMinimumLength(query)) {
          yield (0, _emberConcurrency.timeout)(this.debounceTimeMS);
          yield this.source(query, this.searchLimit).then(items => {
            this.validateQueryResults(items);
            Ember.set(this, "items", items);
          });
        }
      } catch (exception) {
        this.setError(exception);
      }
    }).restartable(),
    otherOptions: Ember.computed("otherOptionsSource", function () {
      const {
        otherOptionsSource
      } = this;
      return otherOptionsSource && otherOptionsSource();
    }),
    submit: (0, _emberConcurrency.task)(function* (item) {
      if (item) {
        Ember.set(this, "selectedItem", item);
        if (typeof this.onSelectedItem === "function") yield this.onSelectedItem(item);
      }

      this.closeOnSelect && Ember.set(this, "isRightPopupOpen", false);
    }),
    onRightPopupClose: (0, _emberConcurrency.task)(function* () {
      yield Ember.set(this, "isRightPopupOpen", false);
    }),
    actions: {
      onClearValue() {
        this.closeOnSelect && Ember.set(this, "isRightPopupOpen", false);
        if (this.onClearValue) this.onClearValue();
      },

      clearQueryInput() {
        const [input] = this.element.getElementsByTagName("input");
        input.value = "";
        input.focus();
        this.onSearch.perform("");
      }

    }
  });

  _exports.default = _default;
});