define("ember-components/components/ui-loader", ["exports", "ember-components/templates/components/ui-loader"], function (_exports, _uiLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _uiLoader.default,
    tagName: ""
  });

  _exports.default = _default;
});