define("ember-components/templates/components/medicaments-list/medicament-events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zoocXAsr",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"table\",true],[10,\"class\",\"ui very basic unstackable table medicament-events-table\"],[10,\"data-test-id\",\"medicament-events-table\"],[8],[0,\"\\n  \"],[14,1,[[28,\"hash\",null,[[\"head\",\"body\"],[[28,\"component\",[\"medicaments-list/medicament-events/head\"],null],[28,\"component\",[\"medicaments-list/medicament-events/body\"],null]]]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/medicaments-list/medicament-events.hbs"
    }
  });

  _exports.default = _default;
});