define("ember-components/templates/components/side-bar/single-select/icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6LcgFIT+",
    "block": "{\"symbols\":[\"@dataTestId\",\"&default\"],\"statements\":[[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"  \"],[14,2],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"i\",true],[11,\"data-test-id\",[28,\"or\",[[23,1,[]],\"icon\"],null]],[11,\"class\",[22,\"icon\"]],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/side-bar/single-select/icon.hbs"
    }
  });

  _exports.default = _default;
});