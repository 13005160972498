define("ember-components/templates/components/side-bar/multi-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UyzWrlRI",
    "block": "{\"symbols\":[\"SingleInput\",\"MultInputPopup\",\"Ui\",\"Ui\",\"@parsedInputs\",\"@validation\",\"@title\",\"&default\"],\"statements\":[[4,\"let\",[[28,\"component\",[\"side-bar/single-input\"],null],[28,\"component\",[\"side-bar/multi-input/popup\"],null]],null,{\"statements\":[[0,\"\\n  \"],[6,[23,1,[]],[[12,\"class\",\"input\"],[12,\"onclick\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"isRightPopupOpen\"]]],null],true],null]]],[[\"@title\",\"@readOnly\",\"@validation\",\"@isInputFocused\"],[[23,7,[]],true,[23,6,[]],[22,\"isRightPopupOpen\"]]],{\"statements\":[[0,\"\\n\\n    \"],[14,8,[[28,\"hash\",null,[[\"template\"],[[23,4,[\"template\"]]]]]]],[0,\"\\n\\n    \"],[6,[23,4,[\"icon\"]],[],[[\"@readOnly\"],[false]]],[0,\"\\n  \"]],\"parameters\":[4]}],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"isRightPopupOpen\"]]],null,{\"statements\":[[0,\"    \"],[6,[23,2,[]],[],[[\"@isRightPopupOpen\",\"@sidebarSwitcher\",\"@fadeBackground\",\"@parsedInputs\",\"@validation\",\"@title\"],[[22,\"isRightPopupOpen\"],[22,\"sidebarSwitcher\"],true,[23,5,[]],[23,6,[]],[23,7,[]]]],{\"statements\":[[0,\"\\n      \"],[14,8,[[28,\"hash\",null,[[\"input\",\"help-block\"],[[23,3,[\"input\"]],[23,3,[\"help-block\"]]]]]]],[0,\"\\n    \"]],\"parameters\":[3]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/side-bar/multi-input.hbs"
    }
  });

  _exports.default = _default;
});