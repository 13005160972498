define("ember-components/components/signature-pad/input", ["exports", "ember-components/templates/components/signature-pad/input"], function (_exports, _input) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _input.default,
    device: Ember.inject.service(),
    "data-test-id": "signature-pad-input",
    classNames: ["signature-pad"],
    readOnly: false,
    canvas: null,
    signatureDotSize: 1.5,
    minSignatureLineWidth: .5,
    maxSignatureLineWidth: 5,
    height: Ember.computed("width", function () {
      return this.width * 0.7;
    }),
    width: Ember.computed("device.isDesktop", {
      get() {
        return this.element.parentElement.offsetWidth - 50;
      },

      set: (key, value) => value
    }),

    init() {
      this._super(...arguments);

      const {
        parent
      } = this;
      if (parent) parent.onSignaturePadComponentCreated(this);
    },

    resizeCanvas(size = 1) {
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      this.canvas.getContext("2d").scale(size * ratio, size * ratio);
    },

    createSignaturePad() {
      const [canvas] = this.element.querySelectorAll("canvas");
      this.set("canvas", canvas);
      return new SignaturePad(canvas);
    },

    customScaledImageFromDataUrlFunction() {
      return function (dataUrl) {
        const getImageValuesFromOrientation = (imageHeight, imageWidth, canvasWidth, canvasHeight) => {
          const orientationOfImage = imageWidth - imageHeight;
          const isVertical = orientationOfImage > 0;

          if (isVertical) {
            const height = canvasWidth * imageHeight / imageWidth;
            const width = canvasWidth;
            const offsetX = 0;
            const offsetY = (canvasHeight - height) / 2;
            return {
              height,
              width,
              offsetX,
              offsetY
            };
          } else {
            const height = imageHeight;
            const width = canvasHeight * imageWidth / imageHeight;
            const offsetX = (canvasWidth - width) / 2;
            const offsetY = 0;
            return {
              height,
              width,
              offsetX,
              offsetY
            };
          }
        };

        const drawImageThatIsBiggerThanCanvas = (image, imageHeight, imageWidth, canvasWidth, canvasHeight) => {
          const {
            height,
            width,
            offsetX,
            offsetY
          } = getImageValuesFromOrientation.call(this, imageHeight, imageWidth, canvasWidth, canvasHeight);

          this._ctx.drawImage(image, offsetX, offsetY, width, height);
        };

        const self = this;
        const image = new Image();
        const ratio = window.devicePixelRatio || 1;
        const canvasWidth = this._canvas.width / ratio;
        const canvasHeight = this._canvas.height / ratio;

        this._reset();

        image.onload = () => {
          const imageWidth = image.width / ratio;
          const imageHeight = image.height / ratio;
          if (canvasWidth < imageWidth || canvasHeight < imageHeight) drawImageThatIsBiggerThanCanvas.call(self, image, imageHeight, imageWidth, canvasWidth, canvasHeight);else self._ctx.drawImage(image, (canvasWidth - imageWidth) / 2, (canvasHeight - imageHeight) / 2, imageWidth, imageHeight);
        };

        image.src = dataUrl;
        this._isEmpty = false;
      };
    },

    didInsertElement() {
      const signaturePad = this.createSignaturePad();
      signaturePad.dotSize = this.signatureDotSize;
      signaturePad.minWidth = this.minSignatureLineWidth;
      signaturePad.maxWidth = this.maxSignatureLineWidth;
      if (!signaturePad.drawScaledImageFromDataUrl) signaturePad.drawScaledImageFromDataUrl = this.customScaledImageFromDataUrlFunction();
      this.set("signaturePad", signaturePad);
      const {
        data
      } = this;

      if (data) {
        signaturePad.drawScaledImageFromDataUrl(data);
        this.resizeCanvas();
        signaturePad.off();
      } else {
        signaturePad.on();
      }

      this._super(...arguments);
    },

    willDestroyElement() {
      this.set("signaturePad", null);
    },

    isCanvasBlank(canvas) {
      if (canvas) {
        const blank = document.createElement("canvas");
        blank.width = canvas.width;
        blank.height = canvas.height;
        return canvas.toDataURL() === blank.toDataURL();
      }

      return false;
    },

    getSignature() {
      const {
        signaturePad,
        onSignatureSubmit
      } = this;
      const isSignatureEmpty = this.isCanvasBlank(this.canvas);
      this.resizeCanvas(-1);
      this.set("data", isSignatureEmpty ? null : signaturePad.toDataURL());
      signaturePad.off();
      onSignatureSubmit && onSignatureSubmit(this.data);
      return this.onCloseModal();
    },

    onClearCanvas() {
      this.set("data", null);
      const {
        signaturePad
      } = this;
      signaturePad.clear();
      signaturePad.on();
    }

  });

  _exports.default = _default;
});