define("ember-components/components/medicaments-list/body/per-event", ["exports", "ember-components/templates/components/medicaments-list/body/per-event"], function (_exports, _perEvent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _perEvent.default,
    tagName: "td",
    ["data-test-id"]: "medicament-per-event",
    classNames: ["right aligned"]
  });

  _exports.default = _default;
});