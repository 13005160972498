define("ember-components/mixins/helper-observer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    destroy() {
      if (this.teardown) this.teardown();

      this._super(...arguments);
    },

    setupRecompute(object, ...paths) {
      if (this.teardown) this.teardown();
      paths.forEach(path => {
        object.addObserver && object.addObserver(path, this, this.recompute);

        this.teardown = () => {
          object.removeObserver && object.removeObserver(path, this, this.recompute);
        };
      });
    }

  });

  _exports.default = _default;
});