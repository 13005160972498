define("ember-components/mixins/modal-counter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    componentsStatus: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments);

      this.isPopupOpen() ? this.increaseModalCount() : this.decreaseModalCount();
    },

    willDestroyElement() {
      this.decreaseModalCount();

      this._super(...arguments);
    },

    decreaseModalCount() {
      const {
        modalCount
      } = this.componentsStatus;
      if (modalCount !== 0) Ember.set(this.componentsStatus, "modalCount", modalCount - 1);
    },

    increaseModalCount() {
      const {
        modalCount
      } = this.componentsStatus;
      Ember.set(this.componentsStatus, "modalCount", modalCount + 1);
    },

    isPopupOpen() {
      const isCenteredPopup = this.className && this.className.includes("centered");
      return !isCenteredPopup && this.isRightPopupOpen || this.centerModalOpen;
    }

  });

  _exports.default = _default;
});