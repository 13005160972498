define("ember-components/components/side-bar/block-input/option", ["exports", "ember-components/templates/components/side-bar/block-input/option"], function (_exports, _option) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _option.default
  });

  _exports.default = _default;
});