define("ember-components/templates/components/side-bar/multi-select/input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ql5jmGtI",
    "block": "{\"symbols\":[\"item\",\"element\",\"&default\",\"@items\"],\"statements\":[[4,\"each\",[[23,4,[]]],null,{\"statements\":[[4,\"with\",[[28,\"hash\",null,[[\"contains\"],[[28,\"array-contains\",[[24,[\"selectedItems\"]],[23,1,[\"name\"]]],[[\"property\"],[\"name\"]]]]]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"data-test-option\",[29,[[23,1,[\"code\"]]]]],[10,\"data-test-id\",\"input-option\"],[11,\"class\",[29,[\"checkbox-input \",[28,\"if\",[[23,2,[\"contains\"]],\"checked\"],null]]]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"onItemSelect\",[24,[\"selectedItems\"]],[23,1,[]],[23,2,[\"contains\"]]],null]],[8],[0,\"\\n      \"],[7,\"input\",true],[10,\"class\",\"checkbox\"],[11,\"checked\",[23,2,[\"contains\"]]],[10,\"type\",\"checkbox\"],[8],[9],[0,\"\\n\"],[4,\"if\",[[25,3]],null,{\"statements\":[[0,\"        \"],[14,3,[[28,\"hash\",null,[[\"item\"],[[23,1,[]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"span\",true],[10,\"class\",\"text\"],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/side-bar/multi-select/input.hbs"
    }
  });

  _exports.default = _default;
});