define("ember-components/components/side-bar/selected-item-block", ["exports", "ember-components/templates/components/side-bar/selected-item-block"], function (_exports, _selectedItemBlock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _selectedItemBlock.default,
    classNames: ["selected-block"],
    "data-test-id": "selected-block",
    canClearValue: Ember.computed("isClearable", {
      get() {
        return this.isClearable !== false;
      },

      set: (key, value) => value
    }),
    actions: {
      clearSelectedValue() {
        this.set("selectedItem", null);
        if (this.onClearValue) this.onClearValue();
      }

    }
  });

  _exports.default = _default;
});