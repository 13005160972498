define("ember-components/components/task-wrapper/error", ["exports", "ember-components/templates/components/task-wrapper/error"], function (_exports, _error) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _error.default,
    tagName: ""
  });

  _exports.default = _default;
});