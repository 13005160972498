define("ember-components/templates/components/medicaments-list/body/per-event", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+X5ihfrW",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"medicament-per-event\",[[24,[\"type\",\"eventsCount\"]],[24,[\"type\",\"totalAmount\"]]],null],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/medicaments-list/body/per-event.hbs"
    }
  });

  _exports.default = _default;
});