define("ember-components/components/tabular-menu", ["exports", "ember-components/templates/components/tabular-menu"], function (_exports, _tabularMenu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _tabularMenu.default,
    classNames: ["tabular-menu ui secondary pointing menu"]
  });

  _exports.default = _default;
});