define("ember-components/components/side-bar/single-input/search", ["exports", "ember-components/templates/components/side-bar/single-input/search", "ember-concurrency"], function (_exports, _search, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _search.default,
    tagName: "",
    minimumQueryLength: 3,

    isQueryGreaterThanOrEqualMinimumLength(query) {
      return query && query.trim().length >= this.minimumQueryLength;
    },

    onDataChange: Ember.observer("data", function () {
      this.dataChangedFromList ? Ember.set(this, "dataChangedFromList", false) : this.onSearch.perform(this.data);
    }),
    onSearchWithResultFinish: Ember.observer("onSearch.last.isFinished", async function () {
      Ember.get(this, "onSearch.last.value.length") && Ember.run.next(() => this.onSearchListVisible());
    }),
    onSearch: (0, _emberConcurrency.task)(function* (query) {
      if (this.isQueryGreaterThanOrEqualMinimumLength(query)) {
        yield (0, _emberConcurrency.timeout)(this.debounce);
        return yield this.source(query, this.searchLimit);
      }

      return null;
    }).restartable(),
    actions: {
      onSelectValue(value) {
        Ember.set(this, "dataChangedFromList", true);
        Ember.set(this, "onSearch.lastSuccessful.value", []);
        this.onSelectedItem ? this.onSelectedItem(value) : Ember.set(this, "data", value);
      }

    }
  });

  _exports.default = _default;
});