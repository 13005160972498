define("ember-components/templates/components/tabular-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qt80E9VQ",
    "block": "{\"symbols\":[\"LeftTabs\",\"@tabs\",\"&default\"],\"statements\":[[4,\"let\",[[28,\"component\",[\"tabular-menu/left\"],null]],null,{\"statements\":[[0,\"  \"],[14,3,[[28,\"hash\",null,[[\"left\",\"right\"],[[23,1,[]],[28,\"component\",[\"tabular-menu/right\"],null]]]]]],[0,\"\\n\"],[4,\"unless\",[[25,3]],null,{\"statements\":[[0,\"    \"],[6,[23,1,[]],[],[[\"@tabs\"],[[23,2,[]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/tabular-menu.hbs"
    }
  });

  _exports.default = _default;
});