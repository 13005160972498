define("ember-components/components/task-reloader", ["exports", "ember-components/templates/components/task-reloader"], function (_exports, _taskReloader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _taskReloader.default,
    classNames: ["refresh-container"]
  });

  _exports.default = _default;
});