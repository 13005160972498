define("ember-components/components/side-bar/numeric-input/date", ["exports", "ember-components/templates/components/side-bar/numeric-input/date", "moment", "ember-components/components/side-bar/numeric-input/abstract"], function (_exports, _date, _moment, _abstract) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstract.default.extend({
    layout: _date.default,
    time: Ember.inject.service(),
    intl: Ember.inject.service(),
    classNames: ["numeric-input", "date-picker"],
    classNameBindings: ["isNotValid:not-parsable"],
    isNotValid: Ember.computed.not("isValid"),
    customValidationRules: [],
    inputValue: Ember.computed("value", {
      set(key, value) {
        this.onInputChange && this.onInputChange(value);
        return value;
      },

      get() {
        return this.value ? (0, _moment.default)(this.value).format("YYYYMMDD") : "";
      }

    }),
    isInputLengthValid: () => true,

    didReceiveAttrs() {
      const {
        value
      } = this;

      if (value && !this.validate(this.inputValue)) {
        Ember.set(this, "error", null);
        Ember.set(this, "isValid", true);
      }

      this.updateAge(value);
    },

    convertTo: value => (0, _moment.default)(value),

    updateAge(date) {
      let age;

      if (date) {
        const now = this.time.getTime();
        const years = (0, _moment.default)(now).diff(date, "year");
        age = `(${years} ${this.intl.t("components.general.years")})`;
      }

      Ember.set(this, "age", age);
    },

    validate(value) {
      if (!(0, _moment.default)(value, "YYYYMMDD", true).isValid()) return this.intl.t("components.warnings.invalidDate");
      return this._super(value);
    },

    onNumberDelete() {
      let date = this.inputValue;

      if (date) {
        date = date.substring(0, date.length - 1);
        Ember.set(this, "inputValue", date);
        this.setValues(date);
      }
    },

    onNumberSelect(value) {
      let inputValue = this.inputValue || "";
      const maxDateLength = 8;
      if (inputValue.length < maxDateLength) inputValue += value;
      return this._super(inputValue);
    },

    onSelectionRemove() {
      Ember.set(this, "error", null);
      Ember.set(this, "inputValue", null);
      return this._super();
    },

    actions: {
      updateInputValue(value) {
        Ember.set(this, "inputValue", value);
        this.onNumberSelect("");
      }

    }
  });

  _exports.default = _default;
});