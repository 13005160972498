define("ember-components/services/device", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    isDesktop: () => !("ontouchstart" in window) || !navigator.maxTouchPoints
  });

  _exports.default = _default;
});