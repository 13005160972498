define("ember-components/components/side-bar/single-select", ["exports", "ember-components/templates/components/side-bar/single-select", "ember-components/helpers/is-item-equal"], function (_exports, _singleSelect, _isItemEqual) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _singleSelect.default,
    classNames: ["single-select"],
    fadeBackground: true,
    debounceTimeMS: 1000,
    sortingKeys: ["favourite"],
    closeOnSelect: true,
    sortedFavourites: Ember.computed.sort("favourites", "sortingKeys"),
    favourites: Ember.computed.filter("itemsWithSelectedOption", item => item.favourite),
    itemsWithSelectedOption: Ember.computed("items", "selectedItem", {
      get() {
        const {
          selectedItem,
          items
        } = this;
        return selectedItem && !this.isInArray(items, selectedItem) ? this.cloneArray(items).insertAt(0, selectedItem) : items;
      },

      set: (key, value) => value
    }),

    didInsertElement() {
      this._super(...arguments);

      const {
        selectedItem,
        items
      } = this;
      if (items && items.length === 1 && !selectedItem && this.required) this.set("selectedItem", items[0]);
    },

    isInArray(items, selectedItem) {
      return items && items.filter(item => (0, _isItemEqual.isItemEqual)(item, selectedItem, this.comparatorKey)).length;
    },

    cloneArray: array => array ? array.toArray() : []
  });

  _exports.default = _default;
});