define("ember-components/components/side-bar/numeric-input/abstract", ["exports", "ember-concurrency", "ember-components/helpers/switch-sidebar", "ember-components/mixins/sidebar-switcher"], function (_exports, _emberConcurrency, _switchSidebar, _sidebarSwitcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_sidebarSwitcher.default, {
    classNames: ["numeric-input"],
    classNameBindings: ["isNotValid:not-parsable"],
    isValid: true,
    intl: Ember.inject.service(),
    required: false,
    isNotValid: Ember.computed.not("isValid"),
    asString: value => value === undefined ? "" : `${value}`,

    didReceiveAttrs() {
      const {
        value
      } = this;
      this.set("parsedValue", value);
      this.set("error", null);
      this.set("isValid", true);
      return this._super(...arguments);
    },

    setInputValue(value) {
      const parsedValue = Ember.isPresent(value) ? this.asString(value) : "";
      this.onInputChange && this.onInputChange(parsedValue);
      return parsedValue;
    },

    inputValue: Ember.computed("value", {
      set(key, value) {
        return this.setInputValue(value);
      },

      get() {
        const {
          value
        } = this;
        return Ember.isPresent(value) ? this.asString(value) : "";
      }

    }),
    onRightPopupClose: (0, _emberConcurrency.task)(function* () {
      yield this.set("isRightPopupOpen", false);
    }),

    countChars(string, searchFor) {
      if (string && searchFor) return string.toString().split(searchFor).length - 1;
      return 0;
    },

    isInputLengthValid() {
      if (this.maxLength) {
        const input = this.inputValue;
        const countDigits = input != null ? input.toString().length : 0;
        return countDigits <= this.maxLength;
      }

      return true;
    },

    setValues(numericValue) {
      this.set("inputValue", numericValue);
      if (!this.required && !numericValue) return this.set("value", numericValue);
      const error = this.validate(numericValue);
      this.set("error", error);

      if (!error) {
        this.set("parsedValue", numericValue);
        this.set("value", this.convertTo(numericValue));
      }

      this.set("isValid", !error);
      return null;
    },

    validateCustomValidations(value) {
      for (let i = 0; i < this.customValidationRules.length; i++) {
        const validationError = this.customValidationRules[i](value);

        if (validationError) {
          return validationError;
        }
      }

      return null;
    },

    validate(value) {
      if (!this.isInputLengthValid()) return "reachedMaxLength";else if (value) {
        return this.validateCustomValidations(value);
      }
      return null;
    },

    onNumberSelect(numericValue) {
      this.setValues(numericValue);
    },

    onNumberDelete() {
      let numericValue = this.asString(this.inputValue);
      numericValue = numericValue.slice(0, -1);
      this.setValues(numericValue);
    },

    onSelectionRemove() {
      this.set("value", undefined);
    },

    actions: {
      onNumberSelect(value) {
        if (this.isInputLengthValid()) this.onNumberSelect(value);
      },

      onNumberDelete() {
        this.onNumberDelete();
      },

      onSelectionRemove() {
        this.onSelectionRemove();
      },

      onFavouriteSelect(value) {
        this.setValues(value);
      },

      onKeyPressed(event) {
        (0, _switchSidebar.switchSidebar)([event.code, this.element]);

        if (event.key === "Enter") {
          this.onRightPopupClose.perform();
        } else {
          this.set("inputValue", "");
          this.onNumberSelect(event.target.value);
        }

        event.stopPropagation();
      }

    }
  });

  _exports.default = _default;
});