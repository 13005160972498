define("ember-components/templates/components/side-bar/block-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PN1MJom2",
    "block": "{\"symbols\":[\"RightPopup\",\"Popup\",\"CornerLabel\",\"@title\",\"&default\",\"@value\",\"@type\",\"@count\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui block button\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"isRightPopupOpen\"]]],null],true],null]],[8],[0,\"\\n\"],[4,\"if\",[[25,5]],null,{\"statements\":[[0,\"    \"],[14,5,[[28,\"hash\",null,[[\"corner-label\"],[[28,\"component\",[\"side-bar/block-input/corner-label\"],[[\"value\",\"type\",\"count\"],[[24,[\"value\"]],[24,[\"type\"]],[24,[\"count\"]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"let\",[[28,\"component\",[\"side-bar/block-input/corner-label\"],null]],null,{\"statements\":[[0,\"      \"],[6,[23,3,[]],[],[[\"@value\",\"@type\",\"@count\"],[[23,6,[]],[23,7,[]],[23,8,[]]]]],[0,\"\\n\"]],\"parameters\":[3]},null]],\"parameters\":[]}],[0,\"  \"],[1,[23,4,[]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[25,5],[24,[\"isRightPopupOpen\"]]],null]],null,{\"statements\":[[4,\"let\",[[28,\"component\",[\"side-bar/right-popup\"],null]],null,{\"statements\":[[0,\"    \"],[6,[23,1,[]],[],[[\"@title\",\"@task\",\"@isRightPopupOpen\"],[[23,4,[]],[22,\"onRightPopupClose\"],[22,\"isRightPopupOpen\"]]],{\"statements\":[[0,\"\\n      \"],[6,[23,2,[\"header\"]],[],[[],[]]],[0,\"\\n\\n      \"],[6,[23,2,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n        \"],[14,5,[[28,\"hash\",null,[[\"popup\"],[[28,\"component\",[\"side-bar/block-input/option\"],null]]]]]],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/side-bar/block-input.hbs"
    }
  });

  _exports.default = _default;
});