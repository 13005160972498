define("ember-components/components/side-bar/time-picker/pop-up", ["exports", "ember-components/templates/components/side-bar/time-picker/pop-up", "ember-concurrency", "moment"], function (_exports, _popUp, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _popUp.default,
    "data-test-id": "time-pad",
    timeService: Ember.inject.service("time"),
    device: Ember.inject.service(),
    intl: Ember.inject.service(),
    timeInputId: "timeInput",
    dateInputId: "dateInput",
    focusedInput: null,
    invalidTimeMessageKey: "components.warnings.invalidTime",
    invalidDateMessageKey: "components.warnings.invalidDate",
    focusInput: id => $(`#${id}`)[0].focus(),
    blurInput: id => $(`#${id}`)[0].blur(),

    isTimeInputFormatValid(time) {
      return time.length === 4 && this.timeService.isTimeValid(time, "HHmm");
    },

    isDateInputFormatValid(date) {
      return date.length === 8 && this.timeService.isTimeValid(date, "YYYYMMDD");
    },

    didInsertElement() {
      this._super(...arguments);

      Ember.set(this, "focusedInput", this.timeInputId);
      if (this.device.isDesktop()) this.focusInput(this.timeInputId);
    },

    invalidInputMessage: Ember.computed("timeInput", "dateInput", "focusedInput", function () {
      const {
        timeInput,
        dateInput,
        focusedInput,
        timeInputId
      } = this;
      if (timeInput && timeInputId === focusedInput && !this.isTimeInputFormatValid(timeInput)) return this.intl.t(this.invalidTimeMessageKey);else if (dateInput && !this.isDateInputFormatValid(dateInput)) return this.intl.t(this.invalidDateMessageKey);
      return null;
    }),
    now: Ember.computed(function () {
      return (0, _moment.default)(this.timeService.getTime()).set({
        second: 0,
        millisecond: 0
      });
    }),
    submit: (0, _emberConcurrency.task)(function* () {
      yield Ember.set(this, "isRightPopupOpen", false);
    }),

    clearError() {
      Ember.set(this, "validationError", null);
      Ember.set(this, "invalidDateTime", null);
    },

    timeInput: Ember.computed("time", {
      get() {
        return this.time && this.timeService.formatTime(this.time, "HHmm");
      },

      set: (key, value) => value
    }),
    dateInput: Ember.computed("time", {
      get() {
        return this.time && this.timeService.formatTime(this.time, "YYYYMMDD");
      },

      set: (key, value) => value
    }),

    updateInputValue(inputKey, value, maxLength) {
      let input = this[inputKey] || "";

      if (input.length < maxLength) {
        input += value;
        Ember.set(this, inputKey, input);
      }
    },

    updateDateTimeValueIfValid() {
      const dateTime = `${this.dateInput} ${this.timeInput}`;
      if (this.timeService.isTimeValid(dateTime, "YYYYMMDD HHmm")) this.onSettingTime(dateTime);
    },

    onTimeInputChange() {
      const {
        dateInputId,
        timeInput
      } = this;

      if (this.isTimeInputFormatValid(timeInput)) {
        if (this.device.isDesktop()) this.focusInput(dateInputId);
        Ember.set(this, "focusedInput", dateInputId);
        if (!this.dateInput) Ember.set(this, "dateInput", this.timeService.formatTime(this.now, "YYYYMMDD"));
      }

      this.updateDateTimeValueIfValid();
    },

    onDateInputChange() {
      this.updateDateTimeValueIfValid();
    },

    onMobileInputClicked(inputId) {
      if (this.focusedInput === inputId) this.focusInput(inputId);else {
        this.blurInput(this.timeInputId);
        this.blurInput(this.dateInputId);
        Ember.set(this, "focusedInput", inputId);
      }
    },

    actions: {
      onSidebarFavouriteButtonClick(dateTime) {
        Ember.set(this, "time", dateTime || (0, _moment.default)());
      },

      onClearDateTimeClicked() {
        Ember.set(this, "time", null);
        Ember.set(this, "timeInput", null);
        Ember.set(this, "dateInput", null);
        Ember.set(this, "focusedInput", this.timeInputId);
        this.device.isDesktop() && this.focusInput(this.timeInputId);
        this.clearError();
        this.onClearValue && this.onClearValue();
      },

      onNumberSelect(value) {
        const {
          focusedInput
        } = this;

        if (focusedInput === this.timeInputId) {
          this.updateInputValue("timeInput", value, 4);
          this.onTimeInputChange();
        } else if (focusedInput === this.dateInputId) {
          this.updateInputValue("dateInput", value, 8);
          this.onDateInputChange();
        }
      },

      onDateUpdate(value) {
        Ember.set(this, "dateInput", value);
        this.onDateInputChange();
      },

      onTimeUpdate(value) {
        Ember.set(this, "timeInput", value);
        this.onTimeInputChange(value);
      },

      onNumberDelete() {
        const {
          focusedInput
        } = this;
        let activeInputValue = this[focusedInput];

        if (activeInputValue) {
          activeInputValue = activeInputValue.substring(0, activeInputValue.length - 1);
          Ember.set(this, focusedInput, activeInputValue);
        }
      },

      onInputClicked(inputId) {
        if (this.device.isDesktop()) {
          Ember.set(this, "focusedInput", inputId);
          this.focusInput(inputId);
        } else {
          this.onMobileInputClicked(inputId);
        }
      }

    }
  });

  _exports.default = _default;
});