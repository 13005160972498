define("ember-components/components/medicaments-list/medicament-events/body/td", ["exports", "ember-components/templates/components/medicaments-list/medicament-events/body/td"], function (_exports, _td) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _td.default,
    tagName: "td"
  });

  _exports.default = _default;
});