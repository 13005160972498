define("ember-components/templates/components/medicaments-list/expand-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FGoVG2L+",
    "block": "{\"symbols\":[\"@isSelected\"],\"statements\":[[7,\"i\",true],[10,\"data-test-id\",\"expand-icon\"],[11,\"class\",[29,[\"expand-icon angle icon grey small \",[28,\"if\",[[23,1,[]],\"up\",\"down\"],null]]]],[8],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/medicaments-list/expand-icon.hbs"
    }
  });

  _exports.default = _default;
});