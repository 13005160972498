define("ember-components/templates/components/message-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3JvFN6d3",
    "block": "{\"symbols\":[\"@description\",\"@message\",\"&default\",\"@type\"],\"statements\":[[4,\"if\",[[24,[\"isVisible\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[\"ui \",[23,4,[]],\" message\"]]],[10,\"data-test-id\",\"message-box\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"isClosable\"]]],null,{\"statements\":[[0,\"      \"],[7,\"i\",true],[10,\"class\",\"close icon\"],[10,\"data-test-id\",\"close-message\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"isVisible\"]]],null],false],null]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"div\",true],[10,\"class\",\"header\"],[10,\"data-test-id\",\"message\"],[8],[0,\"\\n\"],[4,\"if\",[[25,3]],null,{\"statements\":[[0,\"        \"],[14,3],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[23,2,[]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n\"],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"      \"],[7,\"p\",true],[10,\"data-test-id\",\"description\"],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/message-box.hbs"
    }
  });

  _exports.default = _default;
});