define("ember-components/templates/components/side-bar/single-input/comment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LkkH092x",
    "block": "{\"symbols\":[\"@icon\",\"@text\",\"&default\"],\"statements\":[[4,\"if\",[[24,[\"displayIcon\"]]],null,{\"statements\":[[0,\"  \"],[7,\"i\",true],[11,\"class\",[28,\"if\",[[23,1,[]],[23,1,[]],\"info circle icon\"],null]],[10,\"data-test-id\",\"comment-icon\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[1,[23,2,[]],false],[0,\"\\n\"],[14,3]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/side-bar/single-input/comment.hbs"
    }
  });

  _exports.default = _default;
});