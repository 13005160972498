define("ember-components/components/side-bar/single-select/options-list", ["exports", "ember-components/templates/components/side-bar/single-select/options-list"], function (_exports, _optionsList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _optionsList.default,
    tagName: ""
  });

  _exports.default = _default;
});