define("ember-components/templates/components/side-bar/common/help-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qAKrHo7a",
    "block": "{\"symbols\":[\"&default\",\"@title\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[10,\"data-test-id\",\"toggle-block\"],[8],[0,\"\\n  \"],[7,\"i\",true],[11,\"class\",[22,\"icon\"]],[8],[9],[0,\"\\n  \"],[7,\"h4\",true],[10,\"class\",\"title\"],[10,\"data-test-id\",\"help-title\"],[8],[1,[23,2,[]],false],[9],[0,\"\\n  \"],[7,\"i\",true],[11,\"class\",[29,[\"chevron \",[28,\"if\",[[24,[\"isBlockVisible\"]],[24,[\"collapseIcon\"]],[24,[\"expandIcon\"]]],null],\" icon large grey toggle-icon\"]]],[10,\"data-test-id\",\"toggle-icon\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"isBlockVisible\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"content\"],[10,\"data-test-id\",\"help-content\"],[8],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/side-bar/common/help-block.hbs"
    }
  });

  _exports.default = _default;
});