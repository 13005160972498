define("ember-components/templates/components/side-bar/multi-input/input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nhEFpvED",
    "block": "{\"symbols\":[\"@title\"],\"statements\":[[4,\"if\",[[24,[\"errorMessage\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"ui pointing below red basic label\"],[10,\"data-test-id\",\"error-message\"],[8],[0,\"\\n    \"],[1,[22,\"errorMessage\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"label\",true],[10,\"data-test-id\",\"title\"],[8],[1,[23,1,[]],false],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"maxLength\"]]],null,{\"statements\":[[0,\"  \"],[5,\"one-way-number-mask\",[[12,\"data-test-mask\",\"input-with-mask\"],[12,\"class\",\"input-with-mask\"],[12,\"placeholder\",[28,\"repeat-string\",[\"_\",[24,[\"maxLength\"]]],null]],[12,\"onclick\",[28,\"action\",[[23,0,[]],\"onInputClicked\"],null]]],[[\"@value\",\"@update\",\"@mask\"],[[22,\"parsedInputValue\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"inputValue\"]]],null]],null],[29,[[28,\"repeat-string\",[\"9\",[24,[\"maxLength\"]]],null]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"input\",true],[11,\"value\",[22,\"parsedInputValue\"]],[11,\"onkeyup\",[28,\"action\",[[23,0,[]],\"onKeyPressed\"],null]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"onInputClicked\"],null]],[10,\"type\",\"number\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/side-bar/multi-input/input.hbs"
    }
  });

  _exports.default = _default;
});