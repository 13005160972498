define("ember-components/components/side-bar/block-input/corner-label", ["exports", "ember-components/templates/components/side-bar/block-input/corner-label"], function (_exports, _cornerLabel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _cornerLabel.default,
    tagName: ""
  });

  _exports.default = _default;
});