define("ember-components/components/side-bar/single-input", ["exports", "ember-components/templates/components/side-bar/single-input", "ember-components/mixins/validation-class-mixin", "ember-components/mixins/sidebar-switcher"], function (_exports, _singleInput, _validationClassMixin, _sidebarSwitcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_validationClassMixin.default, _sidebarSwitcher.default, {
    layout: _singleInput.default,
    attributeBindings: ["tabindex"],
    classNames: ["input-inline", "input-view"],
    classNameBindings: ["isInputFocused:focused", "isSearchable:searchable"],
    "data-test-id": ["input-container"],
    readOnly: false,
    tabindex: Ember.computed("disabled", function () {
      return this.disabled ? null : 0;
    }),
    isSearchable: Ember.computed("source", "readOnly", "isFocused", "isInputFocused", function () {
      return this.source && (this.isFocused || this.isInputFocused) && !this.readOnly;
    }),
    parentOrActiveElementSelector: Ember.computed("data", function () {
      const {
        activeElement
      } = document;
      const parentElementId = activeElement.parentElement.id;
      if (parentElementId) return parentElementId;
      return activeElement.id ? `#${activeElement.id}` : "";
    }),

    canBubbleUp({
      keyCode,
      target
    }) {
      return keyCode === 13 && target.tagName !== "TEXTAREA" && typeof this.onclick === "function";
    },

    keyUp(event) {
      this.canBubbleUp(event) && Ember.run.next(() => this.onclick());
    },

    actions: {
      onclick() {
        this.onclick && this.onclick();
      },

      onSearchListVisible() {
        this.onSearchListVisible && this.onSearchListVisible();
      }

    }
  });

  _exports.default = _default;
});