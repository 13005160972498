define("ember-components/components/side-bar/single-select/spinner-loader", ["exports", "ember-components/templates/components/side-bar/single-select/spinner-loader"], function (_exports, _spinnerLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _spinnerLoader.default,
    classNames: ["ui", "active", "text", "centered", "inline", "loader", "spinner-loader"],
    "data-test-id": "loader"
  });

  _exports.default = _default;
});