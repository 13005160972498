define("ember-components/templates/components/side-bar/input-popup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CL/yyVCN",
    "block": "{\"symbols\":[\"RightPopup\",\"Popup\",\"@popup-title\",\"@validation\",\"@className\",\"&default\",\"@input-label\"],\"statements\":[[7,\"a\",true],[10,\"data-test-id\",\"input-label\"],[11,\"onclick\",[28,\"disable-bubbling\",[[28,\"action\",[[23,0,[]],\"onRightPopupOpen\"],null]],null]],[8],[1,[23,7,[]],false],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"isRightPopupOpen\"]]],null,{\"statements\":[[4,\"let\",[[28,\"component\",[\"side-bar/right-popup\"],null]],null,{\"statements\":[[0,\"    \"],[6,[23,1,[]],[],[[\"@isRightPopupOpen\",\"@title\",\"@task\",\"@message\",\"@className\"],[[22,\"isRightPopupOpen\"],[23,3,[]],[22,\"onRightPopupClose\"],[23,4,[]],[23,5,[]]]],{\"statements\":[[0,\"\\n      \"],[6,[23,2,[\"header\"]],[],[[],[]]],[0,\"\\n      \"],[14,6],[0,\"\\n    \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/side-bar/input-popup.hbs"
    }
  });

  _exports.default = _default;
});