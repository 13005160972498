define("ember-components/components/checkbox-group", ["exports", "ember-components/templates/components/checkbox-group", "ember-components/mixins/validation-class-mixin", "ember-components/helpers/is-item-in-array", "ember-get-config"], function (_exports, _checkboxGroup, _validationClassMixin, _isItemInArray, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_validationClassMixin.default, {
    layout: _checkboxGroup.default,
    "data-test-id": "checkbox-group",
    classNames: ["checkbox-group"],
    removeItemFromArray: (array, item) => {
      if (typeof item === "object") item = (0, _isItemInArray.getObjectIfMatches)(array, item, _emberGetConfig.default.APP.OBJECT_COMPARATOR_KEY);
      array.removeObject(item);
    },
    addItemToArray: (array, item) => array.pushObject(item),
    actions: {
      onItemSelect(item) {
        (0, _isItemInArray.isItemInArray)([this.selectedItems, item]) ? this.removeItemFromArray(this.selectedItems, item) : this.addItemToArray(this.selectedItems, item);
        if (this.onSelectedValue) this.onSelectedValue();
      }

    }
  });

  _exports.default = _default;
});