define("ember-components/templates/components/side-bar/time-picker/favourite-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wvt470yG",
    "block": "{\"symbols\":[\"SingleInputIcon\",\"favourite\",\"@time\",\"@key\",\"@header\",\"@onOtherOption\",\"@favourites\"],\"statements\":[[7,\"div\",true],[10,\"data-test-id\",\"single-input-header\"],[10,\"class\",\"title\"],[8],[1,[23,5,[]],false],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"favourite-buttons-container\"],[11,\"onClick\",[23,6,[]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"input\"],[8],[0,\"\\n\"],[4,\"each\",[[23,7,[]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"medium ui basic button favourite-button\"],[10,\"data-test-id\",\"favourite-button\"],[11,\"data-test-option\",[29,[[23,2,[\"code\"]]]]],[11,\"onclick\",[28,\"disable-bubbling\",[[28,\"action\",[[23,0,[]],\"onSettingTime\",[23,3,[]],[23,2,[\"difference\"]],[23,2,[\"unit\"]]],null]],null]],[8],[0,\"\\n        \"],[1,[28,\"get\",[[23,2,[]],[23,4,[]]],null],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"other-button\"],[10,\"data-test-id\",\"other-option-button\"],[10,\"data-test-option\",\"OTHER\"],[8],[0,\"\\n\"],[4,\"let\",[[28,\"component\",[\"side-bar/single-input/icon\"],null]],null,{\"statements\":[[0,\"      \"],[6,[23,1,[]],[[12,\"class\",\"blue icon align justify\"]],[[],[]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/side-bar/time-picker/favourite-select.hbs"
    }
  });

  _exports.default = _default;
});