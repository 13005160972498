define("ember-components/helpers/array-to-string", ["exports", "ember-components/mixins/helper-observer"], function (_exports, _helperObserver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.arrayToString = arrayToString;
  _exports.default = void 0;

  function arrayToString(array, key, separator = ", ") {
    if (!array) return "";
    if (!key) return array.filter(element => element).join(separator);
    return array.map(element => Ember.get(element, key)).join(separator);
  }

  var _default = Ember.Helper.extend(_helperObserver.default, {
    compute([array, key, separator]) {
      if (array && array.addObserver) {
        const isObjectWithKey = Ember.isPresent(Ember.get(array, `firstObject.${key}`));
        this.setupRecompute(array, isObjectWithKey ? `@each.${key}` : "length");
      }

      return arrayToString(array, key, separator);
    }

  });

  _exports.default = _default;
});