define("ember-components/templates/components/ui-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "inAHyPvh",
    "block": "{\"symbols\":[\"@cssClass\",\"&default\",\"@isLoading\"],\"statements\":[[4,\"if\",[[23,3,[]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[11,\"class\",[29,[\"ui active loader \",[23,1,[]]]]],[10,\"data-test-id\",\"ui-loader\"],[8],[0,\"\\n    \"],[14,2],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/ui-loader.hbs"
    }
  });

  _exports.default = _default;
});