define("ember-components/components/medicaments-list/header", ["exports", "ember-components/templates/components/medicaments-list/header"], function (_exports, _header) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _header.default,
    tagName: "thead",
    ["data-test-id"]: "header"
  });

  _exports.default = _default;
});