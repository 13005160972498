define("ember-components/templates/components/side-bar/time-picker/generic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J+RAPHpn",
    "block": "{\"symbols\":[\"SingleInput\",\"Ui\",\"@header\",\"@validation\",\"@isRightPopupOpen\",\"@onShowTimePickerPanel\",\"@time\",\"@readOnly\"],\"statements\":[[4,\"let\",[[28,\"component\",[\"side-bar/single-input\"],null]],null,{\"statements\":[[0,\"  \"],[6,[23,1,[]],[],[[\"@readOnly\",\"@title\",\"@validation\",\"@isInputFocused\",\"@onclick\"],[true,[23,3,[]],[23,4,[]],[23,5,[]],[23,6,[]]]],{\"statements\":[[0,\"\\n    \"],[6,[23,2,[\"template\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"moment-format\",[[23,7,[]],\"YYYY-MM-DD HH:mm\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[6,[23,2,[\"icon\"]],[],[[\"@readOnly\"],[[23,8,[]]]]],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/side-bar/time-picker/generic.hbs"
    }
  });

  _exports.default = _default;
});