define("ember-components/templates/components/side-bar/single-select/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1hj9aOfw",
    "block": "{\"symbols\":[\"@onSearch\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"column input-section\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui search\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"ui icon input\"],[8],[0,\"\\n      \"],[7,\"input\",true],[10,\"data-test-id\",\"search-block-input-field\"],[11,\"placeholder\",[28,\"t\",[\"components.enterSearchQuery\"],null]],[11,\"oninput\",[28,\"perform\",[[23,1,[]]],[[\"value\"],[\"target.value\"]]]],[10,\"type\",\"text\"],[8],[9],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"search grey icon\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[14,2],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/side-bar/single-select/search.hbs"
    }
  });

  _exports.default = _default;
});