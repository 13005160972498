define("ember-components/templates/components/tabular-menu/tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OPFz15Gm",
    "block": "{\"symbols\":[\"tab\",\"@icon\",\"@name\",\"@path\",\"@disabled\",\"@tabs\"],\"statements\":[[4,\"if\",[[23,6,[]]],null,{\"statements\":[[4,\"each\",[[23,6,[]]],null,{\"statements\":[[4,\"link-to\",null,[[\"class\",\"data-test-id\",\"disabled\",\"route\"],[\"item\",\"tab\",[23,1,[\"disabled\"]],[23,1,[\"path\"]]]],{\"statements\":[[4,\"if\",[[23,1,[\"icon\"]]],null,{\"statements\":[[0,\"        \"],[7,\"i\",true],[11,\"class\",[23,1,[\"icon\"]]],[10,\"data-test-id\",\"icon\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[7,\"span\",true],[10,\"class\",\"tab-title\"],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[4,\"link-to\",null,[[\"class\",\"data-test-id\",\"disabled\",\"route\"],[\"item\",\"tab\",[23,5,[]],[23,4,[]]]],{\"statements\":[[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"      \"],[7,\"i\",true],[11,\"class\",[23,2,[]]],[10,\"data-test-id\",\"icon\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[1,[23,3,[]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/tabular-menu/tab.hbs"
    }
  });

  _exports.default = _default;
});