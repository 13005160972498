define('ember-metrics/metrics-adapters/base', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function makeToString(ret) {
    return () => ret;
  }

  exports.default = Ember.Object.extend({
    init() {
      (false && !(false) && Ember.assert(`[ember-metrics] ${this.toString()} must implement the init hook!`));
    },

    willDestroy() {
      (false && !(false) && Ember.assert(`[ember-metrics] ${this.toString()} must implement the willDestroy hook!`));
    },

    toString() {
      const hasToStringExtension = Ember.typeOf(this.toStringExtension) === 'function';
      const extension = hasToStringExtension ? ':' + this.toStringExtension() : '';
      const ret = `ember-metrics@metrics-adapter:${extension}:${Ember.guidFor(this)}`;

      this.toString = makeToString(ret);
      return ret;
    },

    metrics: null,
    config: null,
    identify() {},
    trackEvent() {},
    trackPage() {},
    alias() {}
  });
});