define('ember-g-map/components/g-map-route', ['exports', 'ember-g-map/templates/components/g-map-route', 'ember-g-map/components/g-map', 'ember-g-map/utils/compact'], function (exports, _gMapRoute, _gMap, _compact) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const allowedPolylineOptions = Ember.A(['strokeColor', 'strokeWeight', 'strokeOpacity', 'zIndex']);

  const TRAVEL_MODES = {
    walking: Ember.get(window, 'google.maps.TravelMode.WALKING'),
    bicycling: Ember.get(window, 'google.maps.TravelMode.BICYCLING'),
    transit: Ember.get(window, 'google.maps.TravelMode.TRANSIT'),
    driving: Ember.get(window, 'google.maps.TravelMode.DRIVING')
  };

  const GMapRouteComponent = Ember.Component.extend({
    layout: _gMapRoute.default,
    classNames: ['g-map-marker'],
    positionalParams: ['mapContext'],

    map: Ember.computed.alias('mapContext.map'),

    init() {
      this._super(...arguments);
      this.set('waypoints', Ember.A());
      const mapContext = this.get('mapContext');
      (false && !(mapContext instanceof _gMap.default) && Ember.assert('Must be inside {{#g-map}} component with context set', mapContext instanceof _gMap.default));
    },

    didInsertElement() {
      this._super(...arguments);
      this.initDirectionsService();
    },

    willDestroyElement() {
      const renderer = this.get('directionsRenderer');
      if (Ember.isPresent(renderer)) {
        renderer.setMap(null);
      }
    },

    mapWasSet: Ember.observer('map', function () {
      Ember.run.once(this, 'initDirectionsService');
    }),

    initDirectionsService() {
      const map = this.get('map');
      let service = this.get('directionsService');
      let renderer = this.get('directionsRenderer');

      if (Ember.isPresent(map) && Ember.isEmpty(service) && Ember.isEmpty(renderer) && typeof FastBoot === 'undefined' && typeof google !== 'undefined') {
        const rendererOptions = {
          map,
          suppressMarkers: true,
          preserveViewport: true
        };
        renderer = new google.maps.DirectionsRenderer(rendererOptions);
        service = new google.maps.DirectionsService();

        this.set('directionsRenderer', renderer);
        this.set('directionsService', service);

        this.updateRoute();
        this.updatePolylineOptions();
      }
    },

    onLocationsChanged: Ember.observer('originLat', 'originLng', 'destinationLat', 'destinationLng', 'travelMode', function () {
      Ember.run.once(this, 'updateRoute');
    }),

    updateRoute() {
      const service = this.get('directionsService');
      const renderer = this.get('directionsRenderer');
      const originLat = this.get('originLat');
      const originLng = this.get('originLng');
      const destinationLat = this.get('destinationLat');
      const destinationLng = this.get('destinationLng');
      const waypoints = this.get('waypoints').mapBy('waypoint');

      if (Ember.isPresent(service) && Ember.isPresent(renderer) && Ember.isPresent(originLat) && Ember.isPresent(originLng) && Ember.isPresent(destinationLat) && Ember.isPresent(destinationLng) && typeof FastBoot === 'undefined') {
        const origin = new google.maps.LatLng(this.get('originLat'), this.get('originLng'));
        const destination = new google.maps.LatLng(this.get('destinationLat'), this.get('destinationLng'));
        const travelMode = this.retrieveTravelMode(this.get('travelMode'));
        const request = {
          origin,
          destination,
          travelMode,
          waypoints
        };

        service.route(request, (response, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            renderer.setDirections(response);
          }
        });
      }
    },

    polylineOptionsChanged: Ember.observer(...allowedPolylineOptions, function () {
      Ember.run.once(this, 'updatePolylineOptions');
    }),

    updatePolylineOptions() {
      const renderer = this.get('directionsRenderer');
      const polylineOptions = (0, _compact.default)(this.getProperties(allowedPolylineOptions));

      if (Ember.isPresent(renderer) && Ember.isPresent(Object.keys(polylineOptions))) {
        renderer.setOptions({ polylineOptions });

        const directions = renderer.getDirections();
        if (Ember.isPresent(directions)) {
          renderer.setDirections(directions);
        }
      }
    },

    retrieveTravelMode(mode) {
      return TRAVEL_MODES.hasOwnProperty(mode) ? TRAVEL_MODES[mode] : TRAVEL_MODES.driving;
    },

    registerWaypoint(waypoint) {
      this.get('waypoints').addObject(waypoint);
    },

    unregisterWaypoint(waypoint) {
      this.get('waypoints').removeObject(waypoint);
    },

    waypointsChanged: Ember.observer('waypoints.@each.location', function () {
      Ember.run.once(this, 'updateRoute');
    })
  });

  GMapRouteComponent.reopenClass({
    positionalParams: ['mapContext']
  });

  exports.default = GMapRouteComponent;
});