define("ember-components/templates/components/side-bar/notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2pAMu75/",
    "block": "{\"symbols\":[\"@title\",\"&default\"],\"statements\":[[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"  \"],[7,\"h2\",true],[8],[1,[22,\"titleHtml\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\",true],[10,\"data-test-id\",\"description\"],[8],[14,2],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/side-bar/notification.hbs"
    }
  });

  _exports.default = _default;
});