define("ember-intl/utils/links", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PROJECT_URL = 'https://github.com/jasonmit/ember-intl';
  var _default = {
    unsetLocale: `${PROJECT_URL}#setting-runtime-locale`,
    asyncTranslations: `${PROJECT_URL}/blob/master/docs/asynchronously-loading-translations.md#asynchronous-loading-of-translations`,
    polyfill: `${PROJECT_URL}/blob/master/docs/intljs-polyfill.md`
  };
  _exports.default = _default;
});