define("ember-components/components/marker-wrapper", ["exports", "ember-components/templates/components/marker-wrapper"], function (_exports, _markerWrapper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _markerWrapper.default,
    classNames: ["marker"],

    didInsertElement() {
      this._super(...arguments);

      const markInstance = new Mark(this.element);
      this.set("markInstance", markInstance);
      this.markText(this.query);
    },

    didUpdateAttrs() {
      this._super(...arguments);

      this.markText(this.query);
    },

    markText(query) {
      const {
        markInstance
      } = this;
      markInstance.unmark({
        done: () => {
          if (query) markInstance.mark(query);
        }
      });
    },

    willDestroy() {
      delete this.markInstance;
      this.set("markInstance", null);
    }

  });

  _exports.default = _default;
});