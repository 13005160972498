define("ember-components/templates/components/side-bar/multi-select/favourite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/48INIvC",
    "block": "{\"symbols\":[\"favourite\",\"@favourites\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"favourite-buttons-container input\"],[10,\"data-test-id\",\"favourite-buttons-container\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"items\"],[8],[0,\"\\n\"],[4,\"each\",[[23,2,[]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"medium ui basic button favourite-button\"],[10,\"data-test-id\",\"favourite-button\"],[11,\"data-test-option\",[29,[[23,1,[\"code\"]]]]],[11,\"onclick\",[28,\"disable-bubbling\",[[28,\"action\",[[23,0,[]],\"onSelectedItem\",[23,1,[]]],null]],null]],[8],[0,\"\\n        \"],[1,[23,1,[\"name\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/side-bar/multi-select/favourite.hbs"
    }
  });

  _exports.default = _default;
});