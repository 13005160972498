define("ember-components/templates/components/date-range-picker/time-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o+A+1Meh",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[8],[1,[28,\"moment-format\",[[24,[\"time\"]],\"YYYY-MM-DD\"],null],false],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"time\"],[8],[1,[28,\"moment-format\",[[24,[\"time\"]],\"HH:mm\"],null],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/date-range-picker/time-block.hbs"
    }
  });

  _exports.default = _default;
});