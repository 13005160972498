define("ember-components/components/side-bar/single-select/search", ["exports", "ember-components/templates/components/side-bar/single-select/search", "ember-components/mixins/focus-input-field"], function (_exports, _search, _focusInputField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_focusInputField.default, {
    layout: _search.default,
    "data-test-id": "search-block",
    classNames: ["search-block"],

    didInsertElement() {
      this._super(...arguments);

      this.element.querySelector("input").focus();
    }

  });

  _exports.default = _default;
});