define("ember-components/helpers/promise-converter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.promiseToObjectsConverter = promiseToObjectsConverter;
  _exports.promiseToValueConverter = promiseToValueConverter;
  _exports.promiseToPlainValueConverter = promiseToPlainValueConverter;

  const _createPromise = resolveData => new Ember.RSVP.Promise(async (resolve, reject) => {
    try {
      resolve(resolveData);
    } catch (e) {
      reject(e);
    }
  });

  function promiseToObjectsConverter(promise, properties) {
    if (!promise || !properties || !properties.length) return [];

    const getData = async () => {
      const modelRecords = await promise;
      if (modelRecords) return modelRecords.map ? modelRecords.map(object => object && Ember.getProperties(object, properties)) : modelRecords.getProperties(properties);
      return modelRecords;
    };

    return _createPromise(getData());
  }

  function promiseToValueConverter(promise, property) {
    if (!promise || !property) return null;

    const getData = async () => {
      const modelRecords = await promise;
      return modelRecords.map(object => Ember.get(object, property)).filter(value => value);
    };

    return _createPromise(getData());
  }

  function promiseToPlainValueConverter(promise) {
    if (!promise) return null;

    const getData = async () => await promise;

    return _createPromise(getData());
  }
});