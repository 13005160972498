define("ember-components/components/tabular-menu/tab", ["exports", "ember-components/templates/components/tabular-menu/tab"], function (_exports, _tab) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _tab.default,
    tagName: ""
  });

  _exports.default = _default;
});