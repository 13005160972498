define("ember-components/templates/components/side-bar/time-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yU1cdp4z",
    "block": "{\"symbols\":[\"FavouriteSelect\",\"Generic\",\"Popup\",\"popup\",\"@header\",\"@validation\",\"@fadeBackground\",\"@sidebarFavouriteOption\",\"&default\"],\"statements\":[[4,\"let\",[[28,\"component\",[\"side-bar/time-picker/favourite-select\"],null],[28,\"component\",[\"side-bar/time-picker/generic\"],null],[28,\"component\",[\"side-bar/time-picker/pop-up\"],null]],null,{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"isFavouriteOptionsFeatureEnabled\"]]],null,{\"statements\":[[0,\"    \"],[6,[23,1,[]],[],[[\"@key\",\"@favourites\",\"@time\",\"@header\",\"@validation\",\"@onSettingTime\",\"@onOtherOption\"],[\"name\",[22,\"items\"],[22,\"time\"],[23,5,[]],[23,6,[]],[28,\"action\",[[23,0,[]],\"setTime\"],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"isRightPopupOpen\"]]],null],true],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,[23,2,[]],[],[[\"@time\",\"@header\",\"@now\",\"@validation\",\"@isRightPopupOpen\",\"@onShowTimePickerPanel\"],[[22,\"time\"],[23,5,[]],[22,\"now\"],[23,6,[]],[22,\"isRightPopupOpen\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"isRightPopupOpen\"]]],null],true],null]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[24,[\"isRightPopupOpen\"]]],null,{\"statements\":[[0,\"    \"],[6,[23,3,[]],[],[[\"@header\",\"@time\",\"@validation\",\"@isRightPopupOpen\",\"@fadeBackground\",\"@customValidationRules\",\"@validationError\",\"@sidebarSwitcher\",\"@sidebarFavouriteOption\",\"@onSettingTime\"],[[23,5,[]],[22,\"time\"],[23,6,[]],[22,\"isRightPopupOpen\"],[23,7,[]],[22,\"customValidationRules\"],[22,\"error\"],[22,\"sidebarSwitcher\"],[23,8,[]],[28,\"action\",[[23,0,[]],\"setTime\"],null]]],{\"statements\":[[0,\"\\n      \"],[14,9,[[28,\"hash\",null,[[\"validator\"],[[23,4,[\"validator\"]]]]]]],[0,\"\\n    \"]],\"parameters\":[4]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1,2,3]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/side-bar/time-picker.hbs"
    }
  });

  _exports.default = _default;
});