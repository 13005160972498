define("ember-components/helpers/date-interval-to-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.getFormattedDateInterval = void 0;

  const formatValidDate = (fromDate, toDate) => fromDate === toDate ? fromDate : `${fromDate} - ${toDate}`;

  const getFormattedDateInterval = ([fromDate, toDate]) => {
    return fromDate && toDate ? formatValidDate(fromDate, toDate) : "";
  };

  _exports.getFormattedDateInterval = getFormattedDateInterval;

  var _default = Ember.Helper.helper(getFormattedDateInterval);

  _exports.default = _default;
});