define("ember-components/components/side-bar/single-select/options-list/header", ["exports", "ember-components/templates/components/side-bar/single-select/options-list/header"], function (_exports, _header) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _header.default,
    "data-test-id": "options-list-header",
    classNames: ["options-list-header"]
  });

  _exports.default = _default;
});