define("ember-components/components/filter-composer/center-modal", ["exports", "ember-components/templates/components/filter-composer/center-modal", "ember-concurrency"], function (_exports, _centerModal, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _centerModal.default,
    tagName: "",
    closeFilterModal: (0, _emberConcurrency.task)(function* () {
      yield Ember.set(this, "isFilterPopupOpened", false);
    }),
    actions: {
      onFilterButtonSubmit(filters) {
        Ember.set(this, "isFilterSubmitted", true);
        Ember.set(this, "isFilterPopupOpened", false);
        this.filterButtonTask.perform(filters);
      }

    }
  });

  _exports.default = _default;
});