define("ember-components/components/lazy-yield", ["exports", "ember-components/templates/components/lazy-yield"], function (_exports, _lazyYield) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _lazyYield.default,
    tagName: "",
    preCreateDom: false,
    createDom: Ember.computed("visible", "preCreateDom", {
      get() {
        return this.visible || this.preCreateDom ? Ember.set(this, "_preCreateDom", true) : this._preCreateDom;
      },

      set: (key, value) => value
    })
  });

  _exports.default = _default;
});