define("ember-components/helpers/letter-capitalizer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.letterCapitalizer = letterCapitalizer;
  _exports.default = void 0;

  function letterCapitalizer([string]) {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
  }

  var _default = Ember.Helper.helper(letterCapitalizer);

  _exports.default = _default;
});