define("ember-components/helpers/sub-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.subString = subString;
  _exports.default = void 0;

  const isNumber = value => typeof value === "number";

  function subString([string, from, to]) {
    if (string && isNumber(from) && isNumber(to)) return string.substring(from, to);
    return "";
  }

  var _default = Ember.Helper.helper(subString);

  _exports.default = _default;
});