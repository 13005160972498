define('ember-cli-mirage/orm/polymorphic-collection', ['exports', 'lodash/invokeMap', 'lodash/isEqual'], function (exports, _invokeMap2, _isEqual2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  /**
   * An array of models, returned from one of the schema query
   * methods (all, find, where). Knows how to update and destroy its models.
   *
   * Identical to Collection except it can contain a heterogeneous array of
   * models. Thus, it has no `modelName` property. This lets serializers and
   * other parts of the system interact with it differently.
   *
   * @class PolymorphicCollection
   * @constructor
   * @public
   */
  class PolymorphicCollection {
    constructor(models = []) {
      this.models = models;
    }

    /**
     * Number of models in the collection.
     *
     * @property length
     * @type Number
     * @public
     */
    get length() {
      return this.models.length;
    }

    /**
     * Updates each model in the collection (persisting immediately to the db).
     * @method update
     * @param key
     * @param val
     * @return this
     * @public
     */
    update(...args) {
      (0, _invokeMap2.default)(this.models, 'update', ...args);

      return this;
    }

    /**
     * Destroys the db record for all models in the collection.
     * @method destroy
     * @return this
     * @public
     */
    destroy() {
      (0, _invokeMap2.default)(this.models, 'destroy');

      return this;
    }

    /**
     * Saves all models in the collection.
     * @method save
     * @return this
     * @public
     */
    save() {
      (0, _invokeMap2.default)(this.models, 'save');

      return this;
    }

    /**
     * Reloads each model in the collection.
     * @method reload
     * @return this
     * @public
     */
    reload() {
      (0, _invokeMap2.default)(this.models, 'reload');

      return this;
    }

    /**
     * Adds a model to this collection
     *
     * @method add
     * @return this
     * @public
     */
    add(model) {
      this.models.push(model);

      return this;
    }

    /**
     * Removes a model to this collection
     *
     * @method remove
     * @return this
     * @public
     */
    remove(model) {
      var _models$filter = this.models.filter(m => (0, _isEqual2.default)(m.attrs, model.attrs)),
          _models$filter2 = _slicedToArray(_models$filter, 1);

      let match = _models$filter2[0];

      if (match) {
        let i = this.models.indexOf(match);
        this.models.splice(i, 1);
      }

      return this;
    }

    /**
     * Checks if the collection includes the model
     *
     * @method includes
     * @return boolean
     * @public
     */
    includes(model) {
      return this.models.filter(m => (0, _isEqual2.default)(m.attrs, model.attrs)).length > 0;
    }

    /**
     * @method filter
     * @param f
     * @return {Collection}
     * @public
     */
    filter(f) {
      let filteredModels = this.models.filter(f);

      return new PolymorphicCollection(filteredModels);
    }

    /**
     * @method sort
     * @param f
     * @return {Collection}
     * @public
     */
    sort(f) {
      let sortedModels = this.models.concat().sort(f);

      return new PolymorphicCollection(sortedModels);
    }

    /**
     * @method slice
     * @param {Integer} begin
     * @param {Integer} end
     * @return {Collection}
     * @public
     */
    slice(...args) {
      let slicedModels = this.models.slice(...args);

      return new PolymorphicCollection(slicedModels);
    }

    /**
     * @method mergeCollection
     * @param collection
     * @return this
     * @public
     */
    mergeCollection(collection) {
      this.models = this.models.concat(collection.models);

      return this;
    }

    /**
     * Simple string representation of the collection and id.
     * @method toString
     * @return {String}
     * @public
     */
    toString() {
      return `collection:${this.modelName}(${this.models.map(m => m.id).join(',')})`;
    }
  }
  exports.default = PolymorphicCollection;
});