define("ember-components/templates/components/side-bar/block-input/corner-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "msqGGQ45",
    "block": "{\"symbols\":[\"@count\",\"@type\",\"@value\"],\"statements\":[[4,\"if\",[[28,\"or\",[[23,3,[]],[28,\"gt\",[[24,[\"count\"]],0],null]],null]],null,{\"statements\":[[0,\"  \"],[7,\"a\",true],[11,\"class\",[29,[\"ui right corner label \",[28,\"if\",[[23,2,[]],[23,2,[]],\"valid\"],null]]]],[10,\"data-test-id\",\"corner-label\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"lte\",[[23,1,[]],9],null]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[10,\"class\",\"counter label\"],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"i\",true],[11,\"class\",[29,[[28,\"if\",[[23,1,[]],\"warning\",\"checkmark\"],null],\" icon\"]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/side-bar/block-input/corner-label.hbs"
    }
  });

  _exports.default = _default;
});