define("ember-components/components/side-bar/numeric-input/input-section", ["exports", "ember-components/templates/components/side-bar/numeric-input/input-section", "ember-components/mixins/pad-actions"], function (_exports, _inputSection, _padActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_padActions.default, {
    layout: _inputSection.default,
    classNames: ["twelve wide column input-section"]
  });

  _exports.default = _default;
});