define("ember-components/components/letter-filter", ["exports", "ember-components/templates/components/letter-filter"], function (_exports, _letterFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _letterFilter.default,
    classNames: ["block-filter"],
    ranges: [{
      from: "a",
      to: "d",
      name: "A-D",
      code: "FIRST_RANGE"
    }, {
      from: "e",
      to: "g",
      name: "E-G",
      code: "SECOND_RANGE"
    }, {
      from: "h",
      to: "m",
      name: "H-M",
      code: "THIRD_RANGE"
    }, {
      from: "n",
      to: "z",
      name: "N-Z",
      code: "FOURTH_RANGE"
    }],
    actions: {
      filterItems(from, to) {
        const filteredItems = [];
        this.items.forEach(item => {
          const firstLetter = item.name.toLowerCase().charAt(0);
          if (from <= firstLetter && to >= firstLetter) filteredItems.push(item);
        });
        this.set("filteredItems", filteredItems);
      }

    }
  });

  _exports.default = _default;
});