define("ember-components/components/copyable-fields", ["exports", "ember-components/templates/components/copyable-fields"], function (_exports, _copyableFields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _copyableFields.default,
    classNames: ["copyable-fields"]
  });

  _exports.default = _default;
});