define("ember-components/templates/components/side-bar/single-select/not-selected-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oLjTiDKT",
    "block": "{\"symbols\":[\"SelectedItemIcon\",\"@selectedItem\",\"@required\"],\"statements\":[[4,\"unless\",[[23,3,[]]],null,{\"statements\":[[4,\"let\",[[28,\"component\",[\"side-bar/single-select/icon\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"option-wrapper\"],[10,\"data-test-id\",\"remove-button\"],[10,\"data-test-option\",\"remove\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"clearSelectedValue\"],null]],[8],[0,\"\\n\"],[4,\"unless\",[[23,2,[]]],null,{\"statements\":[[0,\"        \"],[6,[23,1,[]],[[12,\"class\",\"selected-item-icon-block\"]],[[\"@dataTestId\"],[\"not-selected-item-icon\"]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[1,[28,\"t\",[\"components.notChosen\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/side-bar/single-select/not-selected-item.hbs"
    }
  });

  _exports.default = _default;
});