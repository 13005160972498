define("ember-components/components/filter-composer", ["exports", "ember-components/templates/components/filter-composer"], function (_exports, _filterComposer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _filterComposer.default,
    classNames: ["filter-composer"],
    validationErrorMessage: "Pasirinkite privalomus laukelius",
    renderInPlace: false
  });

  _exports.default = _default;
});