define("ember-components/components/side-bar/notification", ["exports", "ember-components/templates/components/side-bar/notification"], function (_exports, _notification) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _notification.default,
    classNames: ["notification"],
    "data-test-id": "notification",
    titleHtml: Ember.computed("title", function () {
      return Ember.String.htmlSafe(this.title);
    })
  });

  _exports.default = _default;
});