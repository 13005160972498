define("ember-components/components/side-bar/right-popup", ["exports", "ember-components/templates/components/side-bar/right-popup", "ember-components/mixins/modal-counter", "ember-components/mixins/keyboard-actions"], function (_exports, _rightPopup, _modalCounter, _keyboardActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalCounter.default, _keyboardActions.default, {
    "data-test-id": "right-popup",
    layout: _rightPopup.default,
    className: "mini",
    fadeBackground: true,
    isDisabled: false,
    classNameBindings: ["isRightPopupOpen:visible:hidden"],

    didInsertElement() {
      this._super(...arguments);

      this.defocusParentElement();
      if (this.componentsStatus.modalCount < 2) this.setPointerHeight();
    },

    defocusParentElement() {
      if (this.isRightPopupOpen) {
        const suitableElements = "textarea, input";
        const focusedElement = $(document.activeElement);
        if (!focusedElement.is(suitableElements)) focusedElement.blur();
      }
    },

    hasInputViewClass: element => element.className.includes("input-view"),

    getSiblingElementWithInputViewClass({
      previousElementSibling
    }) {
      return previousElementSibling && this.hasInputViewClass(previousElementSibling) ? previousElementSibling : null;
    },

    getParentElementWithInputViewClass({
      parentElement
    }) {
      if (parentElement) {
        if (this.hasInputViewClass(parentElement)) return parentElement;
        const siblingElement = this.getSiblingElementWithInputViewClass(parentElement);
        if (siblingElement) return siblingElement;
      }

      return null;
    },

    findInputView(element) {
      const siblingElement = this.getSiblingElementWithInputViewClass(element);
      if (siblingElement) return siblingElement;
      const parentElement = this.getParentElementWithInputViewClass(element);
      if (parentElement) return parentElement;
      const [parentElementByAttribute] = document.getElementsByClassName(this.parentClass);
      if (parentElementByAttribute) return parentElementByAttribute;
      return null;
    },

    setPointerHeight() {
      const inputView = this.findInputView(this.element);

      if (inputView) {
        const [pointerElement] = this.element.getElementsByClassName("pointer");
        const {
          top
        } = inputView.getBoundingClientRect();
        const height = top + inputView.offsetHeight / 2 - pointerElement.offsetHeight / 2;
        pointerElement.setAttribute("style", `top: ${height}px`);
      }
    }

  });

  _exports.default = _default;
});