define("ember-components/components/side-bar/numeric-input/phone", ["exports", "ember-components/templates/components/side-bar/numeric-input/phone", "ember-components/mixins/focus-input-field", "ember-components/components/side-bar/numeric-input/abstract"], function (_exports, _phone, _focusInputField, _abstract) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstract.default.extend(_focusInputField.default, {
    layout: _phone.default,
    phoneNumberTemplate: /^\+?[0-9]{3,12}$/,
    customValidationRules: [],
    intl: Ember.inject.service(),

    convertTo(value) {
      return this.asString(value);
    },

    validate(value) {
      const validationError = this._super(...arguments);

      if (validationError) return validationError;else if (this.countChars(value, "+") > 1 || !this.phoneNumberTemplate.test(value)) return this.intl.t("components.warnings.invalidPhoneNumber");
      return this._super(value);
    },

    onNumberSelect(value) {
      let numericValue = this.asString(this.inputValue);
      if (numericValue === "0") numericValue = "";
      numericValue += this.asString(value);

      this._super(numericValue);
    }

  });

  _exports.default = _default;
});