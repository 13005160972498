define('ember-g-map/components/g-map-polyline', ['exports', 'ember-g-map/templates/components/g-map-polyline', 'ember-g-map/components/g-map', 'ember-g-map/utils/compact'], function (exports, _gMapPolyline, _gMap, _compact) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const allowedPolylineOptions = Ember.A(['strokeColor', 'strokeWeight', 'strokeOpacity', 'zIndex', 'geodesic', 'icons', 'clickable', 'draggable', 'visible', 'path']);

  const GMapPolylineComponent = Ember.Component.extend({
    layout: _gMapPolyline.default,
    classNames: ['g-map-polyline'],

    map: Ember.computed.alias('mapContext.map'),

    init() {
      this._super(...arguments);
      this.infowindow = null;
      this.set('coordinates', Ember.A());
      if (Ember.isEmpty(this.get('group'))) {
        this.set('group', null);
      }

      const mapContext = this.get('mapContext');
      (false && !(mapContext instanceof _gMap.default) && Ember.assert('Must be inside {{#g-map}} component with context set', mapContext instanceof _gMap.default));


      mapContext.registerPolyline(this);
    },

    didInsertElement() {
      this._super(...arguments);
      if (Ember.isEmpty(this.get('polyline')) && typeof google !== 'undefined') {
        const options = (0, _compact.default)(this.getProperties(allowedPolylineOptions));
        const polyline = new google.maps.Polyline(options);
        this.set('polyline', polyline);
      }
      this.setMap();
      this.setPath();
      this.updatePolylineOptions();
      this.setOnClick();
      this.setOnDrag();
    },

    willDestroyElement() {
      this.unsetPolylineFromMap();
      this.get('mapContext').unregisterPolyline(this);
    },

    registerCoordinate(coordinate) {
      this.get('coordinates').addObject(coordinate);
    },

    unregisterCoordinate(coordinate) {
      this.get('coordinates').removeObject(coordinate);
      this.setPath();
    },

    unsetPolylineFromMap() {
      const polyline = this.get('polyline');
      if (Ember.isPresent(polyline)) {
        polyline.setMap(null);
      }
    },

    mapWasSet: Ember.observer('map', function () {
      Ember.run.once(this, 'setMap');
    }),

    setMap() {
      const map = this.get('map');
      const polyline = this.get('polyline');

      if (Ember.isPresent(polyline) && Ember.isPresent(map)) {
        polyline.setMap(map);
      }
    },

    setPath() {
      const polyline = this.get('polyline');
      const coordinates = this.get('coordinates');
      const path = this.get('path');

      if (Ember.isPresent(polyline)) {

        if (Ember.isPresent(coordinates) && Ember.isEmpty(path)) {
          let coordArray = Ember.A(this.get('coordinates').mapBy('coordinate')).compact();
          polyline.setPath(coordArray);
        }

        if (Ember.isPresent(path) && Ember.isEmpty(coordinates)) {
          polyline.setPath(path);
        }
      }
    },

    polylineOptionsChanged: Ember.observer(...allowedPolylineOptions, function () {
      Ember.run.once(this, 'updatePolylineOptions');
    }),

    updatePolylineOptions() {
      const polyline = this.get('polyline');
      const options = (0, _compact.default)(this.getProperties(allowedPolylineOptions));

      if (Ember.isPresent(polyline) && Ember.isPresent(Object.keys(options))) {
        polyline.setOptions(options);
      }
    },

    setOnClick() {
      const polyline = this.get('polyline');
      if (Ember.isPresent(polyline)) {
        polyline.addListener('click', e => this.sendOnClick(e));
      }
    },

    sendOnClick(e) {
      const onClick = this.get('onClick');
      const polyline = this.get('polyline');

      if (Ember.typeOf(onClick) === 'function') {
        onClick(e, polyline);
      } else {
        this.sendAction('onClick', e, polyline);
      }
    },

    setOnDrag() {
      const polyline = this.get('polyline');
      if (Ember.isPresent(polyline)) {
        polyline.addListener('dragend', e => this.sendOnDrag(e));
      }
    },

    sendOnDrag(e) {
      const onDrag = this.get('onDrag');
      const polyline = this.get('polyline');

      if (Ember.typeOf(onDrag) === 'function') {
        onDrag(e, polyline);
      } else {
        this.sendAction('onDrag', e, polyline);
      }
    }
  });

  GMapPolylineComponent.reopenClass({
    positionalParams: ['mapContext']
  });

  exports.default = GMapPolylineComponent;
});