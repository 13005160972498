define("ember-components/templates/components/side-bar/right-popup/body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ea0f73pe",
    "block": "{\"symbols\":[\"@isDisabled\",\"@title\",\"@message\",\"&default\"],\"statements\":[[14,4,[[28,\"hash\",null,[[\"validationBlock\",\"help-block\",\"isDisabled\"],[[28,\"component\",[\"side-bar/right-popup/validation-block\"],[[\"message\"],[[23,3,[]]]]],[28,\"component\",[\"side-bar/common/help-block\"],[[\"title\"],[[23,2,[]]]]],[23,1,[]]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/side-bar/right-popup/body.hbs"
    }
  });

  _exports.default = _default;
});