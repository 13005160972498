define("ember-components/services/time", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    getTime: () => (0, _moment.default)(),

    getTimeDifferenceBetweenDatesInMonth(from, to) {
      if (to && from) {
        const duration = _moment.default.duration(to.diff(from));

        return Math.floor(duration.asMonths());
      }

      return null;
    },

    isTimeValid(time, format) {
      return (0, _moment.default)(time, format, true).isValid();
    },

    formatTime(time, format) {
      return (0, _moment.default)(time).format(format);
    }

  });

  _exports.default = _default;
});