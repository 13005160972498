define("ember-components/components/filter-composer/modal-body", ["exports", "ember-components/templates/components/filter-composer/modal-body"], function (_exports, _modalBody) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _modalBody.default
  });

  _exports.default = _default;
});