define("ember-components/components/side-bar/confirm", ["exports", "ember-components/templates/components/side-bar/confirm"], function (_exports, _confirm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _confirm.default
  });

  _exports.default = _default;
});