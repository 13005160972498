define("ember-components/templates/components/side-bar/right-popup/footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kLiHm/Pj",
    "block": "{\"symbols\":[\"@switchSideBarTask\",\"@title\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"tab-button left\"],[11,\"onclick\",[28,\"perform\",[[23,1,[]],-1],null]],[10,\"data-test-id\",\"sidebar-previous-button\"],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"angle left icon big blue\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"tab-button right\"],[11,\"onclick\",[28,\"perform\",[[23,1,[]],1],null]],[10,\"data-test-id\",\"sidebar-next-button\"],[8],[0,\"\\n      \"],[7,\"h4\",true],[10,\"class\",\"title\"],[8],[1,[23,2,[]],false],[9],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"angle right icon big blue\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/side-bar/right-popup/footer.hbs"
    }
  });

  _exports.default = _default;
});