define("ember-components/templates/components/section-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ijcubUVI",
    "block": "{\"symbols\":[\"@icon\",\"@title\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui container\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"data-test-id\",\"section-header-icon\"],[11,\"class\",[29,[\"section-header-icon \",[23,1,[]]]]],[8],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"title\"],[10,\"data-test-id\",\"section-header-title\"],[8],[1,[23,2,[]],false],[9],[0,\"\\n    \"],[14,3],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/section-header.hbs"
    }
  });

  _exports.default = _default;
});