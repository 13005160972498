define("ember-components/components/side-bar/input-popup", ["exports", "ember-concurrency", "ember-components/templates/components/side-bar/input-popup", "ember-components/mixins/validation-class-mixin"], function (_exports, _emberConcurrency, _inputPopup, _validationClassMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_validationClassMixin.default, {
    layout: _inputPopup.default,
    classNames: ["input-popup"],
    tagName: ["span"],
    onRightPopupClose: (0, _emberConcurrency.task)(function* () {
      yield this.set("isRightPopupOpen", false);
    }),
    actions: {
      onRightPopupOpen() {
        this.set("isRightPopupOpen", true);
        return this.onRightPopupOpen ? this.onRightPopupOpen() : null;
      }

    }
  });

  _exports.default = _default;
});