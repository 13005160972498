define("ember-components/components/medicaments-list/medicament-events/body", ["exports", "ember-components/templates/components/medicaments-list/medicament-events/body"], function (_exports, _body) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _body.default,
    tagName: "tbody",
    emptyTrsArray: Ember.computed("colspanCount", {
      get() {
        const array = [];

        for (let i = 0; i < this.colspanCount - 2; i++) array.push("");

        return array;
      },

      set: (key, value) => value
    })
  });

  _exports.default = _default;
});