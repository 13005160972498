define("ember-components/templates/components/side-bar/numeric-input/date/age", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8C9roCjy",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"age\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/side-bar/numeric-input/date/age.hbs"
    }
  });

  _exports.default = _default;
});