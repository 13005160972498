define("ember-components/components/medicaments-list/body/type", ["exports", "ember-components/templates/components/medicaments-list/body/type"], function (_exports, _type) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _type.default,
    tagName: "td",
    ["data-test-id"]: "name"
  });

  _exports.default = _default;
});