define("ember-components/components/side-bar/numeric-input/float", ["exports", "ember-components/templates/components/side-bar/numeric-input/float", "ember-components/mixins/focus-input-field", "ember-components/components/side-bar/numeric-input/abstract"], function (_exports, _float, _focusInputField, _abstract) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstract.default.extend(_focusInputField.default, {
    layout: _float.default,
    customValidationRules: [],
    intl: Ember.inject.service(),

    convertTo(value) {
      return value ? Number(value) : null;
    },

    validate(value) {
      const validationError = this._super(...arguments);

      if (validationError) return validationError;else if (value) {
        const lastDigit = value[value.length - 1];
        if (this.countChars(value, ",") > 0) return this.intl.t("components.warnings.numberWithComma");else if (isNaN(this.convertTo(value)) || lastDigit === ".") return this.intl.t("components.warnings.invalidNumber");
      }
      return this._super(value);
    },

    onNumberSelect(value) {
      let numericValue = this.asString(this.inputValue);
      if (numericValue === "0") numericValue = "";
      if (numericValue === "" && value === ".") numericValue = "0";
      numericValue += this.asString(value);

      this._super(numericValue);
    }

  });

  _exports.default = _default;
});