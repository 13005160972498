define("ember-components/mixins/focus-input-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    device: Ember.inject.service(),

    didUpdate() {
      this.device.isDesktop() && this.element.focus();
    }

  });

  _exports.default = _default;
});