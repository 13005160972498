define("ember-components/templates/components/task-wrapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Vy9AU8bK",
    "block": "{\"symbols\":[\"@task\",\"&default\"],\"statements\":[[14,2,[[28,\"hash\",null,[[\"running\",\"success\",\"empty\",\"error\"],[[28,\"component\",[\"task-wrapper/running\"],[[\"task\"],[[23,1,[]]]]],[28,\"component\",[\"task-wrapper/success\"],[[\"task\"],[[23,1,[]]]]],[28,\"component\",[\"task-wrapper/empty\"],[[\"task\"],[[23,1,[]]]]],[28,\"component\",[\"task-wrapper/error\"],[[\"task\"],[[23,1,[]]]]]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/task-wrapper.hbs"
    }
  });

  _exports.default = _default;
});