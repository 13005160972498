define("ember-components/templates/components/center-modal/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ubYumamF",
    "block": "{\"symbols\":[\"@data\",\"@task\",\"@title\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"header-container\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[0,\"\\n    \"],[7,\"h4\",true],[10,\"data-test-id\",\"title\"],[8],[1,[23,3,[]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"buttons-container\"],[8],[0,\"\\n\"],[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"      \"],[7,\"button\",true],[10,\"data-test-id\",\"submit-button\"],[10,\"class\",\"ui green right floated button submit-button\"],[11,\"onclick\",[28,\"perform\",[[23,2,[]],[23,1,[]]],null]],[8],[0,\"\\n        OK\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[14,4],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/center-modal/header.hbs"
    }
  });

  _exports.default = _default;
});