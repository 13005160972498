define("ember-components/helpers/translate-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    errorTranslation: Ember.inject.service(),

    compute([error]) {
      return this.errorTranslation.getErrorTextFromException(error);
    }

  });

  _exports.default = _default;
});