define("ember-components/components/signature-pad", ["exports", "ember-components/templates/components/signature-pad", "ember-concurrency"], function (_exports, _signaturePad, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _signaturePad.default,
    device: Ember.inject.service(),
    isSignaturePadOpen: false,
    classNames: ["signature"],
    "data-test-id": "signature-pad",

    onSignaturePadComponentCreated(signaturePadComponent) {
      this.set("signaturePadComponent", signaturePadComponent);
    },

    onCloseSignaturePadModal: (0, _emberConcurrency.task)(function* () {
      yield this.set("isSignaturePadOpen", false);
    }),
    onCloseSignatureConfirmationModal: (0, _emberConcurrency.task)(function* () {
      yield this.set("signatureRemoveConfirmation", false);
    }),
    onSignatureRemove: (0, _emberConcurrency.task)(function* () {
      yield this.set("data", null);
      yield this.set("signatureRemoveConfirmation", false);
      yield this.onClear && this.onClear();
    }),
    onSignatureClear: (0, _emberConcurrency.task)(function* () {
      const {
        onClear,
        signaturePadComponent
      } = this;
      yield signaturePadComponent.onClearCanvas();
      yield onClear && onClear();
    }),
    onSubmitForm: (0, _emberConcurrency.task)(function* () {
      yield this.signaturePadComponent.getSignature();
      return this.task;
    }),

    willDestroyElement() {
      this.set("signaturePadComponent", null);
    }

  });

  _exports.default = _default;
});