define("ember-components/components/side-bar/right-popup/body", ["exports", "ember-components/templates/components/side-bar/right-popup/body"], function (_exports, _body) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _body.default,
    classNames: ["sidebar-body"]
  });

  _exports.default = _default;
});