define("ember-components/components/dropdown-menu/menu-item", ["exports", "ember-components/templates/components/dropdown-menu/menu-item"], function (_exports, _menuItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _menuItem.default,
    "data-test-id": "item",
    classNames: ["item"],
    onActiveChange: Ember.observer("active", function () {
      this.onActiveItemChange();
    }),

    init() {
      this._super(...arguments);

      this.onActiveItemChange();
    },

    onActiveItemChange() {
      this.active && this.updateTitle(this.headerTitle || this.title);
    },

    click() {
      this.set("showMenuItems", false);
      if (this.onItemClick) this.onItemClick();
    }

  });

  _exports.default = _default;
});