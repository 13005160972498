define("ember-components/templates/components/filter-composer/filters-template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zpkOP2iW",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"centerModal\"],[[28,\"component\",[\"filter-composer/center-modal\"],[[\"isFilterPopupOpened\",\"isFilterSubmitted\",\"filterTitle\",\"filters\",\"hasValidationErrors\",\"validationErrorMessage\",\"filterButtonTask\"],[[24,[\"isFilterPopupOpened\"]],[24,[\"isFilterSubmitted\"]],[24,[\"filterTitle\"]],[24,[\"filters\"]],[24,[\"hasValidationErrors\"]],[24,[\"validationErrorMessage\"]],[24,[\"filterButtonTask\"]]]]]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/filter-composer/filters-template.hbs"
    }
  });

  _exports.default = _default;
});