define("ember-components/components/contacts/error-message", ["exports", "ember-components/templates/components/contacts/error-message"], function (_exports, _errorMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _errorMessage.default,
    errorTranslation: Ember.inject.service(),
    isNotNetworkError: Ember.computed("error", function () {
      const error = this.errorTranslation.getErrorCodeFromException(this.error);
      return error !== "NO_INTERNET_CONNECTION";
    })
  });

  _exports.default = _default;
});