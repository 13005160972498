define('ember-g-map/components/g-map-infowindow', ['exports', 'ember-g-map/templates/components/g-map-infowindow', 'ember-g-map/components/g-map', 'ember-g-map/components/g-map-marker', 'ember-g-map/utils/compact'], function (exports, _gMapInfowindow, _gMap, _gMapMarker, _compact) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const allowedOptions = Ember.A(['disableAutoPan', 'maxWidth', 'pixelOffset']);

  const OPEN_CLOSE_EVENTS = Ember.A(['click', 'dblclick', 'rightclick', 'mouseover', 'mouseout']);

  const GMapInfowindowComponent = Ember.Component.extend({
    layout: _gMapInfowindow.default,
    classNames: ['g-map-marker'],

    map: Ember.computed.alias('mapContext.map'),
    marker: Ember.computed.alias('mapContext.marker'),

    init() {
      this._super(...arguments);

      const mapContext = this.get('mapContext');
      const hasMap = mapContext instanceof _gMap.default;
      const hasMarker = mapContext instanceof _gMapMarker.default;
      (false && !(hasMarker || hasMap) && Ember.assert('Must be inside {{#g-map}} or {{#g-map-marker}} components with context set', hasMarker || hasMap));


      this.set('hasMarker', hasMarker);
    },

    didInsertElement() {
      this._super(...arguments);
      if (Ember.isEmpty(this.get('infowindow'))) {
        const infowindow = this.buildInfowindow();
        this.set('infowindow', infowindow);
      }
      this.setPosition();
      this.setMap();
      this.setMarker();
      this.setOptions();
    },

    willDestroyElement() {
      this.close();

      if (this.get('hasMarker')) {
        this.get('mapContext').unregisterInfowindow();
      }
    },

    optionsChanged: Ember.observer(...allowedOptions, function () {
      Ember.run.once(this, 'setOptions');
    }),

    setOptions() {
      const infowindow = this.get('infowindow');
      const options = (0, _compact.default)(this.getProperties(allowedOptions));

      if (Ember.isPresent(infowindow) && Ember.isPresent(Object.keys(options))) {
        infowindow.setOptions(options);
      }
    },

    buildInfowindow() {
      if (typeof google !== 'undefined') {
        const infowindow = new google.maps.InfoWindow({
          content: this.get('element')
        });

        if (Ember.isPresent(this.get('attrs.onOpen'))) {
          infowindow.addListener('domready', () => this.handleOpenClickEvent());
        }

        if (Ember.isPresent(this.get('attrs.onClose'))) {
          infowindow.addListener('closeclick', () => this.handleCloseClickEvent());
        }
        return infowindow;
      }
    },

    handleOpenClickEvent() {
      const onOpen = this.get('onOpen');
      if (Ember.typeOf(onOpen) === 'function') {
        onOpen();
      } else {
        this.sendAction('onOpen', this);
      }
    },

    handleCloseClickEvent() {
      const onClose = this.get('onClose');
      if (Ember.typeOf(onClose) === 'function') {
        onClose();
      } else {
        this.sendAction('onClose');
      }
    },

    open() {
      const infowindow = this.get('infowindow');
      const map = this.get('map');
      const marker = this.get('marker');

      this.set('isOpen', true);
      if (Ember.isPresent(map) && Ember.isPresent(marker)) {
        infowindow.open(map, marker);
      } else if (Ember.isPresent(map)) {
        infowindow.open(map);
      }
    },

    close() {
      const infowindow = this.get('infowindow');
      if (Ember.isPresent(infowindow)) {
        this.set('isOpen', false);
        infowindow.close();
      }
    },

    mapWasSet: Ember.observer('map', function () {
      Ember.run.once(this, 'setMap');
    }),

    setMap() {
      if (this.get('hasMarker') === false) {
        this.open();
      }
    },

    markerWasSet: Ember.observer('marker', function () {
      Ember.run.once(this, 'setMarker');
    }),

    setMarker() {
      const map = this.get('map');
      const marker = this.get('marker');
      const context = this.get('mapContext');
      const infowindow = this.get('infowindow');

      if (Ember.isPresent(infowindow) && Ember.isPresent(map) && Ember.isPresent(marker)) {
        const openEvent = this.retrieveOpenEvent();
        const closeEvent = this.retrieveCloseEvent();
        context.registerInfowindow(this, openEvent, closeEvent);
      }
    },

    coordsChanged: Ember.observer('lat', 'lng', function () {
      Ember.run.once(this, 'setPosition');
    }),

    setPosition() {
      const infowindow = this.get('infowindow');
      const lat = this.get('lat');
      const lng = this.get('lng');

      if (Ember.isPresent(infowindow) && Ember.isPresent(lat) && Ember.isPresent(lng) && typeof FastBoot === 'undefined') {
        const position = new google.maps.LatLng(lat, lng);
        infowindow.setPosition(position);
      }
    },

    retrieveOpenEvent() {
      const openEvent = this.get('openOn');
      return OPEN_CLOSE_EVENTS.includes(openEvent) ? openEvent : 'click';
    },

    retrieveCloseEvent() {
      const closeEvent = this.get('closeOn');
      return OPEN_CLOSE_EVENTS.includes(closeEvent) ? closeEvent : null;
    }
  });

  GMapInfowindowComponent.reopenClass({
    positionalParams: ['mapContext']
  });

  exports.default = GMapInfowindowComponent;
});