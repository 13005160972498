define('ember-cli-mirage/route-handlers/function', ['exports', 'ember-cli-mirage/route-handlers/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  class FunctionRouteHandler extends _base.default {

    constructor(schema, serializerOrRegistry, userFunction, path) {
      super();
      this.schema = schema;
      this.serializerOrRegistry = serializerOrRegistry;
      this.userFunction = userFunction;
      this.path = path;
    }

    handle(request) {
      return this.userFunction(this.schema, request);
    }

    setRequest(request) {
      this.request = request;
    }

    serialize(response, serializerType) {
      let serializer;

      if (serializerType) {
        serializer = this.serializerOrRegistry.serializerFor(serializerType, { explicit: true });
      } else {
        serializer = this.serializerOrRegistry;
      }

      return serializer.serialize(response, this.request);
    }

    normalizedRequestAttrs() {
      let path = this.path,
          request = this.request,
          requestHeaders = this.request.requestHeaders;


      let modelName = this.getModelClassFromPath(path);

      if (/x-www-form-urlencoded/.test(requestHeaders['Content-Type'])) {
        return this._getAttrsForFormRequest(request);
      } else {
        return this._getAttrsForRequest(request, modelName);
      }
    }
  }
  exports.default = FunctionRouteHandler;
});