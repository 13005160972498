define("ember-components/templates/components/medicaments-list/body/total", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KeDQ6o9C",
    "block": "{\"symbols\":[\"ExpandIcon\",\"@isSelected\"],\"statements\":[[1,[24,[\"type\",\"totalAmount\"]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"isDrillDownOptionEnabled\"]]],null,{\"statements\":[[4,\"let\",[[28,\"component\",[\"medicaments-list/expand-icon\"],null]],null,{\"statements\":[[0,\"    \"],[6,[23,1,[]],[],[[\"@isSelected\"],[[23,2,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-components/templates/components/medicaments-list/body/total.hbs"
    }
  });

  _exports.default = _default;
});