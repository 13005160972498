define("ember-components/components/medicaments-list/body/types", ["exports", "ember-components/templates/components/medicaments-list/body/types"], function (_exports, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _types.default,
    tagName: "",
    isDrillDownOptionEnabled: true,

    toggleDrillDown(selectedMedicament) {
      const {
        selectedMedicamentCode
      } = this;
      Ember.set(this, "selectedMedicamentCode", selectedMedicamentCode !== selectedMedicament && selectedMedicament);
    },

    actions: {
      onTypeClicked(selectedMedicamentType, selectedItems) {
        if (this.isDrillDownOptionEnabled) {
          this.toggleDrillDown(selectedMedicamentType);
          Ember.isPresent(this.selectedMedicamentCode) && this.onMedicamentClick && this.onMedicamentClick(selectedMedicamentType, selectedItems);
        }
      }

    }
  });

  _exports.default = _default;
});