define("ember-components/components/navigation-menu", ["exports", "ember-components/templates/components/navigation-menu"], function (_exports, _navigationMenu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _navigationMenu.default,
    classNames: ["top-navigation ui mini labeled icon menu"]
  });

  _exports.default = _default;
});