define("ember-components/components/medicaments-list/body/name", ["exports", "ember-components/templates/components/medicaments-list/body/name"], function (_exports, _name) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _name.default,
    tagName: "tr",
    classNames: ["medicament-name"],
    ["data-test-id"]: "medicament"
  });

  _exports.default = _default;
});