define("ember-components/helpers/disable-bubbling", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.disableBubbling = disableBubbling;
  _exports.default = void 0;

  function disableBubbling([action]) {
    return event => {
      event.stopPropagation();
      return action(event);
    };
  }

  var _default = Ember.Helper.helper(disableBubbling);

  _exports.default = _default;
});