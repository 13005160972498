define("ember-components/helpers/is-present", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.isPresent = void 0;

  const isPresent = ([value]) => Ember.isPresent(value);

  _exports.isPresent = isPresent;

  var _default = Ember.Helper.helper(isPresent);

  _exports.default = _default;
});